import { AttachmentTransformResource } from './../../../models/attachment-transform-resource.model';
import { AttachmentWebResultResource } from './../../../models/message-attachment-web.model';
import { createApi } from 'ngrx-rtk-query';
import { configureBaseQuery } from '@kno2/shared/data-access/+store';
import { PagedResource } from '../../../common/paged.resource';
import { MessageResource } from '../../../models/message-resource.model';
import { MessagesCriteriaResource } from '../../../models/messages-criteria-resource.model';
import { IntakeMessagePreviewResource } from '../../../models/intake-message-preview-resource.model';
import { ProcessResource } from '../../../models/process-resource.model';
import { PatientResource } from '../../../models/patient-resource.model';
import { AttachmentResource } from '../../../models/attachment-resource.model';
import { AttachmentMetaResource } from '../../../models/attachment-meta-resource.model';
import { DateTime } from 'luxon';
import { ClassificationResource } from '../../../models/classification-resource.model';

export const messagesApiKey = 'messagesCache';
export const messagesCacheTags = {
    messages: 'messages',
    drafts: 'drafts',
    attachmentPreviews: 'attachmentPreviews',
    attachments: 'attachments',
    messagePreview: 'messagePreview',
    postMessages: 'postMessages'
};

export const messagesApi = createApi({
    reducerPath: messagesApiKey,
    tagTypes: Object.values(messagesCacheTags),
    baseQuery: configureBaseQuery('/api/messages'),
    endpoints: (build) => ({
        get: build.query<MessageResource, string>({
            query: (id) => ({
                url: id
            }),
            providesTags: (params, err, arg) => {
                return [
                    {
                        type: messagesCacheTags.messages,
                        id: arg
                    }
                ];
            }
        }),
        getAll: build.query<PagedResource<MessageResource>, MessagesCriteriaResource>({
            query: (params) => ({
                url: '/',
                params
            }),
            providesTags: (params, err, arg) => [arg.isDraft ? messagesCacheTags.drafts : messagesCacheTags.messages]
        }),
        postGetMessages: build.query<PagedResource<MessageResource>, MessagesCriteriaResource>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body
            }),
            providesTags: [messagesCacheTags.postMessages]
        }),
        getPreview: build.query<IntakeMessagePreviewResource, string>({
            query: (messageId: string) => ({
                url: `/${messageId}/preview`
            }),
            providesTags: (params, err, arg) => {
                return [
                    {
                        type: messagesCacheTags.messages,
                        id: arg
                    },
                    messagesCacheTags.messagePreview
                ];
            }
        }),
        createNewMessage: build.mutation<MessageResource, { draftMessageResource: MessageResource }>({
            query: ({ draftMessageResource }) => ({
                url: '/',
                method: 'PUT',
                body: draftMessageResource
            }),
            invalidatesTags: (params, err, arg) => {
                return [
                    {
                        type: messagesCacheTags.messages,
                        id: params.id
                    }
                ];
            }
        }),
        updateMessage: build.mutation<MessageResource, MessageResource>({
            query: (message: MessageResource) => ({
                url: `/${message.id}`,
                method: 'PUT',
                body: message
            }),
            invalidatesTags: (params, err, arg) => {
                return [
                    {
                        type: messagesCacheTags.messages,
                        id: params.id
                    },
                    messagesCacheTags.drafts,
                    messagesCacheTags.messages
                ];
            }
        }),
        send: build.mutation<void, { messageId: string; messageData: MessageResource }>({
            query: ({ messageId, messageData }) => ({
                url: `${messageId}/send`,
                method: 'POST',
                body: messageData
            }),
            invalidatesTags: (params, err, arg) => {
                return [
                    {
                        type: messagesCacheTags.messages,
                        id: arg.messageId
                    }
                ];
            }
        }),
        forward: build.mutation<void, MessageResource>({
            query: (message: MessageResource) => ({
                url: `/${message.id}/forward`,
                method: 'POST',
                body: message
            }),
            invalidatesTags: (params, err, arg) => {
                return [
                    {
                        type: messagesCacheTags.messages,
                        id: arg.id
                    }
                ];
            }
        }),
        reply: build.mutation<void, MessageResource>({
            query: (message: MessageResource) => ({
                url: `/${message.id}/reply`,
                method: 'POST',
                body: message
            }),
            invalidatesTags: (params, err, arg) => {
                return [
                    {
                        type: messagesCacheTags.messages,
                        id: arg.id
                    }
                ];
            }
        }),
        copy: build.mutation<MessageResource, string>({
            query: (messageId: string) => ({
                url: `/${messageId}/copy`,
                method: 'POST'
            })
        }),
        updateAttachment: build.mutation<void, { messageId: string; attachmentId: string; updatedAttachment: AttachmentResource }>({
            query: ({ messageId, attachmentId, updatedAttachment }) => ({
                url: `/${messageId}/attachments/${attachmentId}`,
                method: 'PUT',
                body: updatedAttachment
            }),
            invalidatesTags: (params, err, arg) => {
                return [
                    {
                        type: messagesCacheTags.messages,
                        id: arg.messageId
                    },
                    {
                        type: messagesCacheTags.attachments,
                        id: arg.attachmentId
                    },
                    messagesCacheTags.attachmentPreviews
                ];
            }
        }),
        updatePatient: build.mutation<void, { messageId: string; patient: PatientResource }>({
            query: ({ messageId, patient }) => ({
                url: `/${messageId}/patient`,
                method: 'PUT',
                body: patient
            }),
            invalidatesTags: (params, err, arg) => {
                return [
                    {
                        type: messagesCacheTags.messages,
                        id: arg.messageId
                    }
                ];
            }
        }),
        deleteAttachmentById: build.mutation<void, { messageId: string; attachmentId: string }>({
            query: ({ attachmentId, messageId }) => ({
                url: `/${messageId}/attachments/${attachmentId}`,
                method: 'DELETE'
            }),
            invalidatesTags: (params, err, arg) => {
                return [
                    {
                        type: messagesCacheTags.messages,
                        id: arg.messageId
                    },
                    {
                        type: messagesCacheTags.attachments,
                        id: arg.attachmentId || ''
                    },
                    messagesCacheTags.attachmentPreviews
                ];
            }
        }),
        transformAttachment: build.mutation<
            AttachmentTransformResource,
            { messageId: string; attachmentId: string; payload: AttachmentTransformResource }
        >({
            query: ({ attachmentId, messageId, payload }) => ({
                url: `/${messageId}/attachments/${attachmentId}/transforms`,
                method: 'PUT',
                body: payload,
                params: { now: true }
            })
        }),
        getAttachmentPreview: build.query<AttachmentWebResultResource & { retryAfterMilliseconds?: number }, string>({
            query: (attachmentId) => ({
                url: `/attachments/${attachmentId}/web`
            }),
            transformResponse: (payload: any, meta: { request: Response; response: Response }) => {
                if (meta.response.status !== 202) return payload;

                const transformedPayload = { ...payload };
                const retryAfter = meta.response.headers.get('retry-after');

                transformedPayload.retryAfterMilliseconds = retryAfter
                    ? (DateTime.fromJSDate(new Date(retryAfter)).toLocal().toSeconds() - DateTime.now().toSeconds()) * 1000
                    : 2000;

                return transformedPayload;
            },
            providesTags: (params, err, attachmentId) => {
                return [{ id: attachmentId, type: messagesCacheTags.attachmentPreviews }];
            }
        }),
        deleteById: build.mutation<void, { messageId: string; isDraft: boolean }>({
            query: ({ messageId }) => ({
                url: `/${messageId}`,
                method: 'DELETE'
            }),
            invalidatesTags: (params, err, arg) => [arg.isDraft ? messagesCacheTags.drafts : messagesCacheTags.messages, 'mailMessages']
        }),
        markAsProcessed: build.mutation<void, { messageId: string; params: ProcessResource }>({
            query: ({ messageId, params }) => ({
                url: `/${messageId}/process`,
                method: 'PUT',
                body: params
            }),
            invalidatesTags: (params, err, arg) => {
                return [
                    {
                        type: messagesCacheTags.messages,
                        id: arg.messageId
                    }
                ];
            }
        }),
        uploadAttachment: build.mutation<AttachmentResource, { messageId: string; fileToUpload: File; attachmentMeta: AttachmentMetaResource }>({
            query: ({ messageId, fileToUpload, attachmentMeta }) => {
                const formData = new FormData();
                formData.append('file', fileToUpload, fileToUpload.name);
                formData.append('metadata', JSON.stringify(attachmentMeta));

                return {
                    url: `/${messageId}/attachments`,
                    method: 'POST',
                    body: formData
                };
            },
            invalidatesTags: (params, err, arg) => {
                return [
                    {
                        type: messagesCacheTags.messages,
                        id: arg.messageId
                    },
                    {
                        type: messagesCacheTags.attachments,
                        id: params?.id || ''
                    }
                ];
            }
        }),
        restoreAttachment: build.mutation<void, { messageId: string; attachmentId: string }>({
            query: ({ messageId, attachmentId }) => ({
                url: `/${messageId}/attachments/${attachmentId}/restore`,
                method: 'PUT'
            }),
            invalidatesTags: (params, err, arg) => [
                {
                    type: messagesCacheTags.messages,
                    id: arg.messageId
                },
                {
                    type: messagesCacheTags.attachments,
                    id: arg.attachmentId
                }
            ]
        }),
        getAttachment: build.query<AttachmentResource & { retryAfterMilliseconds?: number }, { messageId: string; attachmentId: string }>({
            query: ({ messageId, attachmentId }) => ({
                url: `/${messageId}/attachments/${attachmentId}`
            }),
            transformResponse: (payload: any, meta: { request: Response; response: Response }) => {
                const transformedPayload = { ...payload };
                const retryAfter = meta.response.headers.get('retry-after');

                if (retryAfter)
                    transformedPayload.retryAfterMilliseconds =
                        (DateTime.fromJSDate(new Date(retryAfter)).toLocal().toSeconds() - DateTime.now().toSeconds()) * 1000;

                return transformedPayload;
            },
            providesTags: (params, err, arg) => {
                return [
                    {
                        type: messagesCacheTags.messages,
                        id: arg.messageId
                    },
                    {
                        type: messagesCacheTags.attachments,
                        id: arg.attachmentId
                    }
                ];
            }
        }),
        generateAttachmentPreview: build.query<string, { messageId: string; attachmentId: string }>({
            query: ({ messageId, attachmentId }) => ({
                url: `/${messageId}/attachments/${attachmentId}/generatePreview`,
                method: 'PUT',
                body: { throbber: false }
            })
        }),
        getMessageClassifications: build.query<Array<ClassificationResource>, void>({
            query: () => ({
                url: '/classifications'
            })
        }),
        createTriage: build.mutation<{ id: string }, string>({
            query: (id) => ({
                url: `/${id}/triage`,
                method: 'POST'
            })
        })
    })
});

export const {
    useGetQuery,
    useMarkAsProcessedMutation,
    useGetAllQuery,
    usePostGetMessagesQuery,
    useLazyGetAllQuery,
    useGetAttachmentQuery,
    useGetPreviewQuery,
    useCreateNewMessageMutation,
    useReplyMutation,
    useSendMutation,
    useCopyMutation,
    useUpdateMessageMutation,
    useForwardMutation,
    useGetAttachmentPreviewQuery,
    useUpdateAttachmentMutation,
    useUpdatePatientMutation,
    useDeleteAttachmentByIdMutation,
    useDeleteByIdMutation,
    useTransformAttachmentMutation,
    useUploadAttachmentMutation,
    useGenerateAttachmentPreviewQuery,
    useGetMessageClassificationsQuery,
    useRestoreAttachmentMutation,
    useCreateTriageMutation,
    util
} = messagesApi;
