export enum AuditEventType {
    IntakeMessageView = 'IntakeMessageView',
    IntakeMessageProcessed = 'IntakeMessageProcessed',
    IntakeMessageExported = 'IntakeMessageExported',
    IntakeMessagePrinted = 'IntakeMessagePrinted',
    IntakeMessageForwardedToEMR = 'IntakeMessageForwardedToEMR',
    IntakeMessageAwaitingEMRExport = 'IntakeMessageAwaitingEMRExport',
    IntakeMessageEMRExported = 'IntakeMessageEMRExported',
    IntakeMessageDeleted = 'IntakeMessageDeleted',
    ReleaseMessageReplied = 'ReleaseMessageReplied',
    ReleaseMessageForwarded = 'ReleaseMessageForwarded'
}
