import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileApiFacade } from '@kno2/shared/data-access/api-kno2fy-main';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AuthSliceFacade } from '@kno2/shared/data-access/+store';
import { checkHasAuthorizationToAccess } from '@kno2/shared/util/common';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard {
    constructor(private router: Router, private profile: ProfileApiFacade, private authSliceFacade: AuthSliceFacade) {}
    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.authSliceFacade.authToken$.pipe(
            filter((authToken: string | null) => authToken !== null),
            switchMap(() =>
                this.profile
                    .getMergedProfileData$()
                    .pipe(
                        map((profile) =>
                            checkHasAuthorizationToAccess(
                                (<any>profile)?.roles || [],
                                route?.data?.roles || [],
                                route?.data?.useStrictRoleCheck ?? true
                            )
                        )
                    )
            ),
            tap((authorized) => {
                if (!authorized) this.router.navigateByUrl('/unauthorized');
            })
        );
    }
}
