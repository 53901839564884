import { Injectable } from '@angular/core';
import {
    useGetReleaseTypesQuery,
    useCreateReleaseTypeMutation,
    useUpdateReleaseTypesMutation,
    useRemoveReleaseTypeMutation,
    releaseTypesApi,
    releaseCacheTags
} from './release-types.api';
import { APIHandlerService } from '../../../services/api-handler.service';
import { ReleaseTypeResource } from '../../../models/release-type-resource.model';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root'
})
export class ReleaseTypesApiFacade {
    public getReleaseTypes$ = useGetReleaseTypesQuery;

    constructor(private apiHandlerService: APIHandlerService, private store: Store) {}

    public createNewReleaseType = this.apiHandlerService.createMutationHandler<void, typeof useCreateReleaseTypeMutation>(
        useCreateReleaseTypeMutation,
        {
            toastOnSuccess: 'The release type was saved successfully.',
            toastOnFailure: 'There was an error saving this release type.'
        }
    );

    public updateReleaseType = this.apiHandlerService.createMutationHandler<ReleaseTypeResource, typeof useUpdateReleaseTypesMutation>(
        useUpdateReleaseTypesMutation,
        {
            toastOnSuccess: 'The release type was saved successfully.',
            toastOnFailure: 'There was an error saving this release type.'
        }
    );

    public deleteReleaseType = this.apiHandlerService.createMutationHandler<ReleaseTypeResource, typeof useRemoveReleaseTypeMutation>(
        useRemoveReleaseTypeMutation,
        {
            toastOnSuccess: 'The release type was deleted successfully.',
            toastOnFailure: 'There was an error deleting this release type.'
        }
    );

    public forceReleaseTypesInvalidation(): void {
        this.store.dispatch(releaseTypesApi.util.invalidateTags([releaseCacheTags.releaseTypes]));
    }
}
