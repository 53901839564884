/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * Defines EDM metatypes.  0 = None 1 = Primitive 2 = Entity 3 = Complex 4 = Row 5 = Collection 6 = EntityReference 7 = Enum
 */
export enum EdmTypeKind {
    None = 'None',
    Primitive = 'Primitive',
    Entity = 'Entity',
    Complex = 'Complex',
    Row = 'Row',
    Collection = 'Collection',
    EntityReference = 'EntityReference',
    Enum = 'Enum'
}
