export interface AttachmentWebResultResource {
    attachmentId: string;
    fileName: string;
    pageCount: number;
}

export enum AttachmentWebResultSize {
    Thumbnail = 'Thumbnail',
    Preview = 'Preview',
    Full = 'Full'
}

export interface AttachmentWebResultSizeCriteria {
    size: AttachmentWebResultSize;
}
