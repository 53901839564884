import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AttachmentsState, attachmentsSliceKey } from './attachments-slice.config';
import { selectAttachmentPreviews } from '../current-message/current-message-slice.selectors';

export const selectAttachmentsFeature = createFeatureSelector<AttachmentsState>(attachmentsSliceKey);

export const selectAllowAttachmentImageConversion = createSelector(selectAttachmentsFeature, (state) => state.allowAttachmentImageConversion);

export const selectUploadedAttachmentIds = createSelector(selectAttachmentsFeature, (state) => state.uploadedAttachmentIds);

export const selectModifiedAttachmentMetadata = createSelector(selectAttachmentsFeature, (state) => state.modifiedAttachmentMetadata);

export const selectLoadingMessage = createSelector(selectAttachmentsFeature, (state) => state.loadingMessage);

export const selectTrackUnsavedChanges = createSelector(selectAttachmentsFeature, (state) => state.trackUnsavedChanges);

// TODO: move preview-related state into this slice!!
const selectArePreviewsModified = createSelector(selectAttachmentPreviews, (previews) =>
    previews.some((preview) => preview.transformationMeta.markedForDeletion || !!preview.transformationMeta.rotationInDegrees)
);
export const selectAreAttachmentsModified = createSelector(
    selectArePreviewsModified,
    selectUploadedAttachmentIds,
    selectModifiedAttachmentMetadata,
    (arePreviewsModified, uploadedIds, modifiedMetadata) =>
        arePreviewsModified || !!uploadedIds.length || !!Object.entries(modifiedMetadata).filter(([_, data]) => !!data).length
);
