import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AttachmentsState, INTIIAL_ATTACHMENT_STATE } from './attachments-slice.config';
import { AttachmentMetaData } from './models/attachment-metadata.model';

const attachmentsSlice = createSlice({
    name: 'attachments',
    initialState: INTIIAL_ATTACHMENT_STATE,
    reducers: {
        // attachment actions
        setAttachmentImageConversion: (state: AttachmentsState, action: PayloadAction<boolean>) => {
            state.allowAttachmentImageConversion = action.payload;
        },
        addUploadedAttachmentId: (state: AttachmentsState, action: PayloadAction<string>) => {
            state.uploadedAttachmentIds.push(action.payload);
        },
        clearUploadedAttachmentIds: (state: AttachmentsState) => {
            state.uploadedAttachmentIds = [];
        },
        setModifiedAttachmentMetaData: (state: AttachmentsState, action: PayloadAction<{ id: string; meta: AttachmentMetaData }>) => {
            state.modifiedAttachmentMetadata[action.payload.id] = action.payload.meta;
        },
        clearModifiedAttachmentMetaData: (state: AttachmentsState) => {
            state.modifiedAttachmentMetadata = {};
        },
        setUnsavedChangesTracking: (state: AttachmentsState, action: PayloadAction<boolean>) => {
            state.trackUnsavedChanges = action.payload;
        },
        setLoadingMessage: (state: AttachmentsState, action: PayloadAction<string>) => {
            state.loadingMessage = action.payload;
        },
        resetState: () => INTIIAL_ATTACHMENT_STATE
    }
});

export const attachmentsReducer = attachmentsSlice.reducer;
export const {
    setAttachmentImageConversion,
    addUploadedAttachmentId,
    clearUploadedAttachmentIds,
    setModifiedAttachmentMetaData,
    clearModifiedAttachmentMetaData,
    setUnsavedChangesTracking,
    setLoadingMessage,
    resetState
} = attachmentsSlice.actions;
