import { AuthSliceFacade } from '@kno2/shared/data-access/+store';
import { ProfileApiFacade } from '@kno2/shared/data-access/api-kno2fy-main';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RedirectGuard {
    constructor(private router: Router, private profile: ProfileApiFacade, private authSliceFacade: AuthSliceFacade) {}
    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const routeData = route.data.redirectForRole as Record<string, string>;
        return this.authSliceFacade.authToken$.pipe(
            filter((authToken: string | null) => authToken !== null),
            switchMap(() => this.profile.getRole()),
            map((role: string) => routeData[role]),
            tap((redirectPath) => {
                if (redirectPath) this.router.navigateByUrl(redirectPath as string);
            }),
            map((redirectPath) => !redirectPath)
        );
    }
}
