/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Asc 1 = Desc
 */
export enum SortDirection {
    Asc = 'Asc',
    Desc = 'Desc'
}
