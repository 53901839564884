import { configureBaseQuery } from '@kno2/shared/data-access/+store';
import { createApi } from 'ngrx-rtk-query';

export const attachmentsApiKey = 'attachmentsCache';
const cacheTags = {
    extensions: 'extensions'
};

export const attachmentsApi = createApi({
    reducerPath: attachmentsApiKey,
    tagTypes: Object.values(cacheTags),
    baseQuery: configureBaseQuery('/api/attachments'),
    endpoints: (build) => ({
        getValidExtensions: build.query<Array<string>, void>({
            query: () => ({
                url: '/extensions'
            })
        })
    })
});

export const { useGetValidExtensionsQuery } = attachmentsApi;
