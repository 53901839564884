import { APIHandlerService } from './../../../services/api-handler.service';
import { Injectable } from '@angular/core';
import { NotificationService } from '@kno2/shared/util/common';
import { Store } from '@ngrx/store';
import { SetUserIntakeRulesResource, SimpleDocumentSourceResource, UserDetailsResource, VerifyTwoFactorResource } from '../../../models';
import {
    useGetDocumentSourcesByUserQuery,
    useGetRulesByUserQuery,
    useGetUsersQuery,
    useJobFunctionsQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useSetDocumentSourcesForUserMutation,
    useSetIntakeRulesForUserMutation,
    useEnableUserMutation,
    useResendInvitationMutation,
    useResetPasswordMutation,
    useGetUserByIdQuery,
    useSendCodeMutation,
    useVerifyCodeMutation,
    useEnableTwoFactorAuthMutation,
    usersApi,
    usersCacheTags,
    useDeleteAuthenticatorsMutation,
    useExternalAdminMutation
} from './users.api';

@Injectable({
    providedIn: 'root'
})
export class UsersApiFacade {
    public getUserById$ = useGetUserByIdQuery;
    public getUsers$ = useGetUsersQuery;
    public getJobFunctions$ = useJobFunctionsQuery;
    public getRulesByUser$ = useGetRulesByUserQuery;
    public getDocumentSourcesByUser$ = useGetDocumentSourcesByUserQuery;

    constructor(private store: Store, private notificationService: NotificationService, private apiHandlerService: APIHandlerService) {}

    public addExternalAdmin = this.apiHandlerService.createMutationHandler<void, typeof useExternalAdminMutation>(useExternalAdminMutation, {
        toastOnSuccess: 'External admin was added',
        toastOnFailure: 'External admin could not be added'
    });

    public deleteAuthenicators = this.apiHandlerService.createMutationHandler<void, typeof useDeleteAuthenticatorsMutation>(
        useDeleteAuthenticatorsMutation,
        {
            toastOnSuccess: 'User MFA was reset',
            toastOnFailure: 'User MFA could not be reset'
        }
    );

    public async enableTFA(code: string): Promise<boolean> {
        try {
            await useEnableTwoFactorAuthMutation().dispatch({ code }).unwrap();

            this.notificationService.success('Two Factor Authentication has been enabled.');
            return true;
        } catch ({ data }: any) {
            const errorMsg = this.parseOutError(data);
            this.notificationService.error(errorMsg || `Unabled to verify phone with that verification code.`);
            return false;
        }
    }

    public async createUser(payload: UserDetailsResource): Promise<{ id: string; success: boolean }> {
        try {
            const result = await useCreateUserMutation().dispatch(payload).unwrap();

            this.notificationService.success('New user has been added successfully.');
            return { ...result, success: true };
        } catch ({ data }: any) {
            const errorMsg = this.parseOutError(data);
            this.notificationService.error(`There was an error creating this user: ${errorMsg}`);
            return { id: null, success: false };
        }
    }

    public async updateUser(payload: UserDetailsResource, suppressNotification = false): Promise<{ id: string; success: boolean }> {
        try {
            const result = await useUpdateUserMutation().dispatch(payload).unwrap();

            if (!suppressNotification) this.notificationService.success('User has been saved successfully.');

            return { ...result, success: true };
        } catch ({ data }: any) {
            const errorMsg = this.parseOutError(data);

            if (!suppressNotification) this.notificationService.error(`There was an error saving this user: ${errorMsg}`);

            return { id: payload.id, success: false };
        }
    }

    public async setUserDocumentSources(id: string, resources: Array<SimpleDocumentSourceResource>): Promise<boolean> {
        try {
            const params = { sources: resources, userId: id };
            await useSetDocumentSourcesForUserMutation().dispatch(params).unwrap();

            return true;
        } catch ({ data }: any) {
            const errorMsg = this.parseOutError(data);
            this.notificationService.error(`There was an error saving Document Sources for this user: ${errorMsg}`);
            return false;
        }
    }

    public async setUserIntakeRules(id: string, resources: Array<SetUserIntakeRulesResource>): Promise<boolean> {
        try {
            const params = { rules: resources, userId: id };
            await useSetIntakeRulesForUserMutation().dispatch(params).unwrap();

            return true;
        } catch ({ data }: any) {
            const errorMsg = this.parseOutError(data);
            this.notificationService.error(`There was an error saving Intake Rules for this user: ${errorMsg}`);
            return false;
        }
    }

    public async enableUser(id: string, enable: boolean): Promise<boolean> {
        try {
            const params = { userId: id, enable: enable };
            await useEnableUserMutation().dispatch(params).unwrap();

            return true;
        } catch ({ data }: any) {
            const errorMsg = this.parseOutError(data);
            this.notificationService.error(`There was an error ${enable ? 'enabling' : 'disabling'} user with id ${id}. ERROR: ${errorMsg}`);
            return false;
        }
    }

    public async resendInvitation(user: UserDetailsResource): Promise<boolean> {
        try {
            await useResendInvitationMutation().dispatch(user).unwrap();

            return true;
        } catch ({ data }: any) {
            const errorMsg = this.parseOutError(data);
            this.notificationService.error(`There was an error resending invitaion to user ${user.userName}. ERROR: ${errorMsg}`);
            return false;
        }
    }

    public async resetPassword(user: UserDetailsResource): Promise<boolean> {
        try {
            await useResetPasswordMutation().dispatch(user).unwrap();

            return true;
        } catch ({ data }: any) {
            const errorMsg = this.parseOutError(data);
            this.notificationService.error(`There was an error resetting password for user ${user.userName}. ERROR: ${errorMsg}`);
            return false;
        }
    }

    public async sendCode(method: 'text' | 'call'): Promise<boolean> {
        try {
            await useSendCodeMutation().dispatch({ method }).unwrap();
            this.notificationService.success('Your code has been sent.');
            return true;
        } catch ({ data }: any) {
            const errorMsg = this.parseOutError(data);
            this.notificationService.error(errorMsg || `There was an error sending your code.`);
            return false;
        }
    }

    public async verifyCode(userId: string, payload: VerifyTwoFactorResource): Promise<boolean> {
        try {
            await useVerifyCodeMutation()
                .dispatch({ userId, ...payload })
                .unwrap();
            this.notificationService.success('Phone number saved and verified.');
            return true;
        } catch ({ data }: any) {
            const errorMsg = this.parseOutError(data);
            this.notificationService.error(errorMsg || 'Unable to verify phone number with that verification code.');
            return false;
        }
    }

    private parseOutError(error: any): string {
        const modelStateErrors: Array<Array<string>> = JSON.parse(JSON.stringify(Object.values(error.data?.modelState || {})));

        if (error.data?.modelState) {
            return <string>Object.values(error.data?.modelState)[0];
        }

        if (modelStateErrors.length) return (modelStateErrors[0] as Array<string>).pop() as string;

        if (error.exceptionMessage) return error.exceptionMessage;

        if (error.message) return error.message;

        return null;
    }

    public clearUserCacheByUserId(userId: string): void {
        this.store.dispatch(usersApi.util.invalidateTags([{ type: usersCacheTags.users, id: userId }]));
    }
}
