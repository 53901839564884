/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Original 1 = Pdf 2 = Cda 3 = Tif 4 = PdfCda 5 = TifCda 6 = Hl7
 */
export enum AttachmentSendType {
    Original = 'Original',
    Pdf = 'Pdf',
    Cda = 'Cda',
    Tif = 'Tif',
    PdfCda = 'PdfCda',
    TifCda = 'TifCda',
    Hl7 = 'Hl7'
}
