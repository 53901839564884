/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = NotUrgent 1 = Urgent
 */
export enum Priority {
    NotUrgent = 'NotUrgent',
    Urgent = 'Urgent'
}
