import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authSliceKey, AuthState, INITIAL_AUTH_STATE } from './auth-slice.config';

export const authSlice = createSlice({
    name: authSliceKey,
    initialState: INITIAL_AUTH_STATE,
    reducers: {
        setAuthToken: (state: AuthState, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
        clearAuthState: (state: AuthState) => {
            return INITIAL_AUTH_STATE;
        },
        clearAuthToken: (state: AuthState) => {
            state.token = null;
        }
    }
});

export const { setAuthToken, clearAuthState, clearAuthToken } = authSlice.actions;
