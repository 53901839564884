import { PagingResource } from './../../../common/paging.resource';
import { configureBaseQuery } from '@kno2/shared/data-access/+store';
import { createApi } from 'ngrx-rtk-query';
import { ReleaseTypesResource } from '../../../models/release-types-resource.model';
import { ReleaseTypeResource } from '../../../models/release-type-resource.model';

export const releaseTypesApiKey = 'releaseTypes';
export const releaseCacheTags = {
    releaseTypes: 'releaseTypes'
};

export const releaseTypesApi = createApi({
    reducerPath: releaseTypesApiKey,
    baseQuery: configureBaseQuery('/api/releasetypes'),
    tagTypes: Object.values(releaseCacheTags),
    endpoints: (build) => ({
        getReleaseTypes: build.query<ReleaseTypesResource, (PagingResource & { status: string }) | void>({
            query: (paging) => ({
                url: '/',
                params: {
                    ...Object.fromEntries(Object.entries(paging || {}).filter(([key, value]) => !!value)),
                    pageSize: (paging as any)?.pageSize || 500,
                    status: (paging as any)?.status || 'activated'
                }
            }),
            providesTags: [releaseCacheTags.releaseTypes]
        }),
        createReleaseType: build.mutation<void, ReleaseTypeResource>({
            query: (releaseType) => ({
                url: '/',
                method: 'POST',
                body: releaseType
            }),
            invalidatesTags: [releaseCacheTags.releaseTypes]
        }),
        updateReleaseTypes: build.mutation<ReleaseTypeResource, { releaseTypeId: string; payload: ReleaseTypeResource }>({
            query: ({ releaseTypeId, payload }) => ({
                url: `/${releaseTypeId}`,
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: [releaseCacheTags.releaseTypes]
        }),
        removeReleaseType: build.mutation<ReleaseTypeResource, { releaseTypeId: string }>({
            query: ({ releaseTypeId }) => ({
                url: `/${releaseTypeId}`,
                method: 'DELETE'
            }),
            invalidatesTags: [releaseCacheTags.releaseTypes]
        })
    })
});

export const { useGetReleaseTypesQuery, useCreateReleaseTypeMutation, useUpdateReleaseTypesMutation, useRemoveReleaseTypeMutation } = releaseTypesApi;
