import { API_ERROR_EVENT_TOKEN, SHOULD_BUBBLE_ERROR, SHOULD_SUPRESS_ERROR_MESSAGE } from './api-interceptor.constants';
import { ErrorHandler, Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { from, fromEvent, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Environment } from '../environments/environment.model';
import { NotificationService } from '@kno2/shared/util/common';
import { ApiErrorEventModel } from './api-error-event.model';
import { GLOBAL_NOTIFICATION_RULES } from './api-interceptor.constants';

@Injectable()
export class APIInterceptor implements HttpInterceptor, ErrorHandler {
    constructor(private environment: Environment, private notificationService: NotificationService) {
        fromEvent(document, API_ERROR_EVENT_TOKEN)
            .pipe(
                map((event: any) => {
                    const apiError = <ApiErrorEventModel>event.detail;

                    return {
                        method: apiError.method,
                        httpError: new HttpErrorResponse(apiError)
                    };
                })
            )
            .subscribe(({ method, httpError }) => {
                if (
                    RegExp(GLOBAL_NOTIFICATION_RULES.allowedCodes).test(httpError.status.toString()) &&
                    GLOBAL_NOTIFICATION_RULES.allowedMethods.includes(method.toUpperCase())
                )
                    this.handleError(httpError);
            });
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.startsWith('/api')) {
            req = req.clone({ url: this.environment.baseUrl + req.url });
        }

        return next.handle(req).pipe(
            catchError((error) => {
                if (req.context?.get(SHOULD_SUPRESS_ERROR_MESSAGE)) return of();

                const error$ = this.handleError(error);

                if (req.context?.get(SHOULD_BUBBLE_ERROR)) return of(new HttpResponse(error));
                return error$;
            })
        );
    }

    public handleError(error: Partial<HttpErrorResponse>): Observable<never> {
        if (error.error instanceof Blob) return this.handleBlobError(error);
        return this.handleGenericError(error);
    }

    public handleGenericError(error: Partial<HttpErrorResponse> & { Detail?: string }): Observable<never> {
        const msg = error?.error?.Detail || error?.message || error?.error;
        this.displayError(msg);
        return throwError(() => msg);
    }

    public handleBlobError(error: Partial<HttpErrorResponse>): Observable<never> {
        return from(
            Promise.resolve(error).then(async (x) => {
                const message = JSON.parse(await x.error.text()).message;
                this.displayError(message);

                throw new HttpErrorResponse({ error: message });
            })
        );
    }

    public displayError(message: string): void {
        this.notificationService.error(message, {
            duration: 1000 * 5
        });
    }
}
