/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * Enumeration of order by directions.  0 = Ascending 1 = Descending
 */
export enum OrderByDirection {
    Ascending = 'Ascending',
    Descending = 'Descending'
}
