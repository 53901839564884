import { APIHandlerService } from './../../../services/api-handler.service';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { User } from '../../../models/user.model';
import { DocumentSourceResource } from '../../../models/document-source-resource.model';
import { DocumentSourceUserResource } from '../../../models/document-source-user-resource.model';
import { DocumentSource } from '../../../models/document-source.model';
import { OrganizationUserResource } from '../../../models/organization-user-resource.model';
import { UserDetailsResource } from '../../../models/user-details-resource.model';
import {
    useGetAssignedRulesQuery,
    useGetCurrentUserQuery,
    useGetProfileDataQuery,
    useUpdateProfileDataMutation,
    useGetDocumentSourcesQuery,
    useGetReleaseTypeDocumentSourcesQuery,
    useGetAllUsersQuery
} from './profile.api';
import { AccountSettingsResource, OrganizationSummaryResource } from '../../../models';

@Injectable({
    providedIn: 'root'
})
export class ProfileApiFacade {
    public getCurrentUser = useGetCurrentUserQuery;
    public getProfile = useGetProfileDataQuery;
    public getAssignedRules = useGetAssignedRulesQuery;
    public updateProfile = this.apiHandlerService.createMutationHandler<UserDetailsResource, typeof useUpdateProfileDataMutation>(
        useUpdateProfileDataMutation,
        {
            payloadFormatter: ({ profile, ...args }) => ({
                ...args,
                profile: {
                    ...profile,
                    id: args.userId,
                    isAdministrator: (profile as any).allRoles.includes('Administrator')
                }
            })
        }
    );

    constructor(private apiHandlerService: APIHandlerService) {}

    public getAllUsers$(includeNetworkUsers = false): Observable<{ data: Array<DocumentSourceUserResource>; isLoading: boolean }> {
        return useGetAllUsersQuery({ includeNetwork: includeNetworkUsers }).pipe(map(({ data, isLoading }) => ({ data, isLoading })));
    }

    public getMergedProfileData$(): Observable<UserDetailsResource & OrganizationUserResource & AccountSettingsResource> {
        return combineLatest([this.getCurrentUser(), this.getProfile()]).pipe(
            filter(([{ isFetching: fetchingCurrentUser }, { isFetching: fetchingProfile }]) => !fetchingProfile && !fetchingCurrentUser),
            map(([{ data: currentUser }, { data: profileData }]) => ({ ...currentUser, ...profileData }))
        );
    }

    public getUserId$(): Observable<string> {
        return this.getProfile().pipe(
            filter(({ isFetching }) => !isFetching),
            map(({ data }) => (data as any).userId)
        );
    }

    public getReleaseTypeDocumentSources$(): Observable<Array<DocumentSourceResource>> {
        return useGetReleaseTypeDocumentSourcesQuery(this.getUserId$().pipe(map((userId) => ({ userId })))).pipe(
            filter(({ isFetching }) => !isFetching),
            map(({ data }) => data)
        );
    }

    public getDocumentSources$(): Observable<Array<DocumentSource>> {
        return useGetDocumentSourcesQuery(this.getCurrentUser().pipe(map((user) => ({ userId: user.data.id })))).pipe(
            filter((query) => !query.isFetching),
            map((query) => query.data)
        );
    }

    public getRole(): Observable<string> {
        return this.getCurrentUser().pipe(
            filter((query) => !query.isFetching),
            map((userdata) => userdata?.data?.role?.toString() || '')
        );
    }

    public getOrganizationId$(): Observable<string> {
        return this.getCurrentUser().pipe(
            filter((query) => !query.isFetching),
            map((userdata) => userdata?.data?.activeOrganization.id?.toString() || '')
        );
    }

    public getActiveOrganziation$(): Observable<OrganizationSummaryResource> {
        return this.getCurrentUser().pipe(
            filter((query) => !query.isFetching),
            map((userdata) => userdata?.data?.activeOrganization)
        );
    }

    public getParentNetwork$(): Observable<{ networkId: string }> {
        return this.getMergedProfileData$().pipe(map((profile) => ({ networkId: (<any>profile)?.administerOfNetworkObjectId || null })));
    }

    public isNetworkAdmin$(): Observable<boolean> {
        return this.getProfile().pipe(
            filter((query) => !query.isFetching),
            map((userdata) => (<User>userdata?.data)?.isNetworkAdmin)
        );
    }
}
