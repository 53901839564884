import { AuthorizationToAccessItem } from './access-authorization-check.model';

/**
 *
 * @param assignedValues
 * @param requiredValues
 * @param isStrict
 * @returns true / false value represents an entity's ability to access a resource given the constraints
 */
export const checkHasAuthorizationToAccess = (
    assignedValues: Array<string>,
    requiredValues: Array<string> | Array<AuthorizationToAccessItem>,
    isStrict: boolean = true
): boolean => {
    const normalizedAssignedValues: Array<string> = assignedValues.map((x: string) => x.toLowerCase());

    let rolesToHideFor: Array<string> = [];
    let rolesToShowFor: Array<string> = [];

    if ((requiredValues as Array<any>)?.[0]?.value) {
        rolesToShowFor = (requiredValues as Array<AuthorizationToAccessItem>).filter((x) => !x.restrictIfExists).map(({ value }) => value);
        rolesToHideFor = (requiredValues as Array<AuthorizationToAccessItem>).filter((x) => x.restrictIfExists).map(({ value }) => value);
    } else rolesToShowFor = requiredValues as Array<string>;

    rolesToShowFor = rolesToShowFor?.map((x) => x.toLowerCase());
    rolesToHideFor = rolesToHideFor?.map((x) => x.toLowerCase());

    const checkFuncToUse = isStrict ? 'every' : 'some';
    const hasAccess =
        rolesToHideFor.every((role) => !normalizedAssignedValues?.includes(role)) &&
        rolesToShowFor[checkFuncToUse]((role) => normalizedAssignedValues?.includes(role));

    return hasAccess;
};
