import { APIHandlerService } from './../../../services/api-handler.service';
import { Injectable } from '@angular/core';
import { useCreateMutation, useGetBatchQuery, useProcessBatchMutation } from './triage.api';

@Injectable({
    providedIn: 'root'
})
export class TriageApiFacade {
    public getBatch$ = useGetBatchQuery;

    constructor(private apiHandler: APIHandlerService) {}

    public create = this.apiHandler.createMutationHandler<{ id: string }, typeof useCreateMutation>(useCreateMutation);
    public process = this.apiHandler.createMutationHandler<Array<string>, typeof useProcessBatchMutation>(useProcessBatchMutation, {
        toastOnSuccess: 'Successfully sent triaged message(s)',
        toastOnFailure: 'Error sending triaged message(s)'
    });
}
