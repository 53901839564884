import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallbackComponent } from './components/callback/callback.component';
import { AuthRoutingModule } from './auth-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthConfig, AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { LoginComponent } from './components/login/login.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { SharedThemeModule } from '@kno2/shared/ui/theme';

@NgModule({
    declarations: [CallbackComponent, LoginComponent, UnauthorizedComponent],
    imports: [SharedThemeModule, CommonModule, AuthRoutingModule],
    exports: [UnauthorizedComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true
        }
    ]
})
export class Kno2AuthModule {
    public static forRoot(config: AuthConfig): ModuleWithProviders<Kno2AuthModule> {
        return {
            ngModule: Kno2AuthModule,
            providers: [
                AuthModule.forRoot({
                    ...config,
                    authorizationParams: {
                        ...config.authorizationParams,
                        scope: config.authorizationParams?.scope || 'openid'
                    },
                    httpInterceptor: {
                        allowedList: [
                            {
                                uri: '/api/*'
                            }
                        ]
                    }
                }).providers as Array<Provider>
            ]
        };
    }
}
