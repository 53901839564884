import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackComponent } from './components/callback/callback.component';
import { LoginComponent } from './components/login/login.component';
import { SHARED_ROUTES } from '@kno2/shared/util/configuration';

const routes: Routes = [
    {
        path: SHARED_ROUTES.LOGINCALLBACK,
        component: CallbackComponent
    },
    {
        path: 'account/login/:loginType',
        component: LoginComponent
    },
    {
        path: 'account/login',
        pathMatch: 'full',
        component: LoginComponent
    }
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {}
