import { APIHandlerService } from './../../../services/api-handler.service';
import { HttpClient, HttpContext } from '@angular/common/http';
import { IntegrationResource } from './../../../models/integration-resource.model';
import { Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { IntegrationCapability } from '../../../models/integration-capability.model';
import { CapabilityPropertyField } from '../../../models/integration-capability-field.model';
import { SettingsApiFacade } from '../settings/settings-api.facade';
import { IntegrationCapabilityDisplayName } from '../../../models/integration-capability-display-name.model';
import {
    useCheckConnectivityQuery,
    useGetAllQuery,
    useUpdateMutation,
    useGetCapabilitiesQuery,
    useGetIntegrationDocumentTypesQuery,
    useGetIntegrationTypesQuery,
    useGetIntegrationVisitsQuery,
    useSearchPatientsQuery,
    useAddIntegrationPatientMutation,
    useDeleteMutation,
    useCheckIntegrationTypeConnectivityQuery,
    useGetAllConnctedTypesQuery,
    useGetPatientKeysQuery,
    useGetExternalLoginQuery,
    useCheckExternalLoginStateQuery,
    useGetIntegrationFacilitiesQuery,
    integrationsApi,
    useGetOrdersQuery,
    useGetReviewersQuery,
    useGetReviewerGroupsQuery
} from './integrations.api';
import { NotificationService } from '@kno2/shared/util/common';
import { SHOULD_BUBBLE_ERROR } from '@kno2/shared/util/configuration';
import { saveAs } from 'file-saver';
import { Store } from '@ngrx/store';
import { IntegrationPatient } from '../../../models';

@Injectable({
    providedIn: 'root'
})
export class IntegrationsApiFacade {
    public getAll$ = useGetAllQuery;
    public getCapabilities$ = useGetCapabilitiesQuery;
    public getAllConnectedTypes$ = useGetAllConnctedTypesQuery;
    public searchIntegrationPatients$ = useSearchPatientsQuery;
    public getPatientKeys$ = useGetPatientKeysQuery;
    public getVisits$ = useGetIntegrationVisitsQuery;
    public getIntegrationDocumentTypes$ = useGetIntegrationDocumentTypesQuery;
    public getIntegrationTypes$ = useGetIntegrationTypesQuery;
    public getExternalLogin$ = useGetExternalLoginQuery;
    public checkExternalLoginState$ = useCheckExternalLoginStateQuery;
    public getIntegrationFacilities$ = useGetIntegrationFacilitiesQuery;
    public getOrders$ = useGetOrdersQuery;
    public getReviewers$ = useGetReviewersQuery;
    public getReviewerGroup$ = useGetReviewerGroupsQuery;

    public addPatient = this.apiHandlerService.createMutationHandler<IntegrationPatient, typeof useAddIntegrationPatientMutation>(
        useAddIntegrationPatientMutation,
        {
            toastOnSuccess: (args, result) => `The Patient [${args.firstName} ${args.lastName}], was added successfully`,
            toastOnFailure: (args, result, error) => `An Error occurred when attempting to add the Patient, [${args.firstName} ${args.lastName}]`
        }
    );

    constructor(
        private settingsApiFacade: SettingsApiFacade,
        private notificationService: NotificationService,
        private httpClient: HttpClient,
        private apiHandlerService: APIHandlerService,
        private store: Store
    ) {}

    public forceCacheInvalidation(tag: string): void {
        this.store.dispatch(integrationsApi.util.invalidateTags([tag]));
    }

    public downloadIntegrationCertificate$(integration: IntegrationResource): Observable<boolean> {
        return this.httpClient
            .post(`/api/integrations/${integration.id}/downloadcertificate`, integration, {
                responseType: 'blob',
                context: new HttpContext().set(SHOULD_BUBBLE_ERROR, true)
            })
            .pipe(
                tap((blob: any) => {
                    if (blob) saveAs(blob, 'certificate.cer');
                }),
                map((data: any) => !!data),
                tap((success) => {
                    if (success) this.notificationService.success('Certificate successfully downloaded.');
                })
            );
    }

    public checkIntegrationTypeConnectivity$(integration: IntegrationResource, suppressToasts = true): Observable<string | null> {
        return useCheckIntegrationTypeConnectivityQuery(integration).pipe(
            filter(({ isFetching }) => !isFetching),
            map((data) => this.parseAndToastConnectivityError(data, integration, suppressToasts))
        );
    }

    public checkConnectivity$(integration: IntegrationResource, suppressToasts = true): Observable<string | null> {
        return useCheckConnectivityQuery({ integrationId: integration.id }).pipe(
            filter(({ isFetching }) => !isFetching),
            map((data) => this.parseAndToastConnectivityError(data, integration, suppressToasts))
        );
    }

    public async updateIntegration(
        payload: IntegrationResource,
        suppressNotifications = false,
        invalidateCache?: boolean
    ): Promise<IntegrationResource | { error: string }> {
        try {
            const response = await useUpdateMutation()
                .dispatch({ payload, config: invalidateCache ? { invalidateCache } : null })
                .unwrap();

            if (response.message) this.notificationService.warning(response.message);

            const actionVerb = payload.id ? 'updated' : 'added';

            if (!suppressNotifications) this.notificationService.success(`Successfully ${actionVerb} integration.`);

            return response;
        } catch ({ data }: any) {
            const error = data as any;
            const modelStateError: string | null = Object.values(error.modelState)?.[0] as any;

            let message = '';

            if (modelStateError) message = modelStateError;
            else if (error?.message) message = error.message;
            else message = 'There was an error saving the integration.';

            return { error: message };
        }
    }

    public async deleteIntegration(id: string): Promise<void> {
        try {
            await useDeleteMutation().dispatch({ integrationId: id }).unwrap();
            this.notificationService.success('Integration deleted successfully');
        } catch (err) {
            this.notificationService.error('Could not delete the integration');
        }
    }

    public getCapability$(displayName: IntegrationCapabilityDisplayName): Observable<IntegrationCapability> {
        return useGetCapabilitiesQuery().pipe(
            map((res) => res.data?.capabilities.find((capability: IntegrationCapability) => capability.display === displayName))
        );
    }

    public getCustomPatientLookupTemplate$(): Observable<string> {
        return useGetCapabilitiesQuery().pipe(map((res) => res.data?.customPatientLookupTemplate));
    }

    public getCapabilityNames$(): Observable<Array<IntegrationCapabilityDisplayName>> {
        return useGetCapabilitiesQuery().pipe(map((res) => res.data?.capabilities.map((capability: IntegrationCapability) => capability.display)));
    }

    public hasCapability$(displayName: IntegrationCapabilityDisplayName): Observable<{ data: boolean; isFetching: boolean }> {
        return useGetCapabilitiesQuery().pipe(
            filter(({ isFetching }) => !isFetching),
            map(({ data, isFetching }) => ({
                data: data?.capabilities?.some((capability: IntegrationCapability) => capability.display === displayName),
                isFetching
            })),
            catchError(() => of({ data: false, isFetching: false }))
        );
    }

    public getCapabilityPropertyNames$(displayName: IntegrationCapabilityDisplayName): Observable<Array<string>> {
        return useGetCapabilitiesQuery().pipe(
            map((res) => res.data?.capabilities),
            map((capabilities) => capabilities?.find((c: IntegrationCapability) => c.display === displayName)?.properties),
            map((properties) => properties?.map((p: CapabilityPropertyField) => p.name))
        );
    }

    public hideVisitLookup$(): Observable<boolean> {
        return combineLatest([this.hasCapability$('visitLookup'), this.settingsApiFacade.areVisitDetailsReadOnly$]).pipe(
            filter(([{ isFetching }]) => !isFetching),
            map(([{ data: lookupEnabled }, visitsReadonly]) => !lookupEnabled || visitsReadonly)
        );
    }

    /**
     *
     * @param response
     * @param integration
     * @returns null | string
     *
     * Returns an error as a string or a null if no errors exist
     */
    private parseAndToastConnectivityError(response: any, integration: IntegrationResource, suppress: boolean): string {
        const data = response.data as any;
        const error = response?.error?.data as any;
        const success = (response as any)?.data?.isSuccess;

        if (success && !suppress) this.notificationService.success(`${integration.name} connection check success`);

        let message = null;

        if (success) return null;
        else if (data?.errors?.length) message = data?.errors?.[0];
        else if (error?.exceptionMessage) message = error?.exceptionMessage;
        else message = `${integration.name} connection check failed`;

        if (message && !suppress) this.notificationService.error(message);

        return message;
    }
}
