/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Sent 1 = Processed 2 = Printed 3 = Unprocessed 4 = Reassigned
 */
export enum DocumentStatusCode {
    Sent = 'Sent',
    Processed = 'Processed',
    Printed = 'Printed',
    Unprocessed = 'Unprocessed',
    Reassigned = 'Reassigned'
}
