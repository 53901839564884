import { ElementRef } from '@angular/core';
import { NbOverlayContainerAdapter } from '@nebular/theme';

export const createNestedLayoutMutationObserver = (
    applicationEl: HTMLElement,
    parentLayoutElementRef: ElementRef,
    overlayContainerAdapter: NbOverlayContainerAdapter
) => {
    let nestedLayoutExisted = false;

    const mutationObserver = new MutationObserver((list) => {
        const nestedLayoutExists = !!applicationEl.querySelector('nb-layout.nested-layout');

        if (!nestedLayoutExists && nestedLayoutExisted) overlayContainerAdapter.setContainer(parentLayoutElementRef.nativeElement);

        nestedLayoutExisted = nestedLayoutExists;

        mutationObserver.takeRecords();
    });

    return mutationObserver;
};
