<div class="header-container">
    <ng-container *ngIf="enableSidebarToggle">
        <a
            (click)="toggleSidebar()"
            href="#"
            class="sidebar-toggle">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
        <span class="mr-1"></span>
    </ng-container>
    <a
        class="logo"
        href="#"
        (click)="navigateHome()">
        <img
            [src]="logo"
            alt="" />
    </a>
</div>

<div class="header-container">
    <ng-content select="kno2-topnav"></ng-content>
    <nb-actions size="small">
        <nb-action class="control-item">
            <nb-user
                tag="profile"
                [nbContextMenu]="dropdownItems"
                [nbContextMenuTag]="profileMenuTag"
                [onlyPicture]="userPictureOnly"
                [name]="user?.name"
                [picture]="user?.picture"></nb-user>
        </nb-action>
    </nb-actions>
</div>
