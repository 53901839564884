import { Injectable } from '@angular/core';
import { IntercomCustomAttributes } from './intercom-custom-attributes.model';

@Injectable({
    providedIn: 'root'
})
export class IntercomService {
    constructor() {}

    public static init() {
        return (): Promise<void> => {
            return new Promise((resolve, reject) => {
                try {
                    const APP_ID = '';
                    const script = document.createElement('script') as HTMLScriptElement;

                    script.innerHTML = `
                    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

                    document.head.appendChild(script);

                    resolve();
                } catch (err) {
                    reject(err);
                }
            });
        };
    }

    public boot(intercomAppId: string, userId: string, customAttributes: IntercomCustomAttributes): void {
        (window as any).Intercom('boot', {
            app_id: intercomAppId,
            user_id: userId,
            ...customAttributes
        });
    }
}
