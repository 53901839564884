import { HeaderComponent } from './components/header/header.component';
import { LayoutDirectionSwitcherComponent } from './components/layout-direction-switcher/layout-direction-switcher.component';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    NbActionsModule,
    NbAlertModule,
    NbLayoutModule,
    NbMenuModule,
    NbSearchModule,
    NbSidebarModule,
    NbUserModule,
    NbContextMenuModule,
    NbButtonModule,
    NbButtonGroupModule,
    NbTooltipModule,
    NbPopoverModule,
    NbSelectModule,
    NbIconModule,
    NbThemeModule,
    NbRouteTabsetModule,
    NbToastrModule,
    NbWindowModule,
    NbDialogModule,
    NbDatepickerModule,
    NbAccordionModule,
    NbListModule,
    NbCardModule,
    NbProgressBarModule,
    NbSpinnerModule,
    NbBadgeModule,
    NbOptionModule,
    NbInputModule,
    NbCheckboxModule,
    NbFormFieldModule,
    NbAutocompleteModule,
    NbTabsetModule,
    NbRadioModule,
    NbTagModule,
    NbCalendarModule,
    NbCalendarRangeModule,
    NbStepperModule
} from '@nebular/theme';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { SwitcherComponent } from './components/switcher/switcher.component';
import { AppFrameLayoutComponent } from './layouts/app-frame/app-frame.layout';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { NumberWithCommasPipe } from './pipes/number-with-commas.pipe';
import { TimingPipe } from './pipes/timing.pipe';
import { RoundPipe } from './pipes/round.pipe';
import { PluralPipe } from './pipes/plural.pipe';
import { FriendlyKeyNamePipe } from './pipes/friendly-key-name.pipe';
import { AppFrameLiteComponent } from './layouts/app-frame-lite/app-frame-lite.layout';

const NB_MODULES = [
    NbCalendarModule,
    NbCalendarRangeModule,
    NbDateFnsDateModule,
    NbTagModule,
    NbButtonGroupModule,
    NbRadioModule,
    NbAutocompleteModule,
    NbFormFieldModule,
    NbCheckboxModule,
    NbInputModule,
    NbOptionModule,
    NbBadgeModule,
    NbTabsetModule,
    NbProgressBarModule,
    NbSpinnerModule,
    NbCardModule,
    NbThemeModule,
    NbLayoutModule,
    NbListModule,
    NbMenuModule,
    NbUserModule,
    NbAccordionModule,
    NbActionsModule,
    NbAlertModule,
    NbSearchModule,
    NbSidebarModule,
    NbContextMenuModule,
    NbButtonModule,
    NbSelectModule,
    NbIconModule,
    NbEvaIconsModule,
    NbDatepickerModule,
    NbDialogModule,
    NbWindowModule,
    NbToastrModule,
    NbRouteTabsetModule,
    NbTooltipModule,
    NbPopoverModule,
    NbStepperModule
];
const COMPONENTS = [SwitcherComponent, LayoutDirectionSwitcherComponent, HeaderComponent, AppFrameLayoutComponent, AppFrameLiteComponent];
const PIPES = [CapitalizePipe, FriendlyKeyNamePipe, PluralPipe, RoundPipe, TimingPipe, NumberWithCommasPipe];

@NgModule({
    imports: [CommonModule, ...NB_MODULES],
    exports: [CommonModule, ...NB_MODULES, ...PIPES, ...COMPONENTS],
    declarations: [...COMPONENTS, ...PIPES]
})
export class SharedThemeModule {
    public static forRoot(): ModuleWithProviders<SharedThemeModule> {
        return {
            ngModule: SharedThemeModule,
            providers: [
                ...(NbThemeModule.forRoot({
                    name: 'default'
                }).providers as Array<Provider>),
                ...(NbSidebarModule.forRoot().providers as Array<Provider>),
                ...(NbDatepickerModule.forRoot().providers as Array<Provider>),
                ...(NbDateFnsDateModule.forRoot({}).providers as Array<Provider>),
                ...(NbDialogModule.forRoot().providers as Array<Provider>),
                ...(NbWindowModule.forRoot({
                    buttons: {
                        minimize: false,
                        maximize: false
                    }
                }).providers as Array<Provider>),
                ...(NbToastrModule.forRoot().providers as Array<Provider>),
                ...(NbMenuModule.forRoot().providers as Array<Provider>)
            ]
        };
    }
}
