/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Stat 1 = TwentyFourHours 2 = OverTwentyFourHours
 */
export enum RequestUrgency {
    Stat = 'Stat',
    TwentyFourHours = 'TwentyFourHours',
    OverTwentyFourHours = 'OverTwentyFourHours'
}
