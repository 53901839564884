/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = None 1 = Pending 2 = Failed 3 = Completed
 */
export enum TransformStatus {
    None = 'None',
    Pending = 'Pending',
    Failed = 'Failed',
    Completed = 'Completed'
}
