/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Normal 1 = Restricted 2 = VeryRestricted
 */
export enum Confidentiality {
    Normal = 'Normal',
    Restricted = 'Restricted',
    VeryRestricted = 'VeryRestricted'
}
