import { Injectable } from '@angular/core';
import { RouterReducerState, SerializedRouterStateSnapshot } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RouterStateUrl } from '../../utils/custom-serializer.util';
import { selectRoutingFeature } from './routing-slice.selectors';

@Injectable({
    providedIn: 'root'
})
export class RoutingSliceFacade {
    public get routingData$() {
        return this.store.select(selectRoutingFeature) as Observable<RouterReducerState<RouterStateUrl & SerializedRouterStateSnapshot>>;
    }

    constructor(private store: Store) {}
}
