/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Bounced 1 = Delivered 2 = Pending 3 = Sent 4 = Unknown 5 = Forwarded 6 = Deleted 7 = Sending 8 = Forwarding 9 = Replying 10 = Failed 11 = Replied 12 = Exported 13 = Processed 14 = Read 15 = Faxed 16 = Received 17 = Billed 18 = Cancelled 19 = Routed 20 = Problem 21 = Unauthorized 22 = Completed 23 = Rejected 24 = Processing 25 = Reviewing 26 = Suspended
 */
export enum MessageEventType {
    Bounced = 'Bounced',
    Delivered = 'Delivered',
    Pending = 'Pending',
    Sent = 'Sent',
    Unknown = 'Unknown',
    Forwarded = 'Forwarded',
    Deleted = 'Deleted',
    Sending = 'Sending',
    Forwarding = 'Forwarding',
    Replying = 'Replying',
    Failed = 'Failed',
    Replied = 'Replied',
    Exported = 'Exported',
    Processed = 'Processed',
    Read = 'Read',
    Faxed = 'Faxed',
    Received = 'Received',
    Billed = 'Billed',
    Cancelled = 'Cancelled',
    Routed = 'Routed',
    Problem = 'Problem',
    Unauthorized = 'Unauthorized',
    Completed = 'Completed',
    Rejected = 'Rejected',
    Processing = 'Processing',
    Reviewing = 'Reviewing',
    Suspended = 'Suspended'
}
