/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = DoNothing 1 = ReturnToIntake 2 = GoToNextMessage
 */
export enum AfterProcessMessage {
    DoNothing = 'DoNothing',
    ReturnToIntake = 'ReturnToIntake',
    GoToNextMessage = 'GoToNextMessage'
}
