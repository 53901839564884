import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Environment } from '@kno2/shared/util/configuration';
import { NbIconLibraries, NbJSThemeVariable } from '@nebular/theme';

@Injectable({
    providedIn: 'root'
})
export class ThemeHotloaderService {
    constructor(
        private httpClient: HttpClient,
        private environment: Environment,
        private iconLibraries: NbIconLibraries,
        @Inject(DOCUMENT) private document: Document
    ) {}

    public reloadTheme = (): Observable<boolean> => {
        const { name, assetsUrl } = this.environment.brand;
        return this.httpClient.get<NbJSThemeVariable>(`${assetsUrl}/${name}/theme.json`).pipe(
            tap((payload) => {
                const styles = this.document.styleSheets[0];
                const ruleItems = Object.entries(payload).reduce((prev, curr) => {
                    const [key, value] = curr;
                    return prev + `--${key}: ${value}; `;
                }, '');
                styles.addRule('.nb-theme-default', ruleItems);
            }),
            map(() => true),
            catchError((err) => {
                console.log('Error fetching theme for hot reloading: ', err);
                return throwError(err);
            })
        );
    };

    public configure(): void {
        this.iconLibraries.registerFontPack('font-awesome', { packClass: 'fa', iconClassPrefix: 'fa' });
    }
}
