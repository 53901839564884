import { ReservedShortcutEvent } from './reserved-shortcut-event.constants';
import { Shortcut } from '../models/shortcut.model';
import { RESERVED_SHORTCUT_KEYS } from './reserved-shortcut-keys.constants';

export const SHORTCUT_DEFAULTS: Array<Shortcut> = [
    {
        name: 'Open Profile Settings',
        event: ReservedShortcutEvent.GoToSettings,
        keys: RESERVED_SHORTCUT_KEYS[ReservedShortcutEvent.GoToSettings]
    },
    {
        name: 'Navigate to Intake',
        event: ReservedShortcutEvent.GoToIntake,
        keys: RESERVED_SHORTCUT_KEYS[ReservedShortcutEvent.GoToIntake]
    },
    {
        name: 'Navigate to Release',
        event: ReservedShortcutEvent.GoToRelease,
        keys: RESERVED_SHORTCUT_KEYS[ReservedShortcutEvent.GoToRelease]
    },
    {
        name: 'Navigate to Triage',
        event: ReservedShortcutEvent.GoToTriage,
        keys: RESERVED_SHORTCUT_KEYS[ReservedShortcutEvent.GoToTriage]
    },

    {
        name: 'Select All Items in a List',
        event: ReservedShortcutEvent.SelectAll,
        keys: RESERVED_SHORTCUT_KEYS[ReservedShortcutEvent.SelectAll]
    },
    {
        name: 'Save All Changes',
        event: ReservedShortcutEvent.SaveAllChanges,
        keys: RESERVED_SHORTCUT_KEYS[ReservedShortcutEvent.SaveAllChanges]
    },
    {
        name: 'Save Changes',
        event: ReservedShortcutEvent.SaveChanges,
        keys: RESERVED_SHORTCUT_KEYS[ReservedShortcutEvent.SaveChanges]
    },
    {
        name: 'Undo Changes',
        event: ReservedShortcutEvent.UndoChanges,
        keys: RESERVED_SHORTCUT_KEYS[ReservedShortcutEvent.UndoChanges]
    }
];
