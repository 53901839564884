/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * Defines EDM term kinds.  0 = None 1 = Type 2 = Value
 */
export enum EdmTermKind {
    None = 'None',
    Type = 'Type',
    Value = 'Value'
}
