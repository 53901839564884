/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Accepted 1 = Declined 2 = Completed 3 = ConfirmCancel 4 = Admitted 5 = Consultation 6 = Scheduled 7 = NoShow
 */
export enum ConversationResponseType {
    Accepted = 'Accepted',
    Declined = 'Declined',
    Completed = 'Completed',
    ConfirmCancel = 'ConfirmCancel',
    Admitted = 'Admitted',
    Consultation = 'Consultation',
    Scheduled = 'Scheduled',
    NoShow = 'NoShow'
}
