/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = User 1 = ServiceAccount 2 = AuthorizedApplication
 */
export enum SubjectType {
    User = 'User',
    ServiceAccount = 'ServiceAccount',
    AuthorizedApplication = 'AuthorizedApplication'
}
