import { createApi } from 'ngrx-rtk-query';
import { configureBaseQuery } from '@kno2/shared/data-access/+store';
import { PagedResource } from '../../../common/paged.resource';
import { IntakeMessageResource } from '../../../models/intake-message-resource.model';
import { MessagesCriteriaResource } from '../../../models/messages-criteria-resource.model';

export const intakeApiKey = 'intakeCache';
export const intakeCacheTags = {
    mailMessages: 'mailMessages'
};
export const intakeApi = createApi({
    reducerPath: intakeApiKey,
    baseQuery: configureBaseQuery('/api/intake/'),
    tagTypes: Object.values(intakeCacheTags),
    endpoints: (build) => ({
        getIntake: build.query<PagedResource<IntakeMessageResource>, MessagesCriteriaResource>({
            query: (requestParams) => ({
                url: '',
                method: 'POST',
                body: requestParams
            }),
            providesTags: [intakeCacheTags.mailMessages]
        })
    })
});

export const { useGetIntakeQuery, useLazyGetIntakeQuery } = intakeApi;
