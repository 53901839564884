/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Original 1 = Pdf 2 = Hl7v2
 */
export enum ExportType {
    Original = 'Original',
    Pdf = 'Pdf',
    Hl7v2 = 'Hl7v2'
}
