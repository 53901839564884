export const paginationSliceKey = 'pagination';

export interface PaginationOptions {
    pageNumber?: number;
    pageSize?: number;
    search?: string;
}

export interface PaginationState {
    [key: string]: PaginationOptions | null;
}
