import { TriageBatchResource } from './../../../models/triage-batch-resource.model';
import { TriageUploadResource } from './../../../models/triage-upload-resource.model';
import { configureBaseQuery } from '@kno2/shared/data-access/+store';
import { createApi } from 'ngrx-rtk-query';

export const triageApiKey = 'triageApi';
const cacheTags = {
    batch: 'batch'
};

export const triageApi = createApi({
    reducerPath: triageApiKey,
    tagTypes: Object.values(cacheTags),
    baseQuery: configureBaseQuery('/api/triage'),
    endpoints: (build) => ({
        create: build.mutation<{ id: string }, Omit<TriageUploadResource, 'files'> & { files: Array<File> }>({
            query: (args) => {
                const { files, ...payload } = args;
                const formData = new FormData();

                files.forEach((file, index) => {
                    formData.append(`file[${index}]`, file, file.name);
                });
                formData.append('batch', JSON.stringify(payload));

                return { url: '', method: 'POST', body: formData };
            }
        }),
        getBatch: build.query<TriageBatchResource, string>({
            query: (id) => ({
                url: `/${id}`
            }),
            providesTags: (params, err, id) => [{ type: cacheTags.batch, id }]
        }),
        processBatch: build.mutation<Array<string>, { batchId: string; batch: TriageBatchResource }>({
            query: ({ batchId, batch }) => ({
                url: `/${batchId}`,
                method: 'PUT',
                body: batch
            }),
            invalidatesTags: (params, err, args) => [{ type: cacheTags.batch, id: args.batchId }]
        })
    })
});

export const { useCreateMutation, useGetBatchQuery, useProcessBatchMutation } = triageApi;
