/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 100 = Continue 101 = SwitchingProtocols 200 = OK 201 = Created 202 = Accepted 203 = NonAuthoritativeInformation 204 = NoContent 205 = ResetContent 206 = PartialContent 300 = MultipleChoices 300 = Ambiguous 301 = MovedPermanently 301 = Moved 302 = Found 302 = Redirect 303 = SeeOther 303 = RedirectMethod 304 = NotModified 305 = UseProxy 306 = Unused 307 = TemporaryRedirect 307 = RedirectKeepVerb 400 = BadRequest 401 = Unauthorized 402 = PaymentRequired 403 = Forbidden 404 = NotFound 405 = MethodNotAllowed 406 = NotAcceptable 407 = ProxyAuthenticationRequired 408 = RequestTimeout 409 = Conflict 410 = Gone 411 = LengthRequired 412 = PreconditionFailed 413 = RequestEntityTooLarge 414 = RequestUriTooLong 415 = UnsupportedMediaType 416 = RequestedRangeNotSatisfiable 417 = ExpectationFailed 426 = UpgradeRequired 500 = InternalServerError 501 = NotImplemented 502 = BadGateway 503 = ServiceUnavailable 504 = GatewayTimeout 505 = HttpVersionNotSupported
 */
export enum HttpStatusCode {
    Continue = 'Continue',
    SwitchingProtocols = 'SwitchingProtocols',
    OK = 'OK',
    Created = 'Created',
    Accepted = 'Accepted',
    NonAuthoritativeInformation = 'NonAuthoritativeInformation',
    NoContent = 'NoContent',
    ResetContent = 'ResetContent',
    PartialContent = 'PartialContent',
    MultipleChoices = 'MultipleChoices',
    Ambiguous = 'Ambiguous',
    MovedPermanently = 'MovedPermanently',
    Moved = 'Moved',
    Found = 'Found',
    Redirect = 'Redirect',
    SeeOther = 'SeeOther',
    RedirectMethod = 'RedirectMethod',
    NotModified = 'NotModified',
    UseProxy = 'UseProxy',
    Unused = 'Unused',
    TemporaryRedirect = 'TemporaryRedirect',
    RedirectKeepVerb = 'RedirectKeepVerb',
    BadRequest = 'BadRequest',
    Unauthorized = 'Unauthorized',
    PaymentRequired = 'PaymentRequired',
    Forbidden = 'Forbidden',
    NotFound = 'NotFound',
    MethodNotAllowed = 'MethodNotAllowed',
    NotAcceptable = 'NotAcceptable',
    ProxyAuthenticationRequired = 'ProxyAuthenticationRequired',
    RequestTimeout = 'RequestTimeout',
    Conflict = 'Conflict',
    Gone = 'Gone',
    LengthRequired = 'LengthRequired',
    PreconditionFailed = 'PreconditionFailed',
    RequestEntityTooLarge = 'RequestEntityTooLarge',
    RequestUriTooLong = 'RequestUriTooLong',
    UnsupportedMediaType = 'UnsupportedMediaType',
    RequestedRangeNotSatisfiable = 'RequestedRangeNotSatisfiable',
    ExpectationFailed = 'ExpectationFailed',
    UpgradeRequired = 'UpgradeRequired',
    InternalServerError = 'InternalServerError',
    NotImplemented = 'NotImplemented',
    BadGateway = 'BadGateway',
    ServiceUnavailable = 'ServiceUnavailable',
    GatewayTimeout = 'GatewayTimeout',
    HttpVersionNotSupported = 'HttpVersionNotSupported'
}
