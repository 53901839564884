import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    INITIAL_CURRENT_MESSAGE_STATE,
    messagePreviewAdapter,
    currentMessageSliceKey,
    CurrentMessageState,
    composeAttachmentPreviewKey
} from './current-message-slice.config';
import { AttachmentPreview } from './models/current-message-attachment-preview.model';
import { MessageDraftChanges } from './models/current-message-draft-changes.model';
import { MessageConfigurationType, MessageConfiguration } from './models/current-message-meta.model';
import { AttachmentSendType } from '../../../models/attachment-send-type.model';

const currentMessageSlice = createSlice({
    name: currentMessageSliceKey,
    initialState: INITIAL_CURRENT_MESSAGE_STATE,
    reducers: {
        resetMessageState: () => INITIAL_CURRENT_MESSAGE_STATE,

        // message meta actions
        setMessageMetaSourceMessageId: (state: CurrentMessageState, action: PayloadAction<string>) => {
            state.messageMeta.sourceMessageId = action.payload;
        },
        setMessageMeta: (state: CurrentMessageState, action: PayloadAction<MessageConfiguration>) => {
            state.messageMeta = { ...state.messageMeta, ...action.payload };
        },
        setMessageMetaMessageType: (state: CurrentMessageState, action: PayloadAction<MessageConfigurationType>) => {
            state.messageMeta.messageType = action.payload;
        },
        clearMessageMeta: (state: CurrentMessageState) => {
            state.messageMeta = INITIAL_CURRENT_MESSAGE_STATE.messageMeta;
        },

        // draft changes actions
        setDraftMessageDisclosureReason: (state: CurrentMessageState, action: PayloadAction<string>) => {
            state.draftMessageChanges.disclosureReason = action.payload;
        },
        setDraftMessageAdditionalComments: (state: CurrentMessageState, action: PayloadAction<string>) => {
            state.draftMessageChanges.additionalComments = action.payload;
        },
        setDraftMessageBody: (state: CurrentMessageState, action: PayloadAction<string>) => {
            state.draftMessageChanges.messageBody = action.payload;
        },
        setIncludePatientDetailsInBody: (state: CurrentMessageState, action: PayloadAction<boolean>) => {
            state.draftMessageChanges.includePatientDetailsInBody = action.payload;
        },
        setDraftMessageConversion: (state: CurrentMessageState, action: PayloadAction<MessageDraftChanges['attachmentConversionType']>) => {
            state.draftMessageChanges.attachmentConversionType = action.payload;
        },
        clearDraftMessageConversion: (state: CurrentMessageState) => {
            state.draftMessageChanges.attachmentConversionType = AttachmentSendType.Pdf;
        },

        addDraftMessageToAddress: (state: CurrentMessageState, action: PayloadAction<string>) => {
            if (!state.draftMessageChanges.messageHeader.to.includes(action.payload)) state.draftMessageChanges.messageHeader.to.push(action.payload);
        },
        setDraftMessageToAddresses: (state: CurrentMessageState, action: PayloadAction<Array<string>>) => {
            state.draftMessageChanges.messageHeader.to = action.payload;
        },
        clearDraftMessageToAddresses: (state: CurrentMessageState) => {
            state.draftMessageChanges.messageHeader.to = [];
        },

        setDraftMessageFromAddress: (state: CurrentMessageState, action: PayloadAction<string>) => {
            state.draftMessageChanges.messageHeader.fromAddress = action.payload;
        },
        clearDraftMessageFromAddress: (state: CurrentMessageState) => {
            state.draftMessageChanges.messageHeader.fromAddress = null;
        },

        setDraftMessageReleaseType: (state: CurrentMessageState, action: PayloadAction<string>) => {
            state.draftMessageChanges.messageHeader.releaseTypeId = action.payload;
        },
        clearDraftMessageReleaseType: (state: CurrentMessageState) => {
            state.draftMessageChanges.messageHeader.releaseTypeId = null;
        },
        setDraftMessageConversation: (state: CurrentMessageState, action: PayloadAction<MessageDraftChanges['conversation']>) => {
            state.draftMessageChanges.conversation = action.payload;
        },

        // attachments editor actions
        toggleAttachmentsMultiSelectionMode: (state: CurrentMessageState, action: PayloadAction<boolean>) => {
            state.attachmentsEditor.multipleSelectionsEnabled = action.payload;
        },
        toggleAttachmentsEditorMode: (state: CurrentMessageState, action: PayloadAction<boolean>) => {
            state.attachmentsEditor.editorModeEnabled = action.payload;
        },
        addPreviewIdInAttachmentsEditor: (state: CurrentMessageState, action: PayloadAction<{ previewId: string }>) => {
            if (!state.attachmentsEditor.markedPreviewsIds.includes(action.payload.previewId))
                state.attachmentsEditor.markedPreviewsIds.push(action.payload.previewId);
        },
        removePreviewIdInAttachmentsEditor: (state: CurrentMessageState, action: PayloadAction<string>) => {
            state.attachmentsEditor.markedPreviewsIds = state.attachmentsEditor.markedPreviewsIds.filter((previewId) => previewId !== action.payload);
        },
        clearPreviewIdsInAttachmentsEditor: (state: CurrentMessageState) => {
            state.attachmentsEditor.markedPreviewsIds = [];
        },

        // set current selections
        setSelectedMessage: (state: CurrentMessageState, action: PayloadAction<{ messageId: string }>) => {
            state.selectedMesageId = action.payload.messageId;
        },
        setSelectedAttachment: (state: CurrentMessageState, action: PayloadAction<{ attachmentId: string }>) => {
            state.selectedAttachmentId = action.payload.attachmentId;
        },
        setSelectedAttachmentPreview: (state: CurrentMessageState, action: PayloadAction<{ previewId: string }>) => {
            state.selectedPreviewId = action.payload.previewId;
        },
        clearSelectedMessage: (state: CurrentMessageState) => {
            state.selectedMesageId = null;
        },
        clearSelectedAttachmentPreview: (state: CurrentMessageState) => {
            state.selectedPreviewId = null;
        },
        clearSelectedAttachment: (state: CurrentMessageState) => {
            state.selectedAttachmentId = null;
        },

        // attachment actions
        addManyPreviewImages: (state: CurrentMessageState, action: PayloadAction<Array<AttachmentPreview>>) => {
            messagePreviewAdapter.setMany(
                state.attachmentPreviews,
                action.payload.map((x) => ({
                    ...x,
                    id: composeAttachmentPreviewKey(x.parentAttachmentId, x.pageNumber)
                }))
            );
        },
        clearPreviewImages: (state: CurrentMessageState) => {
            messagePreviewAdapter.removeAll(state.attachmentPreviews);
        },
        removePreviewImage: (state: CurrentMessageState, action: PayloadAction<string>) => {
            messagePreviewAdapter.removeOne(state.attachmentPreviews, action.payload);
        },
        rotatePreviewImage: (
            state: CurrentMessageState,
            action: PayloadAction<{
                pageId: string;
                rotationFromPreviousOriginInDegrees: number;
            }>
        ) => {
            const { rotationFromPreviousOriginInDegrees, pageId } = action.payload;
            const targetEntity = state.attachmentPreviews.entities[pageId];

            messagePreviewAdapter.updateOne(state.attachmentPreviews, {
                id: pageId,
                changes: {
                    transformationMeta: {
                        ...targetEntity.transformationMeta,
                        rotationInDegrees: targetEntity.transformationMeta.rotationInDegrees + rotationFromPreviousOriginInDegrees
                    }
                }
            });
        },
        markPreviewImageForDeletion: (state: CurrentMessageState, action: PayloadAction<{ pageId: string; markForDeletion: boolean }>) => {
            const { pageId, markForDeletion } = action.payload;
            const targetEntity = state.attachmentPreviews.entities[pageId];

            messagePreviewAdapter.updateOne(state.attachmentPreviews, {
                id: pageId,
                changes: {
                    transformationMeta: {
                        ...targetEntity.transformationMeta,
                        markedForDeletion: markForDeletion
                    }
                }
            });
        },
        resetPreviewImageTransformation: (state: CurrentMessageState, action: PayloadAction<string>) => {
            messagePreviewAdapter.updateOne(state.attachmentPreviews, {
                id: action.payload,
                changes: {
                    transformationMeta: {
                        rotationInDegrees: 0,
                        markedForDeletion: false
                    }
                }
            });
        },
        resetPreviewsByAttachmentId: (state: CurrentMessageState, action: PayloadAction<string>) => {
            messagePreviewAdapter.updateMany(
                state.attachmentPreviews,
                messagePreviewAdapter
                    .getSelectors()
                    .selectAll(state.attachmentPreviews)
                    .filter(({ parentAttachmentId }) => action.payload === parentAttachmentId)
                    .map((page) => ({
                        id: page.id,
                        changes: {
                            transformationMeta: {
                                rotationInDegrees: 0,
                                markedForDeletion: false
                            }
                        }
                    }))
            );
        },
        setPreviewImageSource: (state: CurrentMessageState, action: PayloadAction<{ pageId: string; source: string; isThumbnail: boolean }>) => {
            const { pageId, source, isThumbnail } = action.payload;

            messagePreviewAdapter.updateOne(state.attachmentPreviews, {
                id: pageId,
                changes: {
                    [isThumbnail ? 'thumbnailSource' : 'previewSource']: source
                }
            });
        }
    }
});

export const currentMessageReducer = currentMessageSlice.reducer;
export const {
    resetMessageState,
    setDraftMessageDisclosureReason,
    setDraftMessageAdditionalComments,
    setDraftMessageBody,
    setIncludePatientDetailsInBody,
    setDraftMessageConversion,
    clearDraftMessageConversion,
    setMessageMetaSourceMessageId,
    setMessageMetaMessageType,
    setMessageMeta,
    clearMessageMeta,
    addDraftMessageToAddress,
    setDraftMessageToAddresses,
    clearDraftMessageToAddresses,
    setDraftMessageFromAddress,
    clearDraftMessageFromAddress,
    setDraftMessageReleaseType,
    clearDraftMessageReleaseType,
    setDraftMessageConversation,
    toggleAttachmentsMultiSelectionMode,
    toggleAttachmentsEditorMode,
    addPreviewIdInAttachmentsEditor,
    removePreviewIdInAttachmentsEditor,
    clearPreviewIdsInAttachmentsEditor,
    setSelectedMessage,
    setSelectedAttachmentPreview,
    clearSelectedMessage,
    clearSelectedAttachment,
    clearSelectedAttachmentPreview,
    setSelectedAttachment,
    rotatePreviewImage,
    addManyPreviewImages,
    clearPreviewImages,
    removePreviewImage,
    resetPreviewImageTransformation,
    resetPreviewsByAttachmentId,
    markPreviewImageForDeletion,
    setPreviewImageSource,
    setDraftMessageConversation: setConversation
} = currentMessageSlice.actions;
