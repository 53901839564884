/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = None 1 = Phone 2 = Email 4 = RecoveryCode 8 = Totp 16 = Guardian
 */
export enum MfaType {
    None = 'None',
    Phone = 'Phone',
    Email = 'Email',
    RecoveryCode = 'RecoveryCode',
    Totp = 'Totp',
    Guardian = 'Guardian'
}
