/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = None 1 = Accepted 2 = Declined 3 = Cancelled 4 = Scheduled 5 = Consulted 6 = NoShow 7 = Completed
 */
export enum ResponseStatus {
    None = 'None',
    Accepted = 'Accepted',
    Declined = 'Declined',
    Cancelled = 'Cancelled',
    Scheduled = 'Scheduled',
    Consulted = 'Consulted',
    NoShow = 'NoShow',
    Completed = 'Completed'
}
