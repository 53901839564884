import { ConversationAcceptanceResponse } from './../../../models/conversation-acceptance-response.model';
import { configureBaseQuery } from '@kno2/shared/data-access/+store';
import { createApi } from 'ngrx-rtk-query';
import { ConversationResponseRequest } from '../../../models';

export const conversationsApiKey = 'conversations';

export const conversationsApi = createApi({
    reducerPath: conversationsApiKey,
    baseQuery: configureBaseQuery('/api/conversations'),
    endpoints: (build) => ({
        updateResponse: build.mutation<ConversationAcceptanceResponse, { conversationId: string; payload: ConversationResponseRequest }>({
            query: ({ conversationId, payload }) => ({
                url: `${conversationId}/respond`,
                method: 'POST',
                body: {
                    responseAction: 'AutoSend',
                    ...payload
                }
            })
        })
    })
});

export const { useUpdateResponseMutation } = conversationsApi;
