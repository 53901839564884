import { VerifyTwoFactorResource } from './../../../models/verify-two-factor-resource.model';
import { configureBaseQuery } from '@kno2/shared/data-access/+store';
import { HttpMethod } from '@auth0/auth0-angular';
import { createApi } from 'ngrx-rtk-query';
import { PagedResource } from '../../../common/paged.resource';
import {
    DocumentSourceResource,
    JobFunctionResource,
    OrganizationUserResource,
    RuleResource,
    SetUserIntakeRulesResource,
    SimpleDocumentSourceResource,
    UserDetailsResource
} from '../../../models';
import { UsersQueryParams } from './users-api-users-query-params.model';

export const usersApiKey = 'users';
export const usersCacheTags = {
    users: 'users',
    jobFunctions: 'jobfunctions',
    rules: 'rules',
    documentSources: 'documentSources'
};

export const usersApi = createApi({
    reducerPath: usersApiKey,
    tagTypes: Object.values(usersCacheTags),
    baseQuery: configureBaseQuery('/api/users'),
    endpoints: (build) => ({
        getUserById: build.query<UserDetailsResource, string>({
            query: (userId) => ({
                url: `/${userId}`
            }),
            providesTags: (result, error, userId) => [{ type: usersCacheTags.users, id: userId }]
        }),
        getUsers: build.query<PagedResource<OrganizationUserResource>, UsersQueryParams | void>({
            query: (params: UsersQueryParams) => ({
                url: '',
                params: {
                    ...Object.fromEntries(
                        Object.entries({
                            ...params,
                            sort: params.sort ? `[{"column":"${params.sort[0].column}","isSortDesc":${params.sort[0].isSortDesc}}]` : null
                        }).filter(([, value]) => typeof value === 'boolean' || !!value)
                    )
                }
            }),
            providesTags: [{ type: usersCacheTags.users, id: 'LIST' }]
        }),
        deleteAuthenticators: build.mutation<void, string>({
            query: (id) => ({
                method: 'DELETE',
                url: `${id}/authenticators`
            }),
            invalidatesTags: (result, error, arg) => [
                { type: usersCacheTags.users, id: 'LIST' },
                { type: usersCacheTags.users, id: arg }
            ]
        }),
        jobFunctions: build.query<Array<JobFunctionResource>, void>({
            query: () => ({
                url: '/jobs'
            }),
            providesTags: [usersCacheTags.jobFunctions]
        }),
        getRulesByUser: build.query<Array<RuleResource>, string>({
            query: (id) => ({
                url: `${id}/rules`
            }),
            providesTags: [usersCacheTags.rules]
        }),
        getDocumentSourcesByUser: build.query<Array<DocumentSourceResource>, string>({
            query: (id) => ({
                url: `${id}/documentsources`
            }),
            providesTags: [usersCacheTags.documentSources]
        }),
        createUser: build.mutation<{ id: string }, UserDetailsResource>({
            query: (resource) => ({
                url: '',
                method: HttpMethod.Post,
                body: {
                    ...Object.fromEntries(Object.entries(resource).filter(([, value]) => !!value)),
                    email: resource?.email ?? resource?.userName,
                    isPointClickCareUser: (<any>resource).isPointClickCareUser
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: usersCacheTags.users, id: 'LIST' },
                { type: usersCacheTags.users, id: result.id }
            ]
        }),
        updateUser: build.mutation<{ id: string }, UserDetailsResource>({
            query: (resource) => ({
                url: `${resource.id}`,
                method: HttpMethod.Put,
                body: {
                    ...Object.fromEntries(Object.entries(resource).filter(([, value]) => !!value)),
                    email: resource?.email ?? resource?.userName,
                    isPointClickCareUser: (<any>resource).isPointClickCareUser
                }
            }),
            invalidatesTags: (result, error, resource) => [
                { type: usersCacheTags.users, id: 'LIST' },
                { type: usersCacheTags.users, id: resource.id }
            ]
        }),
        externalAdmin: build.mutation<void, { userName: string; assignToAllOrgs: boolean }>({
            query: (body) => ({
                url: `/externaladmin`,
                method: HttpMethod.Post,
                body
            })
        }),
        setDocumentSourcesForUser: build.mutation<void, { sources: Array<SimpleDocumentSourceResource>; userId: string }>({
            query: (params) => ({
                url: `/${params.userId}/documentsources`,
                method: HttpMethod.Put,
                body: params.sources
            }),
            invalidatesTags: [usersCacheTags.documentSources]
        }),
        setIntakeRulesForUser: build.mutation<void, { rules: Array<SetUserIntakeRulesResource>; userId: string }>({
            query: (params) => ({
                url: `/${params.userId}/intakerules`,
                method: HttpMethod.Put,
                body: params.rules
            }),
            invalidatesTags: [usersCacheTags.rules]
        }),
        enableUser: build.mutation<void, { userId: string; enable: boolean }>({
            query: (params) => ({
                url: `/${params.userId}/enable`,
                method: HttpMethod.Post,
                params: { enable: params.enable }
            }),
            invalidatesTags: (result, error, params) => [
                { type: usersCacheTags.users, id: 'LIST' },
                { type: usersCacheTags.users, id: params.userId }
            ]
        }),
        resendInvitation: build.mutation<void, UserDetailsResource>({
            query: (params) => ({
                url: '/resendinvitation',
                method: HttpMethod.Post,
                body: params
            })
        }),
        resetPassword: build.mutation<void, UserDetailsResource>({
            query: (params) => ({
                url: '/resetpassword',
                method: HttpMethod.Post,
                body: params
            })
        }),
        sendCode: build.mutation<void, { method: 'text' | 'call' }>({
            query: (params) => ({
                url: `/sendcode?`,
                params: {
                    method: params.method
                }
            })
        }),
        verifyCode: build.mutation<void, VerifyTwoFactorResource & { userId: string }>({
            query: ({ userId, ...params }) => ({
                url: `/${userId}/verifycode`,
                method: HttpMethod.Post,
                body: params
            })
        }),
        enableTwoFactorAuth: build.mutation<void, { code: string }>({
            query: (params) => ({
                url: '/enabletfa',
                params
            }),
            invalidatesTags: [{ type: usersCacheTags.users, id: 'LIST' }]
        })
    })
});

export const {
    useGetUserByIdQuery,
    useGetUsersQuery,
    useJobFunctionsQuery,
    useGetRulesByUserQuery,
    useGetDocumentSourcesByUserQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteAuthenticatorsMutation,
    useSetDocumentSourcesForUserMutation,
    useSetIntakeRulesForUserMutation,
    useEnableUserMutation,
    useResendInvitationMutation,
    useResetPasswordMutation,
    useSendCodeMutation,
    useVerifyCodeMutation,
    useEnableTwoFactorAuthMutation,
    useExternalAdminMutation
} = usersApi;
