import { AttachmentSendType } from './../../../models/attachment-send-type.model';
import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { AttachmentPreview } from './models/current-message-attachment-preview.model';
import { MessageDraftChanges } from './models/current-message-draft-changes.model';
import { MessageConfiguration } from './models/current-message-meta.model';

export const currentMessageSliceKey = 'currentMessage';

export interface CurrentMessageState {
    selectedAttachmentId: string | null;
    selectedMesageId: string | null;
    selectedPreviewId: string | null;
    attachmentPreviews: EntityState<AttachmentPreview>;
    attachmentsEditor: {
        multipleSelectionsEnabled: boolean;
        editorModeEnabled: boolean;
        markedPreviewsIds: Array<string>;
    };
    draftMessageChanges: MessageDraftChanges;
    messageMeta: MessageConfiguration;
}

export const composeAttachmentPreviewKey = (attachmentId: string, pageNumber: number): string => attachmentId + pageNumber.toString();

export const messagePreviewAdapter = createEntityAdapter<AttachmentPreview>({
    selectId: (attachment) => composeAttachmentPreviewKey(attachment.parentAttachmentId, attachment.pageNumber)
});

export const INITIAL_CURRENT_MESSAGE_STATE: CurrentMessageState = {
    selectedMesageId: null,
    selectedAttachmentId: null,
    selectedPreviewId: null,
    attachmentPreviews: messagePreviewAdapter.getInitialState(),
    attachmentsEditor: {
        multipleSelectionsEnabled: false,
        editorModeEnabled: false,
        markedPreviewsIds: []
    },
    draftMessageChanges: {
        messageHeader: {
            to: [],
            fromAddress: null,
            releaseTypeId: null
        },
        attachmentConversionType: AttachmentSendType.Pdf,
        messageBody: '',
        includePatientDetailsInBody: true,
        disclosureReason: '',
        additionalComments: '',
        conversation: null
    },
    messageMeta: {
        canSelectPatientVisit: false,
        attachmentsReadonly: false,
        messageMetaReadonly: false,
        messageBodyReadonly: false,
        patientReadonly: false,
        allowAttachmentUpload: false,
        messageType: null,
        origin: null
    }
};
