<nb-card>
    <nb-card-header>Unauthorized</nb-card-header>
    <nb-card-body>
        <div class="py-2">
            <p class="text-muted">
                You are not allowed to view this page. Please check your permissions. If the the issue persists, contact your Organization
                Administrator.
            </p>
            <button
                nbButton
                class="mt-2"
                status="primary"
                routerLink="/">
                Return to Home
            </button>
        </div>
    </nb-card-body>
</nb-card>
