/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = MessageFrom 1 = MessageDate 2 = PatientId 3 = PatientFirstName 4 = PatientMiddleName 5 = PatientLastName 6 = PatientGender 7 = PatientDOB 8 = AttachmentDocumentType 9 = AttachmentDate
 */
export enum DownloadProfileFilenameOptions {
    MessageFrom = 'MessageFrom',
    MessageDate = 'MessageDate',
    PatientId = 'PatientId',
    PatientFirstName = 'PatientFirstName',
    PatientMiddleName = 'PatientMiddleName',
    PatientLastName = 'PatientLastName',
    PatientGender = 'PatientGender',
    PatientDOB = 'PatientDOB',
    AttachmentDocumentType = 'AttachmentDocumentType',
    AttachmentDate = 'AttachmentDate'
}
