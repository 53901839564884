/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = DirectMessaging 1 = Fax
 */
export enum DocumentSourceProviderType {
    DirectMessaging = 'DirectMessaging',
    Fax = 'Fax'
}
