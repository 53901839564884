/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = NotApplicable 1 = NonXmlBody 2 = StructuredBody
 */
export enum CdaStructureType {
    NotApplicable = 'NotApplicable',
    NonXmlBody = 'NonXmlBody',
    StructuredBody = 'StructuredBody'
}
