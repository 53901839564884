import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'friendlyKeyName'
})
export class FriendlyKeyNamePipe implements PipeTransform {
    public transform(value: string): string {
        const regex = /\B[A-Z]+/g;
        return value.replace(regex, (s) => ' ' + s.toUpperCase());
    }
}
