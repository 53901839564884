import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DownloadBundleOptions } from '../models/download-bundle-options.model';
import { SharedApiKno2fyMainModule } from '../shared-api-kno2fy-main.module';

@Injectable({
    providedIn: SharedApiKno2fyMainModule
})
export class ExportService {
    constructor(private httpClient: HttpClient) {}

    /**
     * @param bundleOptions
     */
    public exportBundle(bundleOptions: DownloadBundleOptions): Observable<any> {
        return this.httpClient.post<any>(`/api/export/bundle`, bundleOptions, {
            responseType: 'blob' as 'json'
        });
    }
}
