import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ProfileApiFacade } from '../profile/profile-api.facade';
import { useGetEnabledFeaturesQuery } from './features.api';

@Injectable({
    providedIn: 'root'
})
export class FeaturesApiFacade {
    public getEnabledFeatures$ = this.profileApi.getOrganizationId$().pipe(switchMap((organizationId) => useGetEnabledFeaturesQuery(organizationId)));

    constructor(private profileApi: ProfileApiFacade) {}

    public isEnabled$(feature: string): Observable<boolean> {
        return this.profileApi.getCurrentUser().pipe(
            switchMap(({ data }) => useGetEnabledFeaturesQuery(data?.activeOrganization.id)),
            map(({ data }) => data?.includes(feature))
        );
    }
}
