/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = CreateConversation 1 = ForwardMessage 2 = VerifySurescripts 3 = MarkProcessed 4 = MarkDeleted 5 = WaitForDelivery 6 = PublishHook 7 = SetDocumentTypeForDocuSignConnectedIntegration 8 = SetPatientIdForDocuSignConnectedIntegration 9 = SetMessageAsProcessedAndAwaitingEmrExport 10 = UploadDocumentWithIntegration
 */
export enum ProcessingStepType {
    CreateConversation = 'CreateConversation',
    ForwardMessage = 'ForwardMessage',
    VerifySurescripts = 'VerifySurescripts',
    MarkProcessed = 'MarkProcessed',
    MarkDeleted = 'MarkDeleted',
    WaitForDelivery = 'WaitForDelivery',
    PublishHook = 'PublishHook',
    SetDocumentTypeForDocuSignConnectedIntegration = 'SetDocumentTypeForDocuSignConnectedIntegration',
    SetPatientIdForDocuSignConnectedIntegration = 'SetPatientIdForDocuSignConnectedIntegration',
    SetMessageAsProcessedAndAwaitingEmrExport = 'SetMessageAsProcessedAndAwaitingEmrExport',
    UploadDocumentWithIntegration = 'UploadDocumentWithIntegration'
}
