/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Not_Id_Proofed 1 = Manually_Id_Proofed 2 = Awaiting_Manual_Proofing 3 = Awaiting_Proof_Upload 4 = Proofed_Manually 5 = Proofed_By_Equifax
 */
export enum IdProofingStatus {
    Not_Id_Proofed = 'Not_Id_Proofed',
    Manually_Id_Proofed = 'Manually_Id_Proofed',
    Awaiting_Manual_Proofing = 'Awaiting_Manual_Proofing',
    Awaiting_Proof_Upload = 'Awaiting_Proof_Upload',
    Proofed_Manually = 'Proofed_Manually',
    Proofed_By_Equifax = 'Proofed_By_Equifax'
}
