/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = AllDates 1 = TwoYears 2 = ThreeYears 3 = FiveYears 4 = DateRange 5 = SpecificDates
 */
export enum DateDisclosure {
    AllDates = 'AllDates',
    TwoYears = 'TwoYears',
    ThreeYears = 'ThreeYears',
    FiveYears = 'FiveYears',
    DateRange = 'DateRange',
    SpecificDates = 'SpecificDates'
}
