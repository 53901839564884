import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AttachmentDownloadService {
    constructor(private httpClient: HttpClient) {}

    public getAttachmentPdf$(messageId: string, attachmentId: string): Observable<Blob> {
        return this.httpClient.get(`/api/messages/${messageId}/attachments/${attachmentId}`, {
            headers: {
                accept: 'application/pdf'
            },
            responseType: 'blob'
        });
    }
}
