import { MAIN_APP_SIDEBAR_TAG } from '../layout-element.tags';
import { Component, ViewChild, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import { NbLayoutComponent, NbOverlayContainerAdapter } from '@nebular/theme';
import { DOCUMENT } from '@angular/common';
import { createNestedLayoutMutationObserver } from '../../utils/nested-layout-observer.util';

@Component({
    selector: 'ngx-app-frame-layout',
    styleUrls: ['./app-frame.layout.scss'],
    template: `
        <nb-layout>
            <nb-layout-header fixed>
                <ng-content select="ngx-header"></ng-content>
            </nb-layout-header>

            <nb-sidebar
                class="menu-sidebar"
                [tag]="mainAppSidebarTag"
                responsive
                start>
                <div class="h-100 d-flex flex-column justify-content-between">
                    <ng-content select="nb-menu.top"></ng-content>
                    <ng-content select="nb-menu.bottom"></ng-content>
                </div>
            </nb-sidebar>

            <nb-layout-column class="content-padding">
                <ng-content select="router-outlet"></ng-content>
            </nb-layout-column>

            <nb-layout-footer fixed>
                <ng-content select="kno2-footer"></ng-content>
            </nb-layout-footer>
        </nb-layout>
    `
})
export class AppFrameLayoutComponent implements AfterViewInit, OnDestroy {
    @ViewChild(NbLayoutComponent) public layoutComponent: NbLayoutComponent;
    public mainAppSidebarTag = MAIN_APP_SIDEBAR_TAG;
    public mutationObserver: MutationObserver;

    constructor(@Inject(DOCUMENT) private document: Document, private overlayContainerAdapter: NbOverlayContainerAdapter) {}

    public ngAfterViewInit(): void {
        this.mutationObserver = createNestedLayoutMutationObserver(
            this.document.body,
            this.layoutComponent['elementRef'],
            this.overlayContainerAdapter
        );
        this.mutationObserver.observe(this.document.body, {
            childList: true,
            subtree: true
        });
    }

    public ngOnDestroy(): void {
        this.mutationObserver.disconnect();
    }
}
