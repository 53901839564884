import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';
import { FeaturesApiFacade } from '@kno2/shared/data-access/api-kno2fy-main';
import { Observable } from 'rxjs';
import { SHARED_ROUTES } from '@kno2/shared/util/configuration';
import { checkHasAuthorizationToAccess } from '@kno2/shared/util/common';

@Injectable({
    providedIn: 'root'
})
export class FeatureGuard {
    constructor(private router: Router, private featuresApi: FeaturesApiFacade) {}

    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.featuresApi.getEnabledFeatures$.pipe(
            filter(({ isFetching }) => !isFetching),
            map(({ data }) => checkHasAuthorizationToAccess(data, route?.data?.features || [], route?.data?.useStrictFeatureCheck ?? true)),
            tap((hasFeatures) => {
                // If the feature is not enabled, send the user to the root route. 'RedirectGuard' will pick it up from there
                if (!hasFeatures) this.router.navigateByUrl(SHARED_ROUTES.ROOT_ROUTE);
            })
        );
    }
}
