/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Received 1 = Accepted 2 = Declined 3 = Completed 4 = Cancelled 5 = Scheduled 6 = Rescheduled 7 = Draft 8 = Pending 9 = ResponseReceived
 */
export enum ConversationStatus {
    Received = 'Received',
    Accepted = 'Accepted',
    Declined = 'Declined',
    Completed = 'Completed',
    Cancelled = 'Cancelled',
    Scheduled = 'Scheduled',
    Rescheduled = 'Rescheduled',
    Draft = 'Draft',
    Pending = 'Pending',
    ResponseReceived = 'ResponseReceived'
}
