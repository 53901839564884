import { ActivatedRouteSnapshot } from '@angular/router';

export const parseDataFromEntireRoute = (route: ActivatedRouteSnapshot): Record<string, any> => {
    let data = { ...route.data, ...route.params };
    let currentRoute: ActivatedRouteSnapshot = route;

    while (currentRoute.firstChild) {
        data = { ...data, ...currentRoute.firstChild.data, ...currentRoute.firstChild.params };
        currentRoute = currentRoute.firstChild;
    }

    return data;
};

export const parseFullRoutePatternsFromEntireRoute = (route: ActivatedRouteSnapshot): string => {
    const config = route?.routeConfig;

    let matchedPath: string = config?.path ? `${config?.path}/` : '';

    if (route.firstChild) matchedPath += parseFullRoutePatternsFromEntireRoute(route.firstChild);

    return matchedPath.charAt(matchedPath.length - 1) === '/' ? matchedPath.slice(0, matchedPath.length - 1) : matchedPath;
};
