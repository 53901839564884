/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 1 = Intake 2 = Release
 */
export enum MessageType {
    Intake = 'Intake',
    Release = 'Release'
}
