import { selectAuthToken } from './auth-slice.selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { clearAuthState, clearAuthToken, setAuthToken } from '../auth/auth.slice';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthSliceFacade {
    constructor(private store: Store) {}

    public get authToken$(): Observable<string> {
        return this.store.select(selectAuthToken);
    }

    public setAuthToken = (token: string) => {
        this.store.dispatch(setAuthToken(token));
    };

    public clearAuthState = () => {
        this.store.dispatch(clearAuthState());
    };

    public clearAuthToken = () => {
        this.store.dispatch(clearAuthToken());
    };
}
