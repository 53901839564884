import { APIHandlerService } from '../../../services/api-handler.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MessageStatus } from '../../../models/message-status.model';
import {
    useGetQuery,
    useDeleteByIdMutation,
    useGetAllQuery,
    usePostGetMessagesQuery,
    useGetPreviewQuery,
    useCopyMutation,
    useUpdateMessageMutation,
    useForwardMutation,
    useGetAttachmentPreviewQuery,
    useUpdatePatientMutation,
    useMarkAsProcessedMutation,
    useTransformAttachmentMutation,
    useGetAttachmentQuery,
    useUpdateAttachmentMutation,
    useUploadAttachmentMutation,
    useGenerateAttachmentPreviewQuery,
    util as messagesApiUtil,
    useDeleteAttachmentByIdMutation,
    useCreateNewMessageMutation,
    useSendMutation,
    useReplyMutation,
    useGetMessageClassificationsQuery,
    useRestoreAttachmentMutation,
    useCreateTriageMutation
} from './messages.api';
import { Confidentiality } from '../../../models/confidentiality.model';
import { AttachmentResource } from '../../../models/attachment-resource.model';
import { SourceType } from '../../../models/source-type.model';
import { ProcessedType } from '../../../models/processed-type.model';
import { MessageResource } from '../../../models/message-resource.model';
import { ClassificationResource } from '../../../models/classification-resource.model';
import { AttachmentTransformResource } from '../../../models';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root'
})
export class MessagesApiFacade {
    public getAttachmentPreview$ = useGetAttachmentPreviewQuery;
    public getMessage$ = useGetQuery;
    public postGetMessages$ = usePostGetMessagesQuery;
    public getAllMessages$ = useGetAllQuery;
    public getMessagePreview$ = useGetPreviewQuery;
    public getAttachment$ = useGetAttachmentQuery;
    public generateAttachmentPreview$ = useGenerateAttachmentPreviewQuery;

    public createTriage = this.apiWrapperService.createMutationHandler<{ id: string }, typeof useCreateTriageMutation>(useCreateTriageMutation);

    public deleteById = this.apiWrapperService.createMutationHandler<void, typeof useDeleteByIdMutation>(useDeleteByIdMutation, {
        toastOnSuccess: (args) => (args.isDraft ? 'Draft has been deleted' : 'Message has been deleted'),
        toastOnFailure: (args) => (args.isDraft ? 'Draft could not be deleted' : 'Message could not be deleted')
    });

    public markMessageAsProcessed = this.apiWrapperService.createMutationHandler<void, typeof useMarkAsProcessedMutation>(
        useMarkAsProcessedMutation,
        {
            toastOnSuccess: 'Message has been marked as processed',
            toastOnFailure: 'Message could not be processed'
        }
    );

    public createNewMessage = this.apiWrapperService.createMutationHandler<MessageResource, typeof useCreateNewMessageMutation>(
        useCreateNewMessageMutation
    );

    public transformMessageAttachment = this.apiWrapperService.createMutationHandler<
        AttachmentTransformResource,
        typeof useTransformAttachmentMutation
    >(useTransformAttachmentMutation);

    public uploadAttachment = this.apiWrapperService.createMutationHandler<AttachmentResource, typeof useUploadAttachmentMutation>(
        useUploadAttachmentMutation,
        {
            toastOnFailure: 'Error Occurred While Uploading Attachment',
            payloadFormatter: (data) => {
                return {
                    ...data,
                    attachmentMeta: data.attachmentMeta || {
                        documentType: null,
                        confidentiality: Confidentiality.Normal,
                        documentDate: new Date().toISOString(),
                        documentDescription: '',
                        convert: true,
                        usePriorityQueue: true
                    }
                };
            }
        }
    );

    public sendMessage = this.apiWrapperService.createMutationHandler<void, typeof useSendMutation>(useSendMutation, {
        toastOnSuccess: 'Successfully Sent Message',
        toastOnFailure: 'Error Sending Message'
    });

    public sendReply = this.apiWrapperService.createMutationHandler<void, typeof useReplyMutation>(useReplyMutation, {
        toastOnSuccess: 'Successfully Saved Changes to Pages',
        toastOnFailure: 'Error Saving Changes to Pages'
    });

    public copyMessage = this.apiWrapperService.createMutationHandler<MessageResource, typeof useCopyMutation>(useCopyMutation);

    public updatePatient = this.apiWrapperService.createMutationHandler<void, typeof useUpdatePatientMutation>(useUpdatePatientMutation, {
        toastOnSuccess: 'Successfully saved patient details.',
        toastOnFailure: 'Error saving patient details.'
    });

    public updateMessage = this.apiWrapperService.createMutationHandler<MessageResource, typeof useUpdateMessageMutation>(useUpdateMessageMutation, {
        toastOnSuccess: 'Successfully Saved Changes to Message',
        toastOnFailure: 'Error Saving Changes to Message'
    });

    public forwardMessage = this.apiWrapperService.createMutationHandler<void, typeof useForwardMutation>(useForwardMutation);

    public deleteAttachmentById = this.apiWrapperService.createMutationHandler<void, typeof useDeleteAttachmentByIdMutation>(
        useDeleteAttachmentByIdMutation,
        {
            toastOnSuccess: 'Successfully Deleted Attachment',
            toastOnFailure: 'Error Deleting Attachment'
        }
    );

    public updateAttachment = this.apiWrapperService.createMutationHandler<void, typeof useUpdateAttachmentMutation>(useUpdateAttachmentMutation, {
        toastOnSuccess: (args, result) => `Changes to "${args.updatedAttachment.fileName}" Attachment Saved!`,
        toastOnFailure: (args, result) => `Error Saving Changes to "${args.updatedAttachment.fileName}" Attachment`
    });

    public restoreAttachment = this.apiWrapperService.createMutationHandler<void, typeof useRestoreAttachmentMutation>(useRestoreAttachmentMutation, {
        toastOnSuccess: 'Original Attachment Restored',
        toastOnFailure: 'Error Restoring Attachment'
    });

    constructor(private apiWrapperService: APIHandlerService, private store: Store) {}

    public getMessageClassifications$(): Observable<Array<ClassificationResource>> {
        return useGetMessageClassificationsQuery().pipe(
            filter(({ isFetching }) => !isFetching),
            map(({ data }) => data)
        );
    }

    public invalidateMessagesCacheByTagName(tagName: string): void {
        this.store.dispatch(messagesApiUtil.invalidateTags([tagName]));
    }

    public getCanMarkReadyForDownload$(messageId: string): Observable<boolean> {
        const statuses = [
            MessageStatus.Processed,
            MessageStatus.Received,
            MessageStatus.Forwarded,
            MessageStatus.Replied,
            MessageStatus.Suspended,
            MessageStatus.Uploaded,
            MessageStatus.Triaged
        ];

        return useGetPreviewQuery(messageId).pipe(
            filter(({ data }) => !!data),
            map(
                ({ data }) =>
                    statuses.includes(data.status) &&
                    data.processedType !== ProcessedType.AwaitingEMRExport &&
                    data.processedType !== ProcessedType.EMRExported
            )
        );
    }

    public getShowTriage$(messageId: string): Observable<boolean> {
        // TODO: KEW-1144 - isRestorable, isPreviewAvailable, and fileName for all attachments needs to be available without getting the full message.
        return useGetQuery(messageId).pipe(
            filter(({ data }) => !!data),
            map(
                ({ data }) =>
                    data.attachments.length > 0 &&
                    data.attachments.some((x) => !x.isRestorable) &&
                    data.attachments.some((x) => x.isPreviewAvailable) &&
                    this.hasUnstructuredDocuments(data.attachments)
            )
        );
    }

    public getSourceType$(messageId: string): Observable<SourceType> {
        // TODO: KEW-1144 - sourceType should be available on IntakeMessagePreviewResource
        return useGetQuery(messageId).pipe(
            filter(({ data }) => !!data),
            map(({ data }) => data.sourceType)
        );
    }

    public getIsDownloadedToEmr$(messageId: string, pollingInterval: number = 5000): Observable<boolean> {
        return useGetPreviewQuery(messageId, { pollingInterval }).pipe(
            filter(({ data }) => !!data),
            map(({ data }) => data.processedType !== ProcessedType.AwaitingEMRExport && data.status !== MessageStatus.Pending)
        );
    }

    private hasUnstructuredDocuments(attachments: Array<AttachmentResource>): boolean {
        return !attachments.every((a) => {
            const ext = a.fileName.substring(a.fileName.lastIndexOf('.') + 1).toLocaleLowerCase();
            return ext == 'hl7' || ext === 'ccd' || ext === 'cda' || ext === 'xml';
        });
    }
}
