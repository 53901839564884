import { Injectable } from '@angular/core';
import {
    useGetDocumentTypesQuery,
    useRemoveDocumentTypeMutation,
    useUpdateDocumentTypeMutation,
    useCreateDocumentTypeMutation,
    useDocumentTypeExistsQuery
} from './document-types.api';
import { NotificationService } from '@kno2/shared/util/common';
import { DocumentTypeResource } from '../../../models/document-type-resource.model';
import { ProfileApiFacade } from '../profile/profile-api.facade';
import { filter, map, mergeMap } from 'rxjs/operators';
import { defer, from, iif, Observable, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DocumentTypesApiFacade {
    public getDocumentTypes$ = useGetDocumentTypesQuery;
    public documentTypeExists$ = useDocumentTypeExistsQuery;
    constructor(private notificationService: NotificationService, private profileApiFacade: ProfileApiFacade) {}
    public async removeDocumentType(id: string): Promise<void> {
        await this.tryMutation(
            async () => await useRemoveDocumentTypeMutation().dispatch(id).unwrap(),
            'The document type was deleted successfully.'
        );
    }

    public updateDocumentTypeWithCheck$(body: DocumentTypeResource): Observable<any> {
        return this.profileApiFacade.getOrganizationId$().pipe(
            map((organizationId) => ({ ...body, organizationId } as DocumentTypeResource)),
            mergeMap((payload) =>
                this.documentTypeExists$(payload).pipe(
                    filter(({ isFetching }) => !isFetching),
                    map(({ data: result }) => [result, payload] as [boolean, DocumentTypeResource])
                )
            ),
            mergeMap(([doesExist, payload]) =>
                iif(
                    () => doesExist,
                    defer(() => throwError(this.notificationService.error('A document type with that name already exists.'))),
                    defer(() => from(this.updateDocumentType(payload)))
                )
            )
        );
    }

    public async updateDocumentType(body: DocumentTypeResource): Promise<void> {
        await this.tryMutation(
            async () => await useUpdateDocumentTypeMutation().dispatch(body).unwrap(),
            'The document type was saved successfully.'
        );
    }

    public async createDocumentType(body: DocumentTypeResource): Promise<void> {
        await this.tryMutation(
            async () => await useCreateDocumentTypeMutation().dispatch(body).unwrap(),
            'The document type was saved successfully.'
        );
    }

    public async tryMutation(mutation: () => Promise<any>, successMessage: string): Promise<void> {
        try {
            await mutation();
            this.notificationService.success(successMessage);
        } catch (err) {
            const { exceptionMessage, message } = (<any>err).data ?? {};
            this.notificationService.error(exceptionMessage || message);
            throw exceptionMessage || message;
        }
    }
}
