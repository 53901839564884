/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Affiliate 1 = Licensing
 */
export enum ResellerType {
    Affiliate = 'Affiliate',
    Licensing = 'Licensing'
}
