/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = DirectMessage 1 = RecordRequest 2 = Triage 3 = Fax 4 = Carequality 5 = NaviHealth 6 = Ihde
 */
export enum SourceType {
    DirectMessage = 'DirectMessage',
    RecordRequest = 'RecordRequest',
    Triage = 'Triage',
    Fax = 'Fax',
    Carequality = 'Carequality',
    NaviHealth = 'NaviHealth',
    Ihde = 'Ihde'
}
