export enum ReservedShortcutEvent {
    // navigation / opening modals
    GoToIntake = 'GoToIntake',
    GoToTriage = 'GoToTriage',
    GoToRelease = 'GoToRelease',
    GoToSettings = 'GoToSettings',
    // entity operations
    SelectAll = 'SelectAll',
    SaveChanges = 'SaveChanges',
    SaveAllChanges = 'SaveAllChanges',
    UndoChanges = 'UndoChanges'
}
