/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 1 = Organization 2 = Brand 3 = DocumentSource 4 = User
 */
export enum SettingsEntityType {
    Organization = 'Organization',
    Brand = 'Brand',
    DocumentSource = 'DocumentSource',
    User = 'User'
}
