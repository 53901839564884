import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DownloadService {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    public createDownload(file: Blob, fileName: string, extension: string): void {
        const downloadLink = this.document.createElement('a');
        downloadLink.href = this.document.defaultView.URL.createObjectURL(file);
        downloadLink.setAttribute('id', fileName);
        downloadLink.setAttribute('download', `${fileName}.${extension}`);
        this.document.body.appendChild(downloadLink);
    }

    public downloadFile(fileName: string, removeLinkAfterDownload?: boolean): void {
        const downloadLink = this.document.getElementById(fileName);
        downloadLink.click();

        if (removeLinkAfterDownload) this.removeLink(fileName);
    }

    public removeLink(fileName: string): void {
        const downloadLink = this.document.getElementById(fileName);
        downloadLink.remove();
    }
}
