import { createApi } from 'ngrx-rtk-query';
import { configureBaseQuery } from '@kno2/shared/data-access/+store';

export const featuresApiKey = 'featuresCache';
const cacheTags = {
    enabledFeatures: 'enabledFeatures'
};

export const featuresApi = createApi({
    reducerPath: featuresApiKey,
    tagTypes: Object.values(cacheTags),
    baseQuery: configureBaseQuery('/api'),
    endpoints: (build) => ({
        getEnabledFeatures: build.query<Array<string>, string>({
            query: (organizationId) => ({
                url: `/organizations/${organizationId}/features`
            }),
            providesTags: [cacheTags.enabledFeatures]
        })
    })
});

export const { useGetEnabledFeaturesQuery } = featuresApi;
