import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PaginationState, PaginationOptions } from './pagination-slice.config';
import { selectPaginationFeature } from './pagination-slice.selectors';
import { clearAllPagination, clearPaginationByPageName, setPaginationByPageName } from './pagination.slice';

@Injectable({
    providedIn: 'root'
})
export class PaginationSliceFacade {
    public get pagination$(): Observable<PaginationState> {
        return this.store.select(selectPaginationFeature);
    }

    constructor(private store: Store) {}

    public applyCacheToGridPaginationOptions$(paginationOptions$: BehaviorSubject<PaginationOptions>, paginationCacheKey: string): Observable<void> {
        return this.pagination$.pipe(
            map((state) => state[paginationCacheKey]),
            tap((options) => {
                if (options)
                    paginationOptions$.next({
                        pageNumber: options?.pageNumber || 1,
                        pageSize: options?.pageSize || 10
                    });
            }),
            map(() => {})
        );
    }

    public setPaginationByPage(paginationCacheKey: string, options: PaginationOptions): void {
        this.store.dispatch(setPaginationByPageName({ name: paginationCacheKey, options: options }));
    }

    public clearPaginationByPage(paginationCacheKey: string): void {
        this.store.dispatch(clearPaginationByPageName(paginationCacheKey));
    }

    public clearPagination(): void {
        this.store.dispatch(clearAllPagination());
    }
}
