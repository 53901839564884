/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * Public enumeration of kinds of query nodes. A subset of InternalQueryNodeKind  0 = None 1 = Constant 2 = Convert 3 = NonentityRangeVariableReference 4 = BinaryOperator 5 = UnaryOperator 6 = SingleValuePropertyAccess 7 = CollectionPropertyAccess 8 = SingleValueFunctionCall 9 = Any 10 = CollectionNavigationNode 11 = SingleNavigationNode 12 = SingleValueOpenPropertyAccess 13 = SingleEntityCast 14 = All 15 = EntityCollectionCast 16 = EntityRangeVariableReference 17 = SingleEntityFunctionCall 18 = CollectionFunctionCall 19 = EntityCollectionFunctionCall 20 = NamedFunctionParameter
 */
export enum QueryNodeKind {
    None = 'None',
    Constant = 'Constant',
    Convert = 'Convert',
    NonentityRangeVariableReference = 'NonentityRangeVariableReference',
    BinaryOperator = 'BinaryOperator',
    UnaryOperator = 'UnaryOperator',
    SingleValuePropertyAccess = 'SingleValuePropertyAccess',
    CollectionPropertyAccess = 'CollectionPropertyAccess',
    SingleValueFunctionCall = 'SingleValueFunctionCall',
    Any = 'Any',
    CollectionNavigationNode = 'CollectionNavigationNode',
    SingleNavigationNode = 'SingleNavigationNode',
    SingleValueOpenPropertyAccess = 'SingleValueOpenPropertyAccess',
    SingleEntityCast = 'SingleEntityCast',
    All = 'All',
    EntityCollectionCast = 'EntityCollectionCast',
    EntityRangeVariableReference = 'EntityRangeVariableReference',
    SingleEntityFunctionCall = 'SingleEntityFunctionCall',
    CollectionFunctionCall = 'CollectionFunctionCall',
    EntityCollectionFunctionCall = 'EntityCollectionFunctionCall',
    NamedFunctionParameter = 'NamedFunctionParameter'
}
