import { Injectable } from '@angular/core';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { IdToken, RedirectLoginOptions } from '@auth0/auth0-spa-js';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { ID_TOKEN_EXPIRATION_OFFSET } from '../auth.constants';
import { AuthSliceFacade } from '@kno2/shared/data-access/+store';
import { AuthServiceLike } from '@kno2/shared/util/common';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements AuthServiceLike {
    private idToken?: IdToken;

    constructor(private auth0Service: Auth0Service, private authSliceFacade: AuthSliceFacade) {}

    public login(options?: RedirectLoginOptions): Observable<void> {
        return this.auth0Service.loginWithRedirect(options || {});
    }

    public logout(): void {
        this.auth0Service.logout();
    }

    public scheduleRenewToken(): Observable<void> {
        const renewIntervalStream$ = new BehaviorSubject(this.calculateTokenInterval());
        return renewIntervalStream$.pipe(
            mergeMap((intervalTime) =>
                interval(intervalTime).pipe(
                    mergeMap(() => this.renewToken(true)),
                    map(() => {
                        renewIntervalStream$.next(this.calculateTokenInterval());
                    }),
                    take(1)
                )
            )
        );
    }

    public renewToken(forceNewToken: boolean = false): Observable<string> {
        return this.auth0Service
            .getAccessTokenSilently({
                cacheMode: forceNewToken ? 'off' : 'on'
            })
            .pipe(
                tap((token) => {
                    this.authSliceFacade.setAuthToken(token);
                }),
                mergeMap((token) => this.setIdToken().pipe(map(() => token)))
            );
    }

    private setIdToken(): Observable<IdToken> {
        return this.auth0Service.idTokenClaims$.pipe(
            filter((token) => token !== null),
            tap((idToken) => {
                if (idToken) {
                    this.idToken = idToken;
                }
            }),
            map((token) => token as IdToken)
        );
    }

    private calculateTokenInterval(): number {
        const currentTime = Date.now() + ID_TOKEN_EXPIRATION_OFFSET;
        const expiresAt = this.idToken?.exp
            ? this.idToken.exp * 1000 // idToken.exp is in seconds. Convert from sec to ms for expiresAt calculation
            : Date.now();

        return Math.abs(expiresAt - currentTime);
    }
}
