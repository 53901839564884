import { routingSlice } from './slices/routing/routing.slice';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreRtkQueryModule } from 'ngrx-rtk-query';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { authSliceKey } from './slices/auth/auth-slice.config';
import { paginationSliceKey } from './slices/pagination/pagination-slice.config';
import { authSlice } from './slices/auth/auth.slice';
import { paginationSlice } from './slices/pagination/pagination.slice';
import { CustomSerializerUtil } from './utils/custom-serializer.util';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forRoot({
            router: routingSlice
        }),
        StoreModule.forFeature(authSliceKey, authSlice.reducer),
        StoreModule.forFeature(paginationSliceKey, paginationSlice.reducer),
        StoreRouterConnectingModule.forRoot({
            serializer: CustomSerializerUtil
        }),
        StoreRtkQueryModule.forRoot({ setupListeners: true }),
        StoreDevtoolsModule.instrument()
    ]
})
export class SharedDataAccessStoreModule {}
