import { createApi } from 'ngrx-rtk-query';
import { configureBaseQuery } from '@kno2/shared/data-access/+store';
import { InteroperabilitySettingsResource } from '../../../models/interoperability-settings-resource.model';
import { SettingsEntityType } from '../../../models/settings-entity-type.model';
import {
    AccountSettingsResource,
    OrganizationSettingsResource,
    RequireTwoFactorResource,
    RequireWhitelistedIpResource,
    UpsertEntitySettingsRequestResource
} from '../../../models';

export const settingsApiKey = 'settings';
const cacheTags = {
    settings: 'settings',
    interoperability: 'interoperability',
    entity: 'entity'
};

export const settingsApi = createApi({
    reducerPath: settingsApiKey,
    tagTypes: Object.values(cacheTags),
    baseQuery: configureBaseQuery('/api/settings'),
    endpoints: (build) => ({
        getSettings: build.query<OrganizationSettingsResource, void>({
            query: () => ({
                url: '/'
            }),
            providesTags: [cacheTags.settings]
        }),
        getInteroperabilitySettings: build.query<InteroperabilitySettingsResource, void>({
            query: () => ({
                url: 'interoperability'
            }),
            providesTags: [cacheTags.interoperability]
        }),
        getEntitySettings: build.query<Record<any, any>, { entityType: SettingsEntityType; entityId: string }>({
            query: ({ entityType, entityId }) => ({
                url: `${entityType}/${entityId}`
            }),
            providesTags: (params, err, { entityType }) => {
                return [
                    {
                        type: cacheTags.entity,
                        entity: entityType
                    }
                ];
            }
        }),
        updateEntitySettings: build.mutation<any, UpsertEntitySettingsRequestResource>({
            query: (payload) => ({
                method: 'POST',
                body: payload
            }),
            invalidatesTags: (params, err, { entityType }) => {
                return [
                    {
                        type: cacheTags.entity,
                        entity: entityType as SettingsEntityType
                    }
                ];
            }
        }),
        updateSessionTimeout: build.mutation<AccountSettingsResource, number>({
            query: (minutes: number) => ({
                url: 'sessiontimeout',
                method: 'PUT',
                body: { sessionTimeoutMinutes: minutes }
            }),
            invalidatesTags: [cacheTags.settings]
        }),
        updateRequireWhitelistedIp: build.mutation<RequireWhitelistedIpResource, boolean>({
            query: (requireIp: boolean) => ({
                url: 'requirewhitelistedip',
                method: 'PUT',
                body: { requireWhitelistedIp: requireIp }
            }),
            invalidatesTags: [cacheTags.settings]
        }),
        updateIgnoredExtensions: build.mutation<void, Array<string>>({
            query: (ignoredExtensions: Array<string>) => ({
                url: 'ignoredattachmentextensions',
                method: 'PUT',
                body: { ignoredExtensions: ignoredExtensions }
            }),
            invalidatesTags: [cacheTags.settings]
        }),
        updateRequireTwoFactor: build.mutation<RequireTwoFactorResource, boolean>({
            query: (require2FA: boolean) => ({
                url: 'requiretwofactorauth',
                method: 'PUT',
                body: { requireTwoFactorAuth: require2FA }
            }),
            invalidatesTags: [cacheTags.settings]
        }),
        updateAllowLegacyCompatibility: build.mutation<void, boolean>({
            query: (allowLegacy: boolean) => ({
                url: 'allowcompatibility',
                method: 'PUT',
                body: { allowOrganizationLegacyLoginCompatibility: allowLegacy }
            }),
            invalidatesTags: [cacheTags.settings]
        }),
        updateInteroperabilitySettings: build.mutation<InteroperabilitySettingsResource, InteroperabilitySettingsResource>({
            query: (interopSettings: InteroperabilitySettingsResource) => ({
                url: 'interoperability',
                method: 'PUT',
                body: interopSettings
            }),
            invalidatesTags: [cacheTags.interoperability]
        })
    })
});

export const {
    useGetInteroperabilitySettingsQuery,
    useGetEntitySettingsQuery,
    useUpdateEntitySettingsMutation,
    useGetSettingsQuery,
    useUpdateSessionTimeoutMutation,
    useUpdateRequireWhitelistedIpMutation,
    useUpdateIgnoredExtensionsMutation,
    useUpdateRequireTwoFactorMutation,
    useUpdateAllowLegacyCompatibilityMutation,
    useUpdateInteroperabilitySettingsMutation
} = settingsApi;
