/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Phone 1 = Fax 2 = Email 3 = Pager 4 = Url 5 = Sms 6 = Other
 */
export enum ContactPointSystem {
    Phone = 'Phone',
    Fax = 'Fax',
    Email = 'Email',
    Pager = 'Pager',
    Url = 'Url',
    Sms = 'Sms',
    Other = 'Other'
}
