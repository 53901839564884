/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Signer 1 = CarbonCopy 2 = InPersonSigner
 */
export enum SignerType {
    Signer = 'Signer',
    CarbonCopy = 'CarbonCopy',
    InPersonSigner = 'InPersonSigner'
}
