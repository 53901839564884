import { configureBaseQuery } from '@kno2/shared/data-access/+store';
import { createApi } from 'ngrx-rtk-query';
import { ChangePasswordModel } from '../../../models/change-password-model.model';

export const accountApiKey = 'account';
export const accountApi = createApi({
    reducerPath: accountApiKey,
    baseQuery: configureBaseQuery('/api/account'),
    endpoints: (build) => ({
        changeOrganization: build.mutation<void, string>({
            query: (orgId: string) => ({
                url: `change`,
                method: 'POST',
                body: { orgId }
            })
        }),
        updatePassword: build.mutation<void, ChangePasswordModel>({
            query: (body) => ({
                url: '/password',
                method: 'PUT',
                body
            })
        })
    })
});

export const { useChangeOrganizationMutation, useUpdatePasswordMutation } = accountApi;
