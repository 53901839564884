/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * Defines EDM schema element types.  0 = None 1 = TypeDefinition 2 = Function 3 = ValueTerm 4 = EntityContainer
 */
export enum EdmSchemaElementKind {
    None = 'None',
    TypeDefinition = 'TypeDefinition',
    Function = 'Function',
    ValueTerm = 'ValueTerm',
    EntityContainer = 'EntityContainer'
}
