/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Current 1 = Former 2 = Other
 */
export enum AddressTypes {
    Current = 'Current',
    Former = 'Former',
    Other = 'Other'
}
