/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Email 1 = SMS
 */
export enum DocuSignNotificationMethod {
    Email = 'Email',
    SMS = 'SMS'
}
