import { configureBaseQuery } from '@kno2/shared/data-access/+store';
import { createApi } from 'ngrx-rtk-query';
import { OrganizationUserResource } from '../../../models/organization-user-resource.model';
import { UserDetailsResource } from '../../../models/user-details-resource.model';
import { RuleResource } from '../../../models/rule-resource.model';
import { RuleType } from '../../../models/rule-type.model';
import { DocumentSource } from '../../../models/document-source.model';
import { DocumentSourceResource } from '../../../models/document-source-resource.model';
import { DocumentSourceUserResource } from '../../../models/document-source-user-resource.model';

export const profileApiKey = 'userCache';
const cacheTags = {
    profileData: 'profileData',
    currentUser: 'currentUser',
    rules: 'rules'
};

export const profileApi = createApi({
    reducerPath: profileApiKey,
    baseQuery: configureBaseQuery('/api/users/'),
    tagTypes: Object.values(cacheTags),
    endpoints: (build) => ({
        getAllUsers: build.query<Array<DocumentSourceUserResource>, { includeNetwork: boolean }>({
            query: (params) => ({
                url: '',
                params
            })
        }),
        getCurrentUser: build.query<OrganizationUserResource, void>({
            query: () => ({
                url: 'current'
            }),
            providesTags: [cacheTags.currentUser]
        }),
        getProfileData: build.query<UserDetailsResource & { ipAddress?: string }, void>({
            query: () => ({
                url: 'profileData'
            }),
            providesTags: [cacheTags.profileData]
        }),
        updateProfileData: build.mutation<
            UserDetailsResource,
            {
                userId: string;
                profile: UserDetailsResource;
            }
        >({
            query: ({ userId, profile }) => ({
                url: `${encodeURIComponent(userId)}`,
                method: 'PUT',
                body: profile
            }),
            invalidatesTags: [cacheTags.currentUser, cacheTags.profileData]
        }),
        getAssignedRules: build.query<Array<RuleResource>, { userId: string; ruleType?: RuleType }>({
            query: ({ userId, ruleType }) => ({
                url: `${userId}/rules`,
                params: {
                    ruleType
                }
            }),
            providesTags: [cacheTags.rules]
        }),
        getDocumentSources: build.query<Array<DocumentSource>, { userId: string }>({
            query: ({ userId }) => ({
                url: `${userId}/documentsources`
            })
        }),
        getReleaseTypeDocumentSources: build.query<Array<DocumentSourceResource>, { userId: string }>({
            query: ({ userId }) => ({
                url: `${userId}/releasetypedocumentsources`
            })
        })
    })
});

export const {
    useGetAllUsersQuery,
    useGetCurrentUserQuery,
    useGetProfileDataQuery,
    useUpdateProfileDataMutation,
    useGetAssignedRulesQuery,
    useGetDocumentSourcesQuery,
    useGetReleaseTypeDocumentSourcesQuery
} = profileApi;
