/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 1 = RequireWhitelistedIp 2 = DocumentSourceFaxCallerId 3 = BrandShowDownloadWithClient 4 = BrandShowDownloadWithDesktop 5 = ManagingExternalServiceAccountClientId 6 = AllowLegacyLogin 7 = AllowOrganizationLegacyLoginCompatibility 8 = AllowUserLegacyLoginCompatibility 9 = AutomatedCarePlanActions
 */
export enum SettingsEnum {
    RequireWhitelistedIp = 'RequireWhitelistedIp',
    DocumentSourceFaxCallerId = 'DocumentSourceFaxCallerId',
    BrandShowDownloadWithClient = 'BrandShowDownloadWithClient',
    BrandShowDownloadWithDesktop = 'BrandShowDownloadWithDesktop',
    ManagingExternalServiceAccountClientId = 'ManagingExternalServiceAccountClientId',
    AllowLegacyLogin = 'AllowLegacyLogin',
    AllowOrganizationLegacyLoginCompatibility = 'AllowOrganizationLegacyLoginCompatibility',
    AllowUserLegacyLoginCompatibility = 'AllowUserLegacyLoginCompatibility',
    AutomatedCarePlanActions = 'AutomatedCarePlanActions'
}
