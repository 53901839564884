/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Splitting 1 = Split
 */
export enum AttachmentSplitStatus {
    Splitting = 'Splitting',
    Split = 'Split'
}
