import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { AttachmentResource } from '../models';

@Injectable({
    providedIn: 'root'
})
export class MessagesAttachmentsService {
    public getAttachment = (messageId: string, attachmentId: string) => firstValueFrom(this.getAttachment$(messageId, attachmentId));

    constructor(private httpClient: HttpClient) {}

    private getAttachment$(messageId: string, attachmentId: string): Observable<AttachmentResource> {
        return this.httpClient.get<AttachmentResource>(`/api/messages/${messageId}/attachments/${attachmentId}`);
    }
}
