import { HttpMethod } from '@auth0/auth0-angular';
import { configureBaseQuery } from '@kno2/shared/data-access/+store';
import { createApi } from 'ngrx-rtk-query';
import { DocumentSourceResource } from '../../../models';
import { DocumentSourcesPageResource } from '../../../models/document-sources-page-resource.model';
import { DocumentSourcesQueryParams } from './document-sources-query-params.model';

export const documentSourcesApiKey = 'documentSources';
export const documentSourcesCacheTags = {
    documentSources: 'documentSources'
};

export const documentSourcesApi = createApi({
    reducerPath: documentSourcesApiKey,
    baseQuery: configureBaseQuery('/api/documentsources'),
    tagTypes: Object.values(documentSourcesCacheTags),
    endpoints: (build) => ({
        getById: build.query<DocumentSourceResource, { id: string }>({
            query: ({ id }) => ({
                url: `/${id}`
            }),
            providesTags: (params, err, args) => [{ type: documentSourcesCacheTags.documentSources, id: args.id }]
        }),
        getAddressTypes: build.query<Array<{ id: string; name: string }>, void>({
            query: () => ({
                url: '/addressTypes'
            })
        }),
        getDocumentSources: build.query<DocumentSourcesPageResource, DocumentSourcesQueryParams>({
            query: (params: DocumentSourcesQueryParams = {}) => ({
                url: '/',
                params: { ...Object.fromEntries(Object.entries(params).filter(([, value]) => !!value)) }
            }),
            providesTags: [documentSourcesCacheTags.documentSources]
        }),
        removeDocumentSource: build.mutation<void, string>({
            query: (id: string) => ({
                url: `/${id}`,
                method: HttpMethod.Delete
            }),
            invalidatesTags: [documentSourcesCacheTags.documentSources]
        }),
        updateDocumentSource: build.mutation<void, DocumentSourceResource>({
            query: (body: DocumentSourceResource) => ({
                url: `/${body.id}`,
                method: HttpMethod.Put,
                body
            }),
            invalidatesTags: [documentSourcesCacheTags.documentSources]
        }),
        createDocumentSource: build.mutation<void, DocumentSourceResource>({
            query: (body: DocumentSourceResource) => ({
                url: `/`,
                method: HttpMethod.Post,
                body
            }),
            invalidatesTags: [documentSourcesCacheTags.documentSources]
        }),
        documentExists: build.query<boolean, { id: string; address: string }>({
            query: ({ id, ...params }) => ({
                url: `${id}/exists`,
                params
            })
        })
    })
});

export const {
    useGetByIdQuery,
    useGetDocumentSourcesQuery,
    useGetAddressTypesQuery,
    useRemoveDocumentSourceMutation,
    useUpdateDocumentSourceMutation,
    useCreateDocumentSourceMutation,
    useDocumentExistsQuery
} = documentSourcesApi;
