/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = None 1 = Received 2 = Pending 3 = Signed 4 = Suspended 5 = Deleted 6 = Removed 7 = Forwarded 8 = Replied 9 = Processed 10 = Uploaded 11 = Triaged 12 = Failed
 */
export enum MessageStatus {
    None = 'None',
    Received = 'Received',
    Pending = 'Pending',
    Signed = 'Signed',
    Suspended = 'Suspended',
    Deleted = 'Deleted',
    Removed = 'Removed',
    Forwarded = 'Forwarded',
    Replied = 'Replied',
    Processed = 'Processed',
    Uploaded = 'Uploaded',
    Triaged = 'Triaged',
    Failed = 'Failed'
}
