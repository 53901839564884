import { UpsertEntitySettingsRequestResource } from './../../../models/upsert-entity-settings-request-resource.model';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AccountSettingsResource, AttachmentSendType, RequireTwoFactorResource, RequireWhitelistedIpResource } from '../../../models';
import { InteroperabilitySettingsResource } from '../../../models/interoperability-settings-resource.model';
import { SettingsEntityType } from '../../../models/settings-entity-type.model';
import { ProfileApiFacade } from '../profile/profile-api.facade';
import {
    useGetEntitySettingsQuery,
    useGetInteroperabilitySettingsQuery,
    useGetSettingsQuery,
    useUpdateEntitySettingsMutation,
    useUpdateAllowLegacyCompatibilityMutation,
    useUpdateIgnoredExtensionsMutation,
    useUpdateInteroperabilitySettingsMutation,
    useUpdateRequireTwoFactorMutation,
    useUpdateRequireWhitelistedIpMutation,
    useUpdateSessionTimeoutMutation
} from './settings.api';
import { NotificationService } from '@kno2/shared/util/common';

@Injectable({
    providedIn: 'root'
})
export class SettingsApiFacade {
    public getSettings$ = useGetSettingsQuery;
    public getInteroperabilitySettings$ = useGetInteroperabilitySettingsQuery;
    public getEntitySettings$ = useGetEntitySettingsQuery;

    public areVisitDetailsReadOnly$: Observable<boolean> = useGetInteroperabilitySettingsQuery().pipe(
        filter((query: any) => !!query.data),
        map((query: any) => query.data),
        map((settings: InteroperabilitySettingsResource) => {
            const { exportMode, exportDirectoryType, exportOption, exportVisitDirectory } = settings ?? {};
            const isSensitive = exportMode === 'print' || exportDirectoryType === 'single_directory' || exportOption === 'xds-sd_cda';
            const isSaveAsSensitive =
                exportMode === 'save_as' && exportDirectoryType === 'patient_folder' && exportOption === 'standard' && !exportVisitDirectory;

            return isSensitive || isSaveAsSensitive;
        })
    );

    public canForwardToEmr$: Observable<boolean> = useGetInteroperabilitySettingsQuery().pipe(
        map(({ data }) => data?.exportMode === 'emr' && !!data?.directAddress)
    );

    public canDownloadToEmr$: Observable<boolean> = useGetInteroperabilitySettingsQuery().pipe(map(({ data }) => data?.downloadMessage));

    public exportOptions$ = combineLatest([useGetInteroperabilitySettingsQuery(), this.getBrandSettings$()]).pipe(
        filter(([interopSettings]) => !!interopSettings.data),
        map(([interopSettings, brandSettings]) => ({ ...interopSettings.data, ...brandSettings })),
        map((result: any) => {
            const showExportDropdown = result.exportMode !== 'emr' && (result.brandShowDownloadWithClient || result.brandShowDownloadWithDesktop);
            return {
                exportMode: result.exportMode,
                enablePrintFromBrowser: result.enablePrintFromBrowser && showExportDropdown,
                enableDownloadFromBrowser: result.enableDownloadFromBrowser && showExportDropdown,
                brandShowDownloadWithClient: result.brandShowDownloadWithClient,
                brandShowDownloadWithDesktop: result.brandShowDownloadWithDesktop,
                fromDirectAddress: result.fromDirectAddress,
                forwardOption: <AttachmentSendType>result.forwardOption
            };
        })
    );

    public directAddresses$: Observable<Array<string>> = useGetInteroperabilitySettingsQuery().pipe(
        map(({ data }) => data?.directAddress.split(';'))
    );

    constructor(private profileApi: ProfileApiFacade, private notificationService: NotificationService) {}

    public getBrandSettings$(): Observable<Record<string, boolean>> {
        return this.profileApi.getProfile().pipe(
            switchMap(({ data }) => useGetEntitySettingsQuery({ entityType: SettingsEntityType.Brand, entityId: (<any>data)?.brandId })),
            filter(({ isFetching }) => !isFetching),
            map(({ data }) => data?.data)
        );
    }

    public getInteroperabilitySetting$(setting: keyof InteroperabilitySettingsResource): Observable<boolean | string> {
        return useGetInteroperabilitySettingsQuery().pipe(
            filter(({ data }) => !!data),
            map(({ data }) => {
                const settings = data;
                return settings[setting];
            })
        );
    }

    public getOrganizationInteroperabilitySettings$(): Observable<InteroperabilitySettingsResource> {
        return this.getInteroperabilitySettings$().pipe(
            filter((response) => !response.isFetching && !!response?.data),
            map((response) => response.data)
        );
    }

    public updateSessionTimeout(minutes: number): Promise<AccountSettingsResource> {
        return useUpdateSessionTimeoutMutation().dispatch(minutes).unwrap();
    }

    public updateRequireWhitelistedIp(requireIp: boolean): Promise<RequireWhitelistedIpResource> {
        return useUpdateRequireWhitelistedIpMutation().dispatch(requireIp).unwrap();
    }

    public async updateIgnoredExtensions(ignoredExtensions: Array<string>): Promise<void> {
        return useUpdateIgnoredExtensionsMutation().dispatch(ignoredExtensions).unwrap();
    }

    public updateRequire2FA(require2FA: boolean): Promise<RequireTwoFactorResource> {
        return useUpdateRequireTwoFactorMutation().dispatch(require2FA).unwrap();
    }

    public async updateAllowLegacyLogin(allowLegacy: boolean): Promise<void> {
        await useUpdateAllowLegacyCompatibilityMutation().dispatch(allowLegacy).unwrap();
    }

    public updateInteropSettings(interopSettings: InteroperabilitySettingsResource): Promise<InteroperabilitySettingsResource> {
        return useUpdateInteroperabilitySettingsMutation().dispatch(interopSettings).unwrap();
    }

    public tryUpdateInteropSettings(interopSettings: InteroperabilitySettingsResource) {
        try {
            this.updateInteropSettings(interopSettings);
            this.notificationService.success('The download configuration was saved.');
        } catch (err) {
            this.notificationService.error(this.parseOutError(err));
        }
    }

    public async updateEntitySettings(settings: UpsertEntitySettingsRequestResource) {
        try {
            await useUpdateEntitySettingsMutation().dispatch(settings).unwrap();
            return true;
        } catch (err) {
            return false;
        }
    }

    private parseOutError(error: any): string {
        const modelStateErrors: Array<Array<string>> = JSON.parse(JSON.stringify(Object.values(error.data?.modelState || {})));

        if (error.data?.modelState) {
            return <string>Object.values(error.data?.modelState)[0];
        }

        if (error.message) return error.message;

        if (error.data?.message) return error.data.message;

        if (modelStateErrors.length) return (modelStateErrors[0] as Array<string>).pop() as string;

        return null;
    }
}
