import { OrderQuery } from './../../../models/order-query.model';
import { IntegrationOrder } from './../../../models/integration-order.model';
import { StateResource } from './../../../models/state-resource.model';
import { ExternalLoginResource } from './../../../models/external-login-resource.model';
import { createApi } from 'ngrx-rtk-query';
import { configureBaseQuery } from '@kno2/shared/data-access/+store';
import { IntegrationVisit } from '../../../models/integration-visit.model';
import { IntegrationPatient } from '../../../models/integration-patient.model';
import {
    AddIntegrationPatient,
    BaseKno2Response,
    FacilityResource,
    IntegrationCapabilitiesResource,
    IntegrationDocumentType,
    IntegrationResource,
    IntegrationReviewer,
    IntegrationReviewerGroup,
    IntegrationTypeResource,
    ReviewerQuery
} from '../../../models';
import { IntegrationPatientKeyResource } from './integration-patient-keys.model';

export const integrationsApiKey = 'integrations';
const cacheTags = {
    integrations: 'integrations',
    integrationTypes: 'integrationTypes',
    patientKeys: 'patientKeys',
    capabilities: 'capabilities',
    patients: 'patients',
    visits: 'visits',
    documentTypes: 'documentTypes',
    faclities: 'facilities'
};

export const integrationsApi = createApi({
    reducerPath: integrationsApiKey,
    tagTypes: Object.values(cacheTags),
    baseQuery: configureBaseQuery('/api/integrations'),
    endpoints: (build) => ({
        getAllConnctedTypes: build.query<Array<IntegrationTypeResource>, void>({
            query: () => ({
                url: '/connected/types'
            }),
            providesTags: [cacheTags.integrationTypes]
        }),
        getAll: build.query<Array<IntegrationResource>, void>({
            query: () => ({}),
            providesTags: (params, err, arg) => {
                return [cacheTags.integrations, ...params.map(({ id }) => ({ id, type: cacheTags.integrations }))];
            },
            // Keep unused data for 8 hours before marking the data as stale and requiring a refetch
            keepUnusedDataFor: 28800
        }),
        update: build.mutation<IntegrationResource, { payload: IntegrationResource; config?: { invalidateCache: boolean } }>({
            query: ({ payload, config }) => ({
                url: '/',
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: (params, err, arg) => {
                return arg?.config?.invalidateCache
                    ? [
                          cacheTags.integrations,
                          cacheTags.integrationTypes,
                          cacheTags.patientKeys,
                          cacheTags.capabilities,
                          cacheTags.patients,
                          cacheTags.visits,
                          cacheTags.documentTypes,
                          cacheTags.faclities,
                          { id: params.id, type: cacheTags.integrations }
                      ]
                    : [];
            }
        }),
        delete: build.mutation<void, { integrationId: string }>({
            query: ({ integrationId }) => ({
                url: `/${integrationId}`,
                method: 'DELETE'
            }),
            invalidatesTags: (params, err, arg) => {
                return [{ id: arg.integrationId, type: cacheTags.integrations }];
            }
        }),
        getExternalLogin: build.query<ExternalLoginResource & { mustLogin: boolean; state: string }, { integrationType: string }>({
            query: (params) => ({
                url: `/externallogin`,
                params
            })
        }),
        checkExternalLoginState: build.query<{ userId: string; metaData: Record<string, string> }, StateResource>({
            query: (body) => ({
                url: '/checkstate',
                method: 'POST',
                body
            })
        }),
        checkConnectivity: build.query<BaseKno2Response, { integrationId: string }>({
            query: ({ integrationId }) => ({
                url: `${integrationId}/connectivity`
            })
        }),
        checkIntegrationTypeConnectivity: build.query<BaseKno2Response, IntegrationResource>({
            query: (body) => ({
                url: `/connectivity`,
                method: 'POST',
                body
            })
        }),
        getCapabilities: build.query<IntegrationCapabilitiesResource, void>({
            query: () => ({
                url: 'capabilities'
            }),
            providesTags: [cacheTags.capabilities],
            // Keep unused data for 8 hours before marking the data as stale and requiring a refetch
            keepUnusedDataFor: 28800
        }),
        searchPatients: build.query<Array<IntegrationPatient>, any>({
            query: (query) => ({
                url: 'patients',
                method: 'POST',
                body: query
            }),
            providesTags: [cacheTags.patients]
        }),
        addIntegrationPatient: build.mutation<IntegrationPatient, AddIntegrationPatient>({
            query: (patientAdd: AddIntegrationPatient) => ({
                url: `/patients/add`,
                method: 'POST',
                body: patientAdd
            }),
            invalidatesTags: [cacheTags.patients]
        }),
        getOrders: build.query<Array<IntegrationOrder>, OrderQuery>({
            query: (query) => ({
                url: 'orders',
                method: 'POST',
                body: query
            })
        }),
        getPatientKeys: build.query<Array<IntegrationPatientKeyResource>, { type: string }>({
            query: (params) => ({ url: 'patientkeys', params }),
            transformResponse: (res: any) => res.data,
            providesTags: [cacheTags.patientKeys]
        }),
        getIntegrationVisits: build.query<Array<IntegrationVisit>, string>({
            query: (patientId: string) => ({
                url: 'visits',
                method: 'POST',
                body: {
                    patientId
                }
            }),
            providesTags: [cacheTags.visits]
        }),
        getIntegrationDocumentTypes: build.query<Array<IntegrationDocumentType>, void>({
            query: () => ({
                url: '/documenttypes',
                providesTags: [cacheTags.documentTypes]
            })
        }),
        getIntegrationTypes: build.query<Array<IntegrationTypeResource>, void>({
            query: () => ({
                url: 'types'
            }),
            providesTags: [cacheTags.integrationTypes]
        }),
        getIntegrationFacilities: build.query<Array<FacilityResource>, void>({
            query: () => ({
                url: `/facilities`
            }),
            providesTags: [cacheTags.faclities]
        }),
        getReviewers: build.query<Array<IntegrationReviewer>, ReviewerQuery>({
            query: (query) => ({
                url: 'reviewers',
                method: 'POST',
                body: query
            })
        }),
        getReviewerGroups: build.query<Array<IntegrationReviewerGroup>, void>({
            query: () => ({
                url: 'reviewergroups'
            })
        })
    })
});

export const {
    useGetAllQuery,
    useUpdateMutation,
    useDeleteMutation,
    useGetCapabilitiesQuery,
    useSearchPatientsQuery,
    useAddIntegrationPatientMutation,
    useGetExternalLoginQuery,
    useCheckExternalLoginStateQuery,
    useCheckConnectivityQuery,
    useCheckIntegrationTypeConnectivityQuery,
    useGetOrdersQuery,
    useGetPatientKeysQuery,
    useGetIntegrationVisitsQuery,
    useGetIntegrationDocumentTypesQuery,
    useGetIntegrationTypesQuery,
    useGetAllConnctedTypesQuery,
    useGetIntegrationFacilitiesQuery,
    useGetReviewersQuery,
    useGetReviewerGroupsQuery
} = integrationsApi;
