import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { paginationSliceKey, PaginationState, PaginationOptions } from './pagination-slice.config';

export const paginationSlice = createSlice({
    name: paginationSliceKey,
    initialState: {} as PaginationState,
    reducers: {
        setPaginationByPageName: (state: PaginationState, action: PayloadAction<{ name: string; options: PaginationOptions }>) => {
            const { name, options } = action.payload;
            state[name] = options;
        },
        clearPaginationByPageName: (state: PaginationState, action: PayloadAction<string>) => {
            state[action.payload] = null;
        },
        clearAllPagination: (state: PaginationState) => {
            return {};
        }
    }
});

export const { setPaginationByPageName, clearPaginationByPageName, clearAllPagination } = paginationSlice.actions;
