import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EntityState } from '@reduxjs/toolkit';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MessageResource } from '../../../models/message-resource.model';
import { CurrentMessageState } from './current-message-slice.config';
import {
    selectCurrentMessageId,
    selectTransformedAttachmentPreviews,
    selectCurrentPreviewId,
    selectAttachmentsEditor,
    selectCurrentAttachmentId,
    selectDraftMessageChanges,
    selectMessageMetaData,
    selectMarkedPreviewIds,
    selectAttachmentPreviews,
    selectPreviewsByAttachment
} from './current-message-slice.selectors';
import {
    addDraftMessageToAddress,
    addManyPreviewImages,
    addPreviewIdInAttachmentsEditor,
    clearDraftMessageConversion,
    clearDraftMessageFromAddress,
    clearDraftMessageReleaseType,
    clearDraftMessageToAddresses,
    clearMessageMeta,
    clearPreviewIdsInAttachmentsEditor,
    clearSelectedAttachment,
    clearSelectedAttachmentPreview,
    clearSelectedMessage,
    markPreviewImageForDeletion,
    removePreviewIdInAttachmentsEditor,
    removePreviewImage,
    resetPreviewImageTransformation,
    rotatePreviewImage,
    setDraftMessageAdditionalComments,
    setDraftMessageBody,
    setDraftMessageConversion,
    setDraftMessageDisclosureReason,
    setDraftMessageFromAddress,
    setDraftMessageReleaseType,
    setDraftMessageConversation,
    setMessageMetaMessageType,
    setMessageMeta,
    setSelectedAttachment,
    setSelectedAttachmentPreview,
    setSelectedMessage,
    toggleAttachmentsEditorMode,
    toggleAttachmentsMultiSelectionMode,
    setPreviewImageSource,
    setMessageMetaSourceMessageId,
    setDraftMessageToAddresses,
    resetPreviewsByAttachmentId,
    clearPreviewImages,
    resetMessageState,
    setIncludePatientDetailsInBody
} from './current-message.slice';
import { AttachmentPreview } from './models/current-message-attachment-preview.model';
import { MessageDraftChanges } from './models/current-message-draft-changes.model';
import { AttachmentSendType, ConversationStatus, ConversationType } from '../../../models';

@Injectable({
    providedIn: 'root'
})
export class CurrentMessageSliceFacade {
    /**
     * This is the ID of the original mesasge that the draft is currently based upon. For example,
     * reply and forward would have a source message ID
     */
    public sourceMessageId$: Observable<string | null> = this.store.select(selectMessageMetaData).pipe(map(({ sourceMessageId }) => sourceMessageId));
    public selectedMessageId$: Observable<string> = this.store.select(selectCurrentMessageId);

    public selectedPreviewId$: Observable<string> = this.store.select(selectCurrentPreviewId);

    public selectedAttachmentId$: Observable<string> = this.store.select(selectCurrentAttachmentId);

    public selectMessageMetaData$: Observable<CurrentMessageState['messageMeta']> = this.store.select(selectMessageMetaData);

    public selectMessageType$: Observable<string | null> = this.store.select(selectMessageMetaData).pipe(map(({ messageType }) => messageType));

    public selectDraftMessageChanges$: Observable<MessageDraftChanges> = this.store.select(selectDraftMessageChanges);

    public selectAttachmentsEditor$: Observable<CurrentMessageState['attachmentsEditor']> = this.store.select(selectAttachmentsEditor);

    // TODO: refactor and deprecate this selector - move preview ID to entity
    public transformedAttachmentPreviews$: Observable<EntityState<AttachmentPreview>> = this.store.select(selectTransformedAttachmentPreviews);

    public previwsByAttachment$: Observable<Record<string, Array<AttachmentPreview>>> = this.store.select(selectPreviewsByAttachment);

    public attachmentPreviews$: Observable<Array<AttachmentPreview>> = this.store.select(selectAttachmentPreviews);

    public markedAttachmentPreviews$: Observable<Array<string>> = this.store.select(selectMarkedPreviewIds);

    constructor(private store: Store) {}

    public hydrateStateFromMessage(message: MessageResource, messageType = 'default'): void {
        const toAddresses = message.toAddress.split(';');
        const fromAddress = message.fromAddress;

        this.setSelectedMessage({ messageId: message.id });
        this.setDraftMessageBody(message.body);
        this.setDraftMessageAdditionalComments(message.body ? message.body.split('Comments:').splice(1).join('') : '');
        this.setDraftMessageDisclosureReason(message.reasonForDisclosure);
        this.setDraftMessageConversion(message?.attachmentSendType || AttachmentSendType.Original);

        if (messageType === 'forward') {
            this.clearDraftMessageFromAddress();
            this.clearDraftMessageToAddresses();
            this.clearDraftMessageReleaseType();
            return;
        }

        this.setDraftMessageReleaseType(message.releaseTypeId);
        this.setDraftMessageFromAddress(messageType === 'reply' ? toAddresses[0] : fromAddress || null);
        this.setDraftMessageToAddresses((messageType === 'reply' ? [fromAddress] : toAddresses).filter((x) => !!x?.trim().length));
    }

    public resetState(): void {
        this.store.dispatch(resetMessageState());
    }

    public setDraftMessageAdditionalComments(payload: string): void {
        this.store.dispatch(setDraftMessageAdditionalComments(payload));
    }

    public clearDraftMessageAdditionalComments(): void {
        this.store.dispatch(setDraftMessageAdditionalComments(''));
    }

    public setDraftMessageDisclosureReason(payload: string): void {
        this.store.dispatch(setDraftMessageDisclosureReason(payload));
    }

    public clearDraftMessageDisclosureReason(): void {
        this.store.dispatch(setDraftMessageDisclosureReason(''));
    }

    public setDraftMessageBody(payload: string): void {
        this.store.dispatch(setDraftMessageBody(payload));
    }

    public setIncludePatientDetailsInBody(payload: boolean): void {
        this.store.dispatch(setIncludePatientDetailsInBody(payload));
    }

    public clearDraftMessageBody(): void {
        this.store.dispatch(setDraftMessageBody(''));
    }

    public setDraftMessageConversion(payload: ReturnType<typeof setDraftMessageConversion>['payload']): void {
        this.store.dispatch(setDraftMessageConversion(payload));
    }

    public clearDraftMessageConversion(): void {
        this.store.dispatch(clearDraftMessageConversion());
    }

    public setMessageMetaSourceMessageId(payload: string): void {
        this.store.dispatch(setMessageMetaSourceMessageId(payload));
    }

    public setMessageMeta(payload: ReturnType<typeof setMessageMeta>['payload']): void {
        this.store.dispatch(setMessageMeta(payload));
    }

    public setMessageMetaMessageType(payload: ReturnType<typeof setMessageMetaMessageType>['payload']): void {
        this.store.dispatch(setMessageMetaMessageType(payload));
    }

    public clearMessageMeta(): void {
        this.store.dispatch(clearMessageMeta());
    }

    public addDraftMessageToAddress(payload: ReturnType<typeof addDraftMessageToAddress>['payload']): void {
        this.store.dispatch(addDraftMessageToAddress(payload));
    }

    public setDraftMessageToAddresses(payload: Array<string>): void {
        this.store.dispatch(setDraftMessageToAddresses(payload));
    }

    public clearDraftMessageToAddresses(): void {
        this.store.dispatch(clearDraftMessageToAddresses());
    }

    public setDraftMessageFromAddress(payload: ReturnType<typeof setDraftMessageFromAddress>['payload']): void {
        this.store.dispatch(setDraftMessageFromAddress(payload));
    }

    public clearDraftMessageFromAddress(): void {
        this.store.dispatch(clearDraftMessageFromAddress());
    }

    public setDraftMessageReleaseType(payload: ReturnType<typeof setDraftMessageReleaseType>['payload']): void {
        this.store.dispatch(setDraftMessageReleaseType(payload));
    }

    public clearDraftMessageReleaseType(): void {
        this.store.dispatch(clearDraftMessageReleaseType());
    }

    public toggleAttachmentsMultiSelectionMode(payload: ReturnType<typeof toggleAttachmentsMultiSelectionMode>['payload']): void {
        this.store.dispatch(toggleAttachmentsMultiSelectionMode(payload));
    }

    public toggleAttachmentsEditorMode(payload: ReturnType<typeof toggleAttachmentsEditorMode>['payload']): void {
        this.store.dispatch(toggleAttachmentsEditorMode(payload));
    }

    public resetPreviewsByAttachmentId(id: string): void {
        this.store.dispatch(resetPreviewsByAttachmentId(id));
    }

    public addPreviewIdInAttachmentsEditor(payload: ReturnType<typeof addPreviewIdInAttachmentsEditor>['payload']): void {
        this.store.dispatch(addPreviewIdInAttachmentsEditor(payload));
    }

    public removePreviewIdInAttachmentsEditor(payload: ReturnType<typeof removePreviewIdInAttachmentsEditor>['payload']): void {
        this.store.dispatch(removePreviewIdInAttachmentsEditor(payload));
    }

    public clearPreviewIdsInAttachmentsEditor(): void {
        this.store.dispatch(clearPreviewIdsInAttachmentsEditor());
    }

    public setSelectedMessage(payload: ReturnType<typeof setSelectedMessage>['payload']): void {
        this.store.dispatch(setSelectedMessage(payload));
    }

    public setSelectedAttachmentPreview(payload: ReturnType<typeof setSelectedAttachmentPreview>['payload']): void {
        this.store.dispatch(setSelectedAttachmentPreview(payload));
    }

    public setSelectedAttachment(payload: ReturnType<typeof setSelectedAttachment>['payload']): void {
        this.store.dispatch(setSelectedAttachment(payload));
    }

    public clearSelectedMessage(): void {
        this.store.dispatch(clearSelectedMessage());
    }

    public clearSelectedAttachmentPreview(): void {
        this.store.dispatch(clearSelectedAttachmentPreview());
    }

    public clearSelectedAttachment(): void {
        this.store.dispatch(clearSelectedAttachment());
    }

    public rotatePreviewImage(payload: ReturnType<typeof rotatePreviewImage>['payload']): void {
        this.store.dispatch(rotatePreviewImage(payload));
    }

    public setAttachmentPreviewImageSource(payload: ReturnType<typeof setPreviewImageSource>['payload']): void {
        this.store.dispatch(setPreviewImageSource(payload));
    }

    public addManyPreviewImages(payload: ReturnType<typeof addManyPreviewImages>['payload']): void {
        this.store.dispatch(addManyPreviewImages(payload));
    }

    public clearPreviewImages(): void {
        this.store.dispatch(clearPreviewImages());
    }

    public removePreviewImage(payload: ReturnType<typeof removePreviewImage>['payload']): void {
        this.store.dispatch(removePreviewImage(payload));
    }

    public markPreviewImageForDeletion(payload: ReturnType<typeof markPreviewImageForDeletion>['payload']): void {
        this.store.dispatch(markPreviewImageForDeletion(payload));
    }

    public resetPreviewImageTransformation(payload: ReturnType<typeof resetPreviewImageTransformation>['payload']): void {
        this.store.dispatch(resetPreviewImageTransformation(payload));
    }

    public shouldTrackFaxResponses(isTracking: boolean): void {
        let conversation: MessageDraftChanges['conversation'] = null;
        if (isTracking) conversation = { conversationStatus: ConversationStatus.Draft, type: ConversationType.Order };

        this.store.dispatch(setDraftMessageConversation(conversation));
    }
}
