import { createApi } from 'ngrx-rtk-query';
import { configureBaseQuery } from '@kno2/shared/data-access/+store';
import { DownloadsResource } from '../../../models/downloads-resource.model';
import { DownloadsQueryParams } from './downloads-query-params.model';
import { SortDirection } from '../../../models/sort-direction.model';

export const downloadsApiKey = 'downloads';
const cacheTags = {
    downloads: 'downloads'
};

export const baseParams: DownloadsQueryParams = {
    sortDir: SortDirection.Asc,
    pageNumber: 1,
    pageSize: 10
};
export const downloadsApi = createApi({
    reducerPath: downloadsApiKey,
    tagTypes: [cacheTags.downloads],
    baseQuery: configureBaseQuery('/api/downloads/getpaged'),
    endpoints: (build) => ({
        getAll: build.query<DownloadsResource, DownloadsQueryParams | void>({
            query: (params: DownloadsQueryParams) => ({
                url: '',
                params: { ...baseParams, ...params }
            }),
            providesTags: [cacheTags.downloads]
        })
    })
});

export const { useGetAllQuery } = downloadsApi;
