import { CurrentMessageState, currentMessageSliceKey, messagePreviewAdapter } from './current-message-slice.config';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AttachmentPreview } from './models/current-message-attachment-preview.model';
export const selectCurrentMessageFeature = createFeatureSelector<CurrentMessageState>(currentMessageSliceKey);

export const selectCurrentMessageId = createSelector(selectCurrentMessageFeature, (state) => state.selectedMesageId);
export const selectCurrentPreviewId = createSelector(selectCurrentMessageFeature, (state) => state.selectedPreviewId);
export const selectCurrentAttachmentId = createSelector(selectCurrentMessageFeature, (state) => state.selectedAttachmentId);

export const selectCurrentPreview = createSelector(selectCurrentMessageFeature, (state) =>
    messagePreviewAdapter.getSelectors().selectById(state.attachmentPreviews, state.selectedPreviewId)
);
export const selectAttachmentsEditor = createSelector(selectCurrentMessageFeature, (state) => state.attachmentsEditor);
export const selectAttachmentPreviews = createSelector(selectCurrentMessageFeature, (state) =>
    messagePreviewAdapter.getSelectors().selectAll(state.attachmentPreviews)
);
export const selectAttachmentPreviewEntities = createSelector(selectCurrentMessageFeature, (state) =>
    messagePreviewAdapter.getSelectors().selectEntities(state.attachmentPreviews)
);
export const selectTransformedAttachmentPreviews = createSelector(selectCurrentMessageFeature, (state) => state.attachmentPreviews);
export const selectMarkedPreviewIds = createSelector(selectCurrentMessageFeature, (state) => state.attachmentsEditor.markedPreviewsIds);
export const selectPreviewsByAttachment = createSelector(selectAttachmentPreviews, (state) => {
    return state.reduce(
        (prev, preview) => ({
            ...prev,
            [preview.parentAttachmentId]: [...(prev?.[preview.parentAttachmentId] || []), preview]
        }),
        {} as Record<string, Array<AttachmentPreview>>
    );
});

export const selectDraftMessageChanges = createSelector(selectCurrentMessageFeature, (state) => state.draftMessageChanges);
export const selectMessageMetaData = createSelector(selectCurrentMessageFeature, (state) => state.messageMeta);
