/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = H 1 = Hp 2 = Hv 3 = Wp 4 = Dir 5 = Pub 6 = Tmp 7 = Old 8 = Bad 9 = Conf 10 = Phys 11 = Pst 12 = As 13 = Ec 14 = Pg 15 = Mc
 */
export enum AddressUseV3 {
    H = 'H',
    Hp = 'Hp',
    Hv = 'Hv',
    Wp = 'Wp',
    Dir = 'Dir',
    Pub = 'Pub',
    Tmp = 'Tmp',
    Old = 'Old',
    Bad = 'Bad',
    Conf = 'Conf',
    Phys = 'Phys',
    Pst = 'Pst',
    As = 'As',
    Ec = 'Ec',
    Pg = 'Pg',
    Mc = 'Mc'
}
