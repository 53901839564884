/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * Defines an enumeration for $inlinecount query option values.  0 = None 1 = AllPages
 */
export enum InlineCountValue {
    None = 'None',
    AllPages = 'AllPages'
}
