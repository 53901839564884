import { Injectable } from '@angular/core';
import mimeConverter from 'mime';
import { filter, map } from 'rxjs/operators';
import { useGetValidExtensionsQuery } from './attachments.api';

@Injectable({
    providedIn: 'root'
})
export class AttachmentsApiFacade {
    public getValidExtensions$ = useGetValidExtensionsQuery;

    public getValidMimeTypes$() {
        return this.getValidExtensions$().pipe(
            filter((query) => !query.isFetching && !!query.data),
            map((query) => {
                const mimeTypesArr = query.data
                    .map((extension) => mimeConverter.getType(extension.substr(1)))
                    .concat('text/xml')
                    .filter((x) => x);

                return {
                    extensionTypes: [...new Set(query.data.filter((x) => x))],
                    mimeTypesArr: [...new Set(mimeTypesArr)],
                    mimeTypesStr: [...new Set(mimeTypesArr)].join(',')
                };
            })
        );
    }
}
