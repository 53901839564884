import { BaseQueryFn, fetchBaseQuery, retry } from '@reduxjs/toolkit/query';
import { API_ERROR_EVENT_TOKEN, BASE_URL } from '@kno2/shared/util/configuration';
import { parseOutError } from '@kno2/shared/util/common';
import { ApiErrorEventModel } from '@kno2/shared/util/configuration';
import { authSliceKey } from '../slices/auth/auth-slice.config';

export const createRTKAPiHeaders = (accessToken: string, headers: Headers): Headers => {
    headers.append('Authorization', `Bearer ${accessToken}`);
    headers.append('Accept', 'application/json, text/plain, */*');
    return headers;
};

export const configureBaseQuery = (endpointUrl: string, maxRetries: number = 0): BaseQueryFn => {
    return retry(
        async (args: string, api, extraOptions) => {
            try {
                const result = await fetchBaseQuery({
                    baseUrl: `${BASE_URL}${endpointUrl}`,
                    prepareHeaders: (headers: Headers, api) => {
                        const state = (api.getState() as any)[authSliceKey];
                        return createRTKAPiHeaders(state.token, headers);
                    }
                })(args, api, extraOptions);

                if (result.error) {
                    const evt = new CustomEvent(API_ERROR_EVENT_TOKEN, {
                        detail: <ApiErrorEventModel>{
                            error: !!result.error.data ? parseOutError(result.error.data) : 'An unknown error occurred',
                            status: result.error?.status,
                            method: result?.meta?.request?.method || 'GET',
                            url: result?.meta?.request?.url || ''
                        }
                    });
                    document.dispatchEvent(evt);
                }

                return result;
            } catch (error) {
                return {
                    error
                };
            }
        },
        { maxRetries }
    );
};
