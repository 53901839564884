import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestParams } from '../common';
import { StateResource } from '../models/state-resource.model';
import { AuthorizationCodeResource } from '../models/authorization-code-resource.model';
import { SharedApiKno2fyMainModule } from '../shared-api-kno2fy-main.module';
import { IntegrationResource } from '../models/integration-resource.model';
import { OrderQuery } from '../models/order-query.model';
import { ReviewerQuery } from '../models/reviewer-query.model';
import { PatientQueryResource } from '../models/patient-query-resource.model';
import { VisitQuery } from '../models/visit-query.model';

@Injectable({
    providedIn: SharedApiKno2fyMainModule
})
export class IntegrationsService {
    constructor(private httpClient: HttpClient) {}

    /**
     * @param resource
     */
    public authCode(resource: AuthorizationCodeResource): Observable<void> {
        return this.httpClient.post<void>('/api/integrations/authcode', resource);
    }

    /**
     * @param state
     */
    public checkState(state: StateResource): Observable<any> {
        return this.httpClient.post<any>('/api/integrations/checkstate', state);
    }

    /**
     */
    public clearCachedData(): Observable<any> {
        return this.httpClient.post<any>('/api/integrations/clearcache', {});
    }

    /**
     * @param id
     */
    public deleteIntegration(id: string): Observable<any> {
        return this.httpClient.delete<any>(`/api/integrations/${encodeURIComponent(id)}`);
    }

    /**
     * @param id
     * @param resource
     */
    public downloadCertificate(id: string, resource: IntegrationResource): Observable<any> {
        return this.httpClient.post<any>(`/api/integrations/${encodeURIComponent(id)}/downloadcertificate`, resource);
    }

    /**
     * @param params
     * integrationType: string
     */
    public externalLogin(params: RequestParams = {}): Observable<any> {
        return this.httpClient.get<any>('/api/integrations/externallogin', {
            params
        });
    }

    /**
     */
    public getConnectedIntegrationTypes(): Observable<any> {
        return this.httpClient.get<any>('/api/integrations/connected/types');
    }

    /**
     */
    public getCurrentCapabilities(): Observable<any> {
        return this.httpClient.get<any>('/api/integrations/capabilities');
    }

    /**
     */
    public getFacilities(): Observable<any> {
        return this.httpClient.get<any>('/api/integrations/facilities');
    }

    /**
     * @param id
     */
    public getIntegration(id: string): Observable<any> {
        return this.httpClient.get<any>(`/api/integrations/${encodeURIComponent(id)}`);
    }

    /**
     */
    public getIntegrationDocumentTypes(): Observable<any> {
        return this.httpClient.get<any>('/api/integrations/documenttypes');
    }

    /**
     * @param query
     */
    public getIntegrationOrders(query: OrderQuery): Observable<any> {
        return this.httpClient.post<any>('/api/integrations/orders', query);
    }

    /**
     * @param id
     */
    public getIntegrationPatient(id: string): Observable<any> {
        return this.httpClient.get<any>(`/api/integrations/patients/${encodeURIComponent(id)}`);
    }

    /**
     */
    public getIntegrationReviewergroups(): Observable<any> {
        return this.httpClient.get<any>('/api/integrations/reviewergroups');
    }

    /**
     * @param query
     */
    public getIntegrationReviewers(query: ReviewerQuery): Observable<any> {
        return this.httpClient.post<any>('/api/integrations/reviewers', query);
    }

    /**
     */
    public getIntegrationTypes(): Observable<any> {
        return this.httpClient.get<any>('/api/integrations/types');
    }

    /**
     * @param query
     */
    public getIntegrationVisits(query: VisitQuery): Observable<any> {
        return this.httpClient.post<any>('/api/integrations/visits', query);
    }

    /**
     */
    public getIntegrations(): Observable<any> {
        return this.httpClient.get<any>('/api/integrations');
    }

    /**
     * @param params
     * type: string
     */
    public getPatientKeys(params: RequestParams = {}): Observable<any> {
        return this.httpClient.get<any>('/api/integrations/patientkeys', {
            params
        });
    }

    /**
     * @param resource
     */
    public revokeToken(resource: StateResource): Observable<any> {
        return this.httpClient.post<any>('/api/integrations/revoketoken', resource);
    }

    /**
     */
    public revokeTokenOauth(): Observable<any> {
        return this.httpClient.post<any>('/api/integrations/revoketoken/oauth', {});
    }

    /**
     * @param query
     */
    public searchIntegrationPatients(query: PatientQueryResource): Observable<any> {
        return this.httpClient.post<any>('/api/integrations/patients', query);
    }

    /**
     */
    public templates(): Observable<any> {
        return this.httpClient.get<any>('/api/integrations/templates');
    }

    /**
     * @param resource
     */
    public testIntegrationConnectivity(resource: IntegrationResource): Observable<any> {
        return this.httpClient.post<any>('/api/integrations/connectivity', resource);
    }

    /**
     * @param id
     */
    public testIntegrationConnectivityById(id: string): Observable<any> {
        return this.httpClient.get<any>(`/api/integrations/${encodeURIComponent(id)}/connectivity`);
    }

    /**
     * @param resource
     */
    public upsertIntegration(resource: IntegrationResource): Observable<any> {
        return this.httpClient.put<any>('/api/integrations', resource);
    }
}
