/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = All 1 = Pertinent
 */
export enum RequestAmount {
    All = 'All',
    Pertinent = 'Pertinent'
}
