import { Injectable } from '@angular/core';
import { AuditQueryParams } from './audit-query-params.model';
import { useGetAuditEntriesByTypesQuery, useGetAuditEntriesByUserAndTypeQuery } from './audit.api';

@Injectable({
    providedIn: 'root'
})
export class AuditApiFacade {
    public getAuditEntriesByUserAndType$ = useGetAuditEntriesByUserAndTypeQuery;

    public getAuditEntriesByTypes$(correlationId: string, params: AuditQueryParams): ReturnType<typeof useGetAuditEntriesByTypesQuery> {
        // omits eventTypes if none are provided. Otherwise, API will return 0 results
        params = params.eventTypes ? params : { startDate: params.startDate, endDate: params.endDate };
        return useGetAuditEntriesByTypesQuery({ correlationId, params });
    }

    constructor() {}
}
