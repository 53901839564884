import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NbToastrModule } from '@nebular/theme';
import { APIInterceptor } from './interceptors/api.interceptor';

@NgModule({
    imports: [HttpClientModule, NbToastrModule.forRoot()],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: APIInterceptor,
            multi: true
        }
    ]
})
export class SharedUtilConfigurationModule {}
