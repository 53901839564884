import { RawServerErrorResource } from './models/api-server-error.model';

export const parseOutError = (error: RawServerErrorResource['data']): string => {
    const modelStateErrors: Array<Array<string>> = JSON.parse(JSON.stringify(Object.values(error?.modelState || {})));

    if (modelStateErrors.length) return modelStateErrors[0].pop();

    if (error?.modelState) {
        return <string>Object.values(error?.modelState)[0];
    }

    if (error.exceptionMessage) return error.exceptionMessage;

    if (error.message) return error.message;

    if (error.message) return error.message;

    return null;
};
