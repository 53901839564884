import { Injectable } from '@angular/core';
import { NotificationService } from '@kno2/shared/util/common';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { DocumentSourceResource } from '../../../models/document-source-resource.model';
import { SourceType } from '../../../models/source-type.model';
import {
    useGetByIdQuery,
    useCreateDocumentSourceMutation,
    useDocumentExistsQuery,
    useGetDocumentSourcesQuery,
    useRemoveDocumentSourceMutation,
    useUpdateDocumentSourceMutation,
    documentSourcesApi,
    documentSourcesCacheTags,
    useGetAddressTypesQuery
} from './document-sources.api';

@Injectable({
    providedIn: 'root'
})
export class DocumentSourcesApiFacade {
    public getDocumentSourceById$ = useGetByIdQuery;
    public getDocumentSources$ = useGetDocumentSourcesQuery;
    public documentAddressTypes$ = useGetAddressTypesQuery;

    constructor(private store: Store, private notificationService: NotificationService) {}

    public documentSourceExists$(id: string, address: string) {
        return useDocumentExistsQuery({ id, address }).pipe(
            filter(({ isFetching }) => !isFetching),
            map(({ data, error }) => error && !data)
        );
    }

    public async removeDocumentSource(id: string, sourceType: SourceType): Promise<void> {
        const resultType = sourceType === SourceType.DirectMessage ? 'direct address' : 'fax';
        await this.tryMutation(async () => await useRemoveDocumentSourceMutation().dispatch(id).unwrap(), `The ${resultType} has been deleted.`);
    }

    public clearCache(): void {
        this.store.dispatch(documentSourcesApi.util.invalidateTags([documentSourcesCacheTags.documentSources]));
    }

    public async updateDocumentSource(body: DocumentSourceResource): Promise<boolean> {
        const resultType = body.type === SourceType.DirectMessage ? 'direct address' : 'fax';
        try {
            await this.tryMutation(
                async () => await useUpdateDocumentSourceMutation().dispatch(body).unwrap(),
                `The ${resultType} was saved successfully.`
            );
            return true;
        } catch (err) {
            return false;
        }
    }

    public async createDocumentSource(body: DocumentSourceResource): Promise<boolean> {
        const resultType = body.type === SourceType.DirectMessage ? 'direct address' : 'fax';
        try {
            await this.tryMutation(
                async () => await useCreateDocumentSourceMutation().dispatch(body).unwrap(),
                `The ${resultType} was saved successfully.`
            );
            return true;
        } catch (err) {
            return false;
        }
    }

    public async tryMutation(mutation: () => Promise<any>, successMessage: string): Promise<void> {
        try {
            await mutation();
            this.notificationService.success(successMessage);
        } catch (err) {
            const { exceptionMessage, message, modelState } = (<any>err).data ?? {};
            this.notificationService.error(Object.values(modelState || {})?.[0] || exceptionMessage || message, {
                duration: 10000
            });
            throw exceptionMessage || message;
        }
    }
}
