/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = None 1 = PasswordReset 2 = Invitation 3 = PasswordResetWithChallenge 4 = PasswordResetAfterChallenge
 */
export enum SecurityTokenType {
    None = 'None',
    PasswordReset = 'PasswordReset',
    Invitation = 'Invitation',
    PasswordResetWithChallenge = 'PasswordResetWithChallenge',
    PasswordResetAfterChallenge = 'PasswordResetAfterChallenge'
}
