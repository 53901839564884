/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Unverified 1 = Pending 2 = Verified
 */
export enum FaxNumberStatus {
    Unverified = 'Unverified',
    Pending = 'Pending',
    Verified = 'Verified'
}
