/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = User 1 = Administrator
 */
export enum OnboardingRoleType {
    User = 'User',
    Administrator = 'Administrator'
}
