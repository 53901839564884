export const phoneNumberUtils = {
    format(phoneNumber?: string): string {
        switch (phoneNumber?.length) {
            case 10:
                return phoneNumber.replace(/^(\d{3})(\d{3})(\d{4}).*/, '$1-$2-$3');
            case 11:
                return phoneNumber.replace(/^(\d{1})(\d{3})(\d{3})(\d{4}).*/, '$1-$2-$3-$4');
            case 12:
                return phoneNumber.replace(/^(\d{2})(\d{3})(\d{3})(\d{4}).*/, '$1-$2-$3-$4');
            case 13:
                return phoneNumber.replace(/^(\d{3})(\d{3})(\d{3})(\d{4}).*/, '$1-$2-$3-$4');
            default:
                return phoneNumber;
        }
    }
};
