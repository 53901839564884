/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Direct 1 = Fax 2 = Tcp 3 = NaviHealth 4 = SureScripts 5 = Ihde 6 = Crisp
 */
export enum DirectoryEntryType {
    Direct = 'Direct',
    Fax = 'Fax',
    Tcp = 'Tcp',
    NaviHealth = 'NaviHealth',
    SureScripts = 'SureScripts',
    Ihde = 'Ihde',
    Crisp = 'Crisp'
}
