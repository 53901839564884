import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
    selectAllowAttachmentImageConversion,
    selectAreAttachmentsModified,
    selectModifiedAttachmentMetadata,
    selectTrackUnsavedChanges,
    selectLoadingMessage,
    selectUploadedAttachmentIds
} from './attachments-slice.selectors';
import {
    addUploadedAttachmentId,
    clearModifiedAttachmentMetaData,
    clearUploadedAttachmentIds,
    setAttachmentImageConversion,
    resetState,
    setModifiedAttachmentMetaData,
    setUnsavedChangesTracking,
    setLoadingMessage
} from './attachments.slice';
import { AttachmentMetaData } from './models/attachment-metadata.model';

@Injectable({
    providedIn: 'root'
})
export class AttachmentsSliceFacade {
    public loadingMessage$: Observable<string> = this.store.select(selectLoadingMessage);
    public allowAttachmentImageConversion$: Observable<boolean> = this.store.select(selectAllowAttachmentImageConversion);
    public uploadedAttachmentIds$: Observable<Array<string>> = this.store.select(selectUploadedAttachmentIds);
    public modifiedAttachmentMetaData$: Observable<Record<string, AttachmentMetaData>> = this.store.select(selectModifiedAttachmentMetadata);
    public areAttachmentsModified$: Observable<boolean> = this.store.select(selectAreAttachmentsModified);
    public trackUnsavedChanges$: Observable<boolean> = this.store.select(selectTrackUnsavedChanges);

    constructor(private store: Store) {}

    public setAttachmentImageConversion(allowConversion: boolean): void {
        this.store.dispatch(setAttachmentImageConversion(allowConversion));
    }

    public addUploadedAttachmentId(id: string): void {
        this.store.dispatch(addUploadedAttachmentId(id));
    }

    public clearUploadedAttachmentIds(): void {
        this.store.dispatch(clearUploadedAttachmentIds());
    }

    public setModifiedAttachmentMetaData(id: string, meta: AttachmentMetaData): void {
        this.store.dispatch(setModifiedAttachmentMetaData({ id, meta }));
    }

    public clearModifiedAttachmentMetaData(): void {
        this.store.dispatch(clearModifiedAttachmentMetaData());
    }

    public setUnsavedChangesTracking(trackUnsavedChanges: boolean): void {
        this.store.dispatch(setUnsavedChangesTracking(trackUnsavedChanges));
    }

    public setLoadingMessage(loadingMessage: string): void {
        this.store.dispatch(setLoadingMessage(loadingMessage));
    }

    public clearLoadingMessage(): void {
        this.store.dispatch(setLoadingMessage(null));
    }

    public resetState(): void {
        this.store.dispatch(resetState());
    }
}
