/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Provisioning 1 = Activated 2 = PendingUpdate 3 = PendingDelete 4 = Deleted 5 = Failed
 */
export enum DocumentSourceStatus {
    Provisioning = 'Provisioning',
    Activated = 'Activated',
    PendingUpdate = 'PendingUpdate',
    PendingDelete = 'PendingDelete',
    Deleted = 'Deleted',
    Failed = 'Failed'
}
