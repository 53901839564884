import { APIHandlerService } from './../../../services/api-handler.service';
import { Injectable } from '@angular/core';
import { useChangeOrganizationMutation, useUpdatePasswordMutation } from './account.api';

@Injectable({
    providedIn: 'root'
})
export class AccountApiFacade {
    public updatePassword = this.apiHandlerService.createMutationHandler<void, typeof useUpdatePasswordMutation>(useUpdatePasswordMutation);

    constructor(private apiHandlerService: APIHandlerService) {}

    public async changeOrganization(id: string): Promise<void> {
        await useChangeOrganizationMutation().dispatch(id);
    }
}
