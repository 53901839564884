import { Injectable } from '@angular/core';
import { BaseQueryFn, MutationDefinition } from '@reduxjs/toolkit/query';
import { UseMutation } from 'ngrx-rtk-query/lib/types';
import { NotificationService, RawServerErrorResource } from '@kno2/shared/util/common';
import { ApiMutationBehaviorParams, ApiMutationResponseResource } from '../common';

@Injectable({
    providedIn: 'root'
})
export class APIHandlerService {
    constructor(private notificationService: NotificationService) {}
    public createMutationHandler<
        Result,
        T extends UseMutation<
            MutationDefinition<any /*args*/, BaseQueryFn<any, unknown, unknown, Record<any, any>, Record<any, any>>, string, Result, string>
        >,
        A extends ApiMutationBehaviorParams<Parameters<ReturnType<T>['dispatch']>[0], Result> = ApiMutationBehaviorParams<
            Parameters<ReturnType<T>['dispatch']>[0],
            Result
        >
    >(mutationFunc: T, defaultOptions?: A) {
        return async <B extends A>(
            payload: Parameters<ReturnType<T>['dispatch']>[0],
            behaviorParams?: B
        ): Promise<B extends { autoUnwrap: true } ? Result : ApiMutationResponseResource<Result>> => {
            // Only overwrite default behavior if prop no undefined
            const overwrittenParams = {
                ...defaultOptions,
                ...Object.fromEntries(Object.entries(behaviorParams || {}).filter(([, val]) => val !== undefined && val !== null))
            };

            const result: ApiMutationResponseResource<Result> = {
                success: true,
                response: null
            };

            try {
                result.response = await mutationFunc()
                    .dispatch(overwrittenParams.payloadFormatter ? overwrittenParams.payloadFormatter(payload) : payload)
                    .unwrap();

                if (!overwrittenParams.suppressToast) {
                    if (typeof overwrittenParams.toastOnSuccess === 'string') this.notificationService.success(overwrittenParams.toastOnSuccess);
                    else if (overwrittenParams.toastOnSuccess)
                        this.notificationService.success(overwrittenParams.toastOnSuccess(payload, result.response));
                }
            } catch (err: any) {
                const rawError = err as RawServerErrorResource;

                result.success = false;
                result.error = rawError;

                if (overwrittenParams.errorFormatter) result.error = overwrittenParams.errorFormatter(rawError);

                if (!overwrittenParams.suppressToast) {
                    if (typeof overwrittenParams.toastOnFailure === 'string') this.notificationService.error(overwrittenParams.toastOnFailure);
                    else if (overwrittenParams.toastOnFailure)
                        this.notificationService.error(overwrittenParams.toastOnFailure(payload, result.response, result.error));
                }

                if (overwrittenParams.bubbleUpError) throw result;
            }

            return (behaviorParams?.autoUnwrap ? result.response : result) as B extends { autoUnwrap: true }
                ? Result
                : ApiMutationResponseResource<Result>;
        };
    }
}
