/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Pdf 1 = Cda 2 = Orig
 */
export enum SendType {
    Pdf = 'Pdf',
    Cda = 'Cda',
    Orig = 'Orig'
}
