import { Injectable } from '@angular/core';
import { useGetIntakeQuery, useLazyGetIntakeQuery, intakeApi, intakeCacheTags } from './intake.api';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root'
})
export class IntakeApiFacade {
    public getIntakeMessagesByCriteria$ = useGetIntakeQuery;
    public getIntakeMessagesLazily = useLazyGetIntakeQuery;
    public getIntakeMessages = useGetIntakeQuery;

    constructor(private store: Store) {}

    public forceInvalidateIntakeMessages = (): void => {
        this.store.dispatch(intakeApi.util.invalidateTags([intakeCacheTags.mailMessages]));
    };
}
