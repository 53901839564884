import { DateTime } from 'luxon';
import { PagingResource } from '../../../common/paging.resource';
import { AuditEventType } from './audit-event-type.model';

export interface AuditQueryParams extends PagingResource {
    eventTypes?: Array<AuditEventType>;
    startDate?: DateTime;
    endDate?: DateTime;
}

export interface AuditUserQueryParams extends PagingResource {
    username?: string;
    eventType?: AuditUserEventType;
    startDate?: DateTime;
    endDate?: DateTime;
}

export enum AuditUserEventType {
    All = 'All',
    LoginSuccess = 'LoginSuccess',
    LoginFail = 'LoginFail',
    LoggedOut = 'LoggedOut'
}
