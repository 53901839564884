import { Injectable } from '@angular/core';
import { Params, RouterStateSnapshot } from '@angular/router';
import { parseDataFromEntireRoute, parseFullRoutePatternsFromEntireRoute } from '@kno2/shared/util/common';
import { RouterStateSerializer } from '@ngrx/router-store';

export interface RouterStateUrl {
    url: string;
    params: Params;
    queryParams: Params;
    data: Record<string, any>;
    matchedPath: string;
}

@Injectable({
    providedIn: 'root'
})
export class CustomSerializerUtil implements RouterStateSerializer<RouterStateUrl> {
    public serialize(routerState: RouterStateSnapshot): RouterStateUrl {
        let route = routerState.root;

        while (route.firstChild) {
            route = route.firstChild;
        }

        const {
            url,
            root: { queryParams }
        } = routerState;
        const { params } = route;

        // Only return an object including the URL, params and query params
        // instead of the entire snapshot
        return {
            url,
            params,
            queryParams,
            data: parseDataFromEntireRoute(routerState.root),
            matchedPath: parseFullRoutePatternsFromEntireRoute(routerState.root)
        };
    }
}
