/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = None 1 = Brand 2 = Reseller
 */
export enum ExternalServiceAccountEntityType {
    None = 'None',
    Brand = 'Brand',
    Reseller = 'Reseller'
}
