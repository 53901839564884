import { AttachmentMetaData } from './models/attachment-metadata.model';

export const attachmentsSliceKey = 'attachments';

export interface AttachmentsState {
    loadingMessage: string;
    allowAttachmentImageConversion: boolean;
    uploadedAttachmentIds: Array<string>;
    modifiedAttachmentMetadata: Record<string, AttachmentMetaData>;
    trackUnsavedChanges: boolean;
}

export const INTIIAL_ATTACHMENT_STATE: AttachmentsState = {
    loadingMessage: null,
    uploadedAttachmentIds: [],
    modifiedAttachmentMetadata: {},
    trackUnsavedChanges: true,
    allowAttachmentImageConversion: false
};
