/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = up 1 = down 2 = nearest
 */
export enum ERoundingDirection {
    up = 'up',
    down = 'down',
    nearest = 'nearest'
}
