import { configureBaseQuery } from '@kno2/shared/data-access/+store';
import { createApi } from 'ngrx-rtk-query';
import { IAuditEntry } from '../../../models/i-audit-entry.model';
import { AuditQueryParams, AuditUserQueryParams } from './audit-query-params.model';

export const auditApiKey = 'audit';

export const auditApi = createApi({
    reducerPath: auditApiKey,
    baseQuery: configureBaseQuery('/api/audit'),
    endpoints: (build) => ({
        getAuditEntriesByTypes: build.query<Array<IAuditEntry>, { correlationId: string; params: AuditQueryParams }>({
            query: ({ correlationId, params }) => ({
                url: `${correlationId}/events`,
                params
            }),
            keepUnusedDataFor: 0
        }),
        getAuditEntriesByUserAndType: build.query<Array<IAuditEntry>, AuditUserQueryParams>({
            query: (params) => ({
                url: '/user',
                params: { ...params, username: encodeURIComponent(params.username) }
            })
        })
    })
});

export const { useGetAuditEntriesByTypesQuery, useGetAuditEntriesByUserAndTypeQuery } = auditApi;
