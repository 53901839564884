import { APIHandlerService } from './../../../services/api-handler.service';
import { Injectable } from '@angular/core';
import { useUpdateResponseMutation } from './conversations.api';
import { ConversationAcceptanceResponse } from '../../../models';

@Injectable({
    providedIn: 'root'
})
export class ConversationsApiFacade {
    public updateResponse = this.apiHandlerService.createMutationHandler<ConversationAcceptanceResponse, typeof useUpdateResponseMutation>(
        useUpdateResponseMutation,
        {
            toastOnSuccess: (x) => `The Message Has Been Successfully ${x.payload.responseType}`,
            toastOnFailure: (x) => `Unable to change status to ${x.payload.responseType}`
        }
    );

    constructor(private apiHandlerService: APIHandlerService) {}
}
