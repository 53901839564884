/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = Intake 1 = Triage
 */
export enum RuleType {
    Intake = 'Intake',
    Triage = 'Triage'
}
