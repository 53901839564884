/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = FromAddress 1 = AttachmentType 2 = Origin 3 = FromAddressList 4 = SourceTypeList
 */
export enum DirectoryRuleType {
    FromAddress = 'FromAddress',
    AttachmentType = 'AttachmentType',
    Origin = 'Origin',
    FromAddressList = 'FromAddressList',
    SourceTypeList = 'SourceTypeList'
}
