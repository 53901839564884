import { ApplicationRef, Injectable } from '@angular/core';
import { NbComponentStatus, NbToastrConfig, NbToastrService } from '@nebular/theme';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(private toastr: NbToastrService, private appRef: ApplicationRef) {}

    public success(message: string, config?: Partial<NbToastrConfig>) {
        this.showAlert('success', message, config);
    }

    public error(message: string, config?: Partial<NbToastrConfig>) {
        this.showAlert('danger', message, config);
    }

    public warning(message: string, config?: Partial<NbToastrConfig>) {
        this.showAlert('warning', message, config);
    }

    public notice(message: string, config?: Partial<NbToastrConfig>) {
        this.showAlert('info', message, config);
    }

    public showAlert(status: NbComponentStatus, message: string, config?: Partial<NbToastrConfig>): void {
        if (!this.appReady()) return;
        const title = status === 'danger' ? 'Oh no!' : null;
        const configObj = {
            status,
            toastClass: `notification-${status}`,
            ...config
        };
        if (status === 'danger' || status === 'warning') {
            configObj.duration = 6000;
        }
        this.toastr.show(message, title, configObj);
    }

    private appReady(): boolean {
        return this.appRef.components.some((c) => c.location.nativeElement.querySelector('router-outlet'));
    }
}
