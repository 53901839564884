import { MAIN_APP_SIDEBAR_TAG } from './../../layouts/layout-element.tags';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { filter, map, tap } from 'rxjs/operators';
import { Unsubscribable } from 'rxjs';
import { Environment } from '@kno2/shared/util/configuration';

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
    @Input() public user: any;
    @Input() public dropdownItems: Array<NbMenuItem>;
    @Input() public enableSidebarToggle = true;

    public logo: string;
    public userPictureOnly = false;
    public profileMenuTag = 'profile-context-menu';
    private subscriptions: Array<Unsubscribable> = [];

    // Future implementation
    public themes = [
        {
            value: 'default',
            name: 'Light'
        },
        {
            value: 'dark',
            name: 'Dark'
        }
    ];
    public currentTheme = 'default';

    constructor(
        private sidebarService: NbSidebarService,
        private menuService: NbMenuService,
        private themeService: NbThemeService,
        private breakpointService: NbMediaBreakpointsService,
        private environment: Environment
    ) {
        this.logo = `${this.environment.brand.assetsUrl}/${this.environment.brand.name}/logo-header.png`;
    }

    public ngOnInit(): void {
        this.currentTheme = this.themeService.currentTheme;

        const { xl } = this.breakpointService.getBreakpointsMap();
        this.subscriptions.push(
            this.themeService
                .onMediaQueryChange()
                .pipe(map(([, currentBreakpoint]) => currentBreakpoint.width < xl))
                .subscribe((isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl))
        );

        this.subscriptions.push(
            this.themeService
                .onThemeChange()
                .pipe(map(({ name }) => name))
                .subscribe((themeName) => (this.currentTheme = themeName))
        );
        const menu$ = this.menuService
            .onItemClick()
            .pipe(
                filter(({ tag }) => tag === this.profileMenuTag),
                tap((clickedItem) => {
                    const { data } = clickedItem.item;
                    if (data.onClick) data.onClick();
                })
            )
            .subscribe();

        this.subscriptions.push(menu$);
    }

    public ngOnDestroy(): void {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    public changeTheme(themeName: string): void {
        this.themeService.changeTheme(themeName);
    }

    public toggleSidebar(): boolean {
        this.sidebarService.toggle(true, MAIN_APP_SIDEBAR_TAG);

        return false;
    }

    public navigateHome(): false {
        this.menuService.navigateHome();
        return false;
    }
}
