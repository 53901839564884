/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = None 1 = StructuredExport 2 = PDFExport 4 = NativeExport 8 = Printed 16 = Saved 32 = AwaitingEMRExport 64 = EMRExported 128 = ForceProcessed
 */
export enum ProcessedType {
    None = 'None',
    StructuredExport = 'StructuredExport',
    PDFExport = 'PDFExport',
    NativeExport = 'NativeExport',
    Printed = 'Printed',
    Saved = 'Saved',
    AwaitingEMRExport = 'AwaitingEMRExport',
    EMRExported = 'EMRExported',
    ForceProcessed = 'ForceProcessed'
}
