import { HttpMethod } from '@auth0/auth0-angular';
import { configureBaseQuery } from '@kno2/shared/data-access/+store';
import { createApi } from 'ngrx-rtk-query';
import { PagingResource } from '../../../common';
import { DocumentTypeResource } from '../../../models/document-type-resource.model';
import { DocumentTypesResource } from '../../../models/document-types-resource.model';

export const documentTypesApiKey = 'documentTypes';
export const documentTypesCacheTags = {
    documentTypes: 'documentTypes'
};
export const documentsApi = createApi({
    reducerPath: documentTypesApiKey,
    baseQuery: configureBaseQuery('/api/documenttypes'),
    tagTypes: Object.values(documentTypesCacheTags),
    endpoints: (build) => ({
        getDocumentTypes: build.query<DocumentTypesResource, PagingResource | void>({
            query: (params: PagingResource = {}) => ({
                url: '/',
                params
            }),
            providesTags: [documentTypesCacheTags.documentTypes]
        }),
        removeDocumentType: build.mutation<void, string>({
            query: (id: string) => ({
                url: `/${id}`,
                method: HttpMethod.Delete
            }),
            invalidatesTags: [documentTypesCacheTags.documentTypes]
        }),
        updateDocumentType: build.mutation<void, DocumentTypeResource>({
            query: (body: DocumentTypeResource) => ({
                url: `/${body.id}`,
                method: HttpMethod.Put,
                body
            }),
            invalidatesTags: [documentTypesCacheTags.documentTypes]
        }),
        createDocumentType: build.mutation<void, DocumentTypeResource>({
            query: (body: DocumentTypeResource) => ({
                url: `/`,
                method: HttpMethod.Post,
                body
            }),
            invalidatesTags: [documentTypesCacheTags.documentTypes]
        }),
        documentTypeExists: build.query<boolean, DocumentTypeResource>({
            query: (params) => ({
                url: `/exists`,
                params: { ...Object.fromEntries(Object.entries(params).filter(([, value]) => !!value)) }
            })
        })
    })
});

export const {
    useGetDocumentTypesQuery,
    useDocumentTypeExistsQuery,
    useRemoveDocumentTypeMutation,
    useUpdateDocumentTypeMutation,
    useCreateDocumentTypeMutation
} = documentsApi;
