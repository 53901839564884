import { Socket, default as io } from 'socket.io-client';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { Environment } from '@kno2/shared/util/configuration';
import { ApplicationInsightsService } from '@kno2/shared/util/integrations';
import { AuthSliceFacade } from '@kno2/shared/data-access/+store';
import { ProfileApiFacade } from '../+store/api/profile/profile-api.facade';
import { take } from 'rxjs/operators';
import { AuthRequestResult, SyncReleaseTypesResult } from '../models/socket.model';

@Injectable({
    providedIn: 'root'
})
export class SocketService {
    private client: typeof Socket;

    // create an observable to track if the client is connected to the server
    private clientConnected = new BehaviorSubject<boolean>(false);
    private syncReleaseTypes = new Subject<SyncReleaseTypesResult>();

    public clientConnected$ = this.clientConnected.asObservable();
    public syncReleaseTypes$ = this.syncReleaseTypes.asObservable();

    constructor(
        private environment: Environment,
        private authSlice: AuthSliceFacade,
        private profileApiFacade: ProfileApiFacade,
        private appInsights: ApplicationInsightsService
    ) {}

    public start(): void {
        const { socketUrl } = this.environment;
        this.client = io(socketUrl, {
            forceNew: true,
            transports: ['websocket']
        });

        this.client.on('connect_error', (err: any) => this.appInsights.trackException(err));
        this.client.on('error', (err: any) => this.appInsights.trackException(err));

        this.client.on('connect', () => {
            this.handleConnected();
        });
        this.client.on('authRequestResult', (result: AuthRequestResult) => this.handleAuthRequestResult(result));
        this.client.on('SyncReleaseTypes', (payload: SyncReleaseTypesResult) => {
            this.syncReleaseTypes.next(payload);
        });
    }

    public stop(): void {
        this.client.disconnect();
        this.clientConnected.next(false);
        this.client = null;
        this.clientConnected.complete();
        this.syncReleaseTypes.complete();
    }

    private handleConnected() {
        combineLatest([this.authSlice.authToken$, this.profileApiFacade.getUserId$()])
            .pipe(take(1))
            .subscribe(
                ([token, userId]) => {
                    this.client.emit('authRequest', {
                        apiUrl: this.environment.baseUrl,
                        authToken: token,
                        userId
                    });
                },
                (err) => {
                    console.error('Socket Service failed to auth:', err);
                }
            );
    }

    private handleAuthRequestResult(result: AuthRequestResult) {
        if (!result.success) {
            console.error('Could not successfully authenticate to delivery service: ', result);
            return this.stop();
        }

        this.clientConnected.next(true);
    }
}
