import { Injectable } from '@angular/core';
import { ApplicationInsights, ICustomProperties } from '@microsoft/applicationinsights-web';
import { Environment } from '@kno2/shared/util/configuration';

@Injectable({
    providedIn: 'root'
})
export class ApplicationInsightsService {
    private readonly instrumentationKey: string;
    private appInsights: ApplicationInsights;
    public get sessionId(): string {
        return this.appInsights.context.getSessionId();
    }

    constructor(private environment: Environment) {
        this.instrumentationKey = this.environment.appInsights?.instrumentationKey || '';
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: this.instrumentationKey,
                enableAutoRouteTracking: true
            }
        });

        if (this.instrumentationKey) this.appInsights.loadAppInsights();
    }

    public trackException(exception: Error, customProperties?: ICustomProperties): void {
        if (!this.instrumentationKey) return;
        try {
            this.appInsights.trackException({ exception }, customProperties);
        } catch (error) {
            console.error(error);
        }
    }

    public setUserContext(userId: string, organizationId: string): void {
        if (!this.instrumentationKey) return;
        try {
            if (userId && organizationId) {
                this.appInsights.context.user.id = userId;
                this.setAppInsightsCustomFields(organizationId);
            }
        } catch (error) {
            console.error(error);
        }
    }

    private setAppInsightsCustomFields(organizationId: string): void {
        const telemetryInitializer = (envelope: any) => {
            envelope.data.organizationId = organizationId;
            envelope.data.buildNumber = this.environment.buildNumber;
        };
        this.appInsights.addTelemetryInitializer(telemetryInitializer);
    }
}
