import { ReservedShortcutEvent } from './reserved-shortcut-event.constants';

export const RESERVED_SHORTCUT_KEYS = {
    [ReservedShortcutEvent.GoToIntake]: 'meta.i',
    [ReservedShortcutEvent.GoToTriage]: 'meta.t',
    [ReservedShortcutEvent.GoToRelease]: 'meta.r',
    [ReservedShortcutEvent.GoToSettings]: 'meta.shift.s',
    [ReservedShortcutEvent.SelectAll]: 'meta.a',
    [ReservedShortcutEvent.SaveChanges]: 'meta.s',
    [ReservedShortcutEvent.SaveAllChanges]: 'meta.s.a',
    [ReservedShortcutEvent.UndoChanges]: 'meta.z'
} as const;
