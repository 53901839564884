export const SHARED_ROUTES = {
    ROOT_ROUTE: '',

    POLICIES: 'policies',
    POLICIES_PRIVACY: 'privacy',
    POLICIES_TERMSOFUSE: 'termsofuse',

    LEGALINFORMATION: 'legalinformation',

    ABOUTUS: 'aboutus',

    ACCOUNT_LOGIN: 'account/login',
    ACCOUNT_LOGIN_TYPE: 'account/login/:loginType',

    LOGINCALLBACK: 'callback',
    UNAUTHORIZED: 'unauthorized'
};
