/**
 * Enum values are converted to int when received by the endpoint
 * Enum values are converted to string when received by the client
 *
 * 0 = MessageReceive 1 = MessageReceiveAwaitingEmrExport 2 = MessageSendStatusUpdated 3 = OrganizationActivation 4 = MessageProcessing
 */
export enum Kno2WebhookEvents {
    MessageReceive = 'MessageReceive',
    MessageReceiveAwaitingEmrExport = 'MessageReceiveAwaitingEmrExport',
    MessageSendStatusUpdated = 'MessageSendStatusUpdated',
    OrganizationActivation = 'OrganizationActivation',
    MessageProcessing = 'MessageProcessing'
}
