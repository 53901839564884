import { APP_INITIALIZER, NgModule } from '@angular/core';
import { IntercomService } from './intercom/intercom.service';

@NgModule({
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: IntercomService.init,
            multi: true
        }
    ]
})
export class SharedUtilIntegrationsModule {}
