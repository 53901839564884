import { AuthConfig } from '@auth0/auth0-angular';
import { IConfiguration, IConfig } from '@microsoft/applicationinsights-web';
export class Environment {
    public production: boolean;
    public buildNumber: string;
    public brand: any;
    public baseUrl: string;
    public auth0: AuthConfig;
    public appInsights: IConfiguration & IConfig;

    public socketUrl: string;

    constructor(environment: Environment) {
        for (const [key, value] of Object.entries(environment)) {
            (this as any)[key] = value;
        }
    }
}
